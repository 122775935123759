import axios from "axios";
import { useEffect, useState } from "react";
import { MdCancel } from "react-icons/md";
import Moment from "react-moment";
import { Link } from "react-router-dom";
import { TbMinusVertical } from "react-icons/tb";
import { FaBriefcase, FaMedkit } from "react-icons/fa";
import ReactPaginate from "react-paginate";
import "../App.css";

const SingleNotification = ({
  data,
  progettoNotifications,
  setResult,
  setWidth,
  setProjectName,
  setFilterProjectsTicketsActive,
}) => {
  const [currentItems, setCurrentItems] = useState([]);
  const [pageCount, setPageCount] = useState(0);
  const [itemOffset, setItemOffset] = useState(0);
  const itemsPerPage = 10;

  useEffect(() => {
    const handleResize = () => {
      setWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    const endOffset = itemOffset + itemsPerPage;
    setCurrentItems(
      data
        .filter((el) =>
          progettoNotifications.toLowerCase() === ""
            ? el
            : el.project.title
                .toLowerCase()
                .includes(progettoNotifications.toLowerCase())
        )
        .slice(itemOffset, endOffset)
    );
    setPageCount(
      Math.ceil(
        data.filter((el) =>
          progettoNotifications.toLowerCase() === ""
            ? el
            : el.project.title
                .toLowerCase()
                .includes(progettoNotifications.toLowerCase())
        ).length / itemsPerPage
      )
    );
  }, [itemOffset, itemsPerPage, data, progettoNotifications]);

  const handlePageClick = (event) => {
    const newOffset =
      (event.selected * itemsPerPage) %
      data.filter((el) =>
        progettoNotifications.toLowerCase() === ""
          ? el
          : el.project.title
              .toLowerCase()
              .includes(progettoNotifications.toLowerCase())
      ).length;
    setItemOffset(newOffset);
  };

  const deleteNotificationUrl = `${process.env.REACT_APP_BASE_URL}/delete-notification`;
  const token = localStorage.getItem("token");

  const deleteMessageButton = (notificationId) => {
    const config = {
      method: "post",
      url: deleteNotificationUrl,
      headers: {
        Accept: "application/json",
        "Content-type": "application/json",
        "Authorization": "Bearer " + token,
      },
      data: {
        notificationId: notificationId,
      },
    };

    axios(config)
      .then((res) => {
        setResult(res);
        setCurrentItems(
          currentItems.filter((item) => item.id !== notificationId)
        );
      })
      .catch((err) => console.log(err));
  };

  const VediProgettiDaNotifiche = (id) => {
    setProjectName(id);
    setFilterProjectsTicketsActive(true);
  };

  return (
    <>
      <tbody>
        {data.length === 0 ? (
          <tr className='text-center mt-5'>
            <td>
              <h5>Nessuna notifica trovata</h5>
            </td>
          </tr>
        ) : currentItems.length === 0 ? (
          <tr className='text-center mt-5'>
            <td>
              <h5>Nessun messaggio trovato</h5>
            </td>
          </tr>
        ) : (
          currentItems.map((message, index) => {
            return (
              <tr key={index}>
                <td className='border-0 px-0 pt-3' style={{ width: "500px" }}>
                  <div className='bg-light-grey pill-start py-2 position-relative'>
                    {message.read ? (
                      ""
                    ) : (
                      <div
                        className='badge bg-danger position-absolute rounded-circle'
                        style={{ top: "-3px", left: "-3px" }}
                      >
                        !
                      </div>
                    )}
                    <span className='font-13'>
                      {message.ticket.type === "task" ? (
                        <FaBriefcase />
                      ) : (
                        <FaMedkit />
                      )}
                    </span>
                    <span className='font-13'>
                      {message.message.length > 70
                        ? message.message.substr(0, 70) + "..."
                        : message.message}
                    </span>
                  </div>
                </td>
                <td className='border-0 px-0 pt-3' style={{ width: "300px" }}>
                  <div className='bg-light-grey py-2'>
                    <span className='font-13'>
                      <Link to={`tickets/${message.ticket.id}`}>
                        {"#" + message.ticket.id + " "}
                        {message.ticket.title.length > 30
                          ? message.ticket.title.substr(0, 30) + "..."
                          : message.ticket.title}
                      </Link>
                    </span>
                  </div>
                </td>
                <td className='border-0 px-0 pt-3'>
                  <div className='bg-light-grey py-2'>
                    <span className='font-13'>
                      <Link
                        to='/tickets'
                        onClick={() =>
                          VediProgettiDaNotifiche(message.project.id)
                        }
                      >
                        {message.project.title}
                      </Link>
                    </span>
                  </div>
                </td>
                <td className='border-0 px-0 pt-3' style={{ width: "136px" }}>
                  <div className='bg-light-grey py-2'>
                    <span className='font-13 m-0'>
                      <Moment format='DD MMMM YYYY HH:mm'>
                        {message.created.date}
                      </Moment>
                    </span>
                  </div>
                </td>

                <td className='border-0 px-0 pt-3' style={{ width: "40px" }}>
                  <div className='bg-light-grey text-end py-2 pill-end'>
                    <span
                      className='text-orange pe-2'
                      onClick={() => deleteMessageButton(message.id)}
                      key={message.id}
                    >
                      <MdCancel />
                    </span>
                  </div>
                </td>
              </tr>
            );
          })
        )}
      </tbody>
      <tfoot className='position-relative'>
        <tr
          className='row position-absolute p-0 mt-4 border-0'
          style={{ right: "0" }}
        >
          <td>
            <div className='col-12 p-0'>
              <div className='border-0'>
                <ReactPaginate
                  breakLabel='...'
                  nextLabel='>>'
                  onPageChange={handlePageClick}
                  pageRangeDisplayed={1}
                  pageCount={pageCount}
                  previousLabel='<<'
                  renderOnZeroPageCount={null}
                  containerClassName={"pagination text-end notification-pag"}
                  pageClassName={"page-item"}
                  pageLinkClassName={"page-link"}
                  previousClassName={"page-item"}
                  previousLinkClassName={"page-link"}
                  nextClassName={"page-item"}
                  nextLinkClassName={"page-link"}
                  breakClassName={"page-item"}
                  breakLinkClassName={"page-link"}
                  activeClassName={"active activeLink"}
                  marginPagesDisplayed={1}
                />
              </div>
            </div>
          </td>
        </tr>
      </tfoot>
    </>
  );
};

export default SingleNotification;
