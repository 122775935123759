import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import styled from "styled-components";
import "../../App.css";

const SidebarLink = styled(NavLink)`
  display: flex;
  color: #e1e9fc;
  text-decoration: none;
  padding-top: 20px;
  padding-bottom: 20px;
  transition: all 0.25s;

  &:hover {
    color: white;
    transition: all 0.25s;
  }
`;

const SidebarLabel = styled.span`
  text-decoration: none;
  transition: all 0.25s;
`;

const DropdownLink = styled(NavLink)`
  text-decoration: none;
  text-align: left;
  color: white;

  &:hover {
    color: white;
    transition: all 0.25s;
    background-color: red;
  }
`;

const SidebarLabelDropdown = styled.li`
  text-decoration: none;
  transition: all 0.25s;
  padding-left: 40px;
  padding-top: 10px;
  padding-bottom: 10px;

  &:hover {
    transition: all 0.25s;
    background-color: #77b8b0;
  }
`;

const SubMenu = ({
  item,
  setStatus,
  setOpenTickets,
  setTypeOfTicket,
  setProjectName,
  setType,
  setFilterProjectsTicketsActive,
  filterProjectsTicketsActive,
}) => {
  const [subnav, setSubnav] = useState(false);
  const [isHovering, setIsHovering] = useState(false);
  const [active, setActive] = useState(false);

  const handleMouseOver = () => {
    setIsHovering(true);
  };

  const handleMouseOut = () => {
    setIsHovering(false);
  };

  const OpenTickets = () => {
    setActive(true);
    setOpenTickets("notclosed");
    setStatus("");
    setProjectName("");
    setType("");
  };
  const CloseTickets = () => {
    setActive(true);
    setOpenTickets("");
    setStatus("closed");
    setProjectName("");
  };
  const AllTickets = () => {
    setActive(true);
    setType("");
    setStatus("");
    setOpenTickets("");
    setProjectName("");
  };

  const ClickOnThePageTickets = () => {
    setType("");
    setStatus("");
    setProjectName("");
    setOpenTickets("");
    setSubnav(!subnav);
  };

  const ClickOnTheOtherPages = () => {
    setActive(false);
    setType("");
    setStatus("");
    setProjectName("");
    setOpenTickets("");
  };

  return (
    <>
      {item.subnav ? (
        <div
          onClick={item.subnav && ClickOnThePageTickets}
          className={`bg-inactive border-bottom border-dark ticketsSidebar`}
          style={{ cursor: "pointer" }}
          onMouseOver={handleMouseOver}
          onMouseOut={handleMouseOut}
        >
          <div className='col-8 text-start ps-2'>
            {item.icon}
            <SidebarLabel>{item.title}</SidebarLabel>
          </div>
          <div className='text-end col-4 pe-3'>
            {item.subnav && subnav
              ? item.iconOpen
              : item.subnav
              ? item.iconClosed
              : null}
          </div>
        </div>
      ) : (
        <SidebarLink
          to={item.path}
          onClick={ClickOnTheOtherPages}
          activeClassName={item.cPath}
          className={
            isHovering
              ? `${item.cPath}`
              : "bg-inactive border-bottom border-dark"
          }
          onMouseOver={handleMouseOver}
          onMouseOut={handleMouseOut}
        >
          <div className='col-8 text-start ps-2'>
            {item.icon}
            <SidebarLabel>{item.title}</SidebarLabel>
          </div>
        </SidebarLink>
      )}

      {subnav &&
        item.subnav.map((item, index) => {
          return (
            <DropdownLink
              to={"/tickets"}
              key={index}
              activeClassName={"ticketsActive"}
              className={isHovering ? `${item.cPath}` : ""}
              onMouseOver={handleMouseOver}
              onMouseOut={handleMouseOut}
            >
              <SidebarLabelDropdown
                onClick={
                  item.title === "Aperti"
                    ? OpenTickets
                    : item.title === "Chiusi"
                    ? CloseTickets
                    : AllTickets
                }
              >
                {" "}
                {item.title}
              </SidebarLabelDropdown>
            </DropdownLink>
          );
        })}
    </>
  );
};

export default SubMenu;
