import React from "react";
import "../../App.css";

const Profile = ({ data }) => {
  return (
    <th>
      <div className='row justify-content-end m-0 p-0 '>
        <div className='col-12 d-flex align-items-center'>
          {data.issuer.avatar ? (
            <div
              className='image-profile-navbar'
              style={{
                background: `url('${data.issuer.avatar}') center center / cover`,
              }}
            ></div>
          ) : (
            <div className='bg-red rounded-circle p-3 d-none d-lg-inline image-profile float-start m-0 text-center pt-3 text-white'>
              {data.issuer.name.charAt(0)}
              {data.issuer.lastname.charAt(0)}
            </div>
          )}

          <span className='text-black m-0 ms-3'>
            {data.issuer.name} {data.issuer.lastname}
          </span>
        </div>
      </div>
    </th>
  );
};

export default Profile;
