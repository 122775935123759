import React, { useContext } from "react";
import { GiHamburgerMenu } from "react-icons/gi";
import { FaSearch } from "react-icons/fa";
import SidebarMobile from "../sidebar/SidebarMobile";
import SearchMobile from "../search/SearchMobile";
import { StateContext } from "../../StateContainer";

const NavbarMobile = ({ user, setTypeOfTicket }) => {
  const state = useContext(StateContext);
  const { titlePage } = state;
  return (
    <>
      <nav
        className='nav w-100 bg-variant-blue text-white d-flex align-items-center position-sticky'
        style={{ height: "70px", top: "0", zIndex: "3" }}
      >
        <div className='container'>
          <div className='row justify-content-evenly d-flex align-items-center'>
            <div className='col h4 text-start'>
              <a
                className='text-decoration-none text-white fs-2'
                data-bs-toggle='offcanvas'
                href='#offcanvasMobile'
                role='button'
                aria-controls='offcanvasMobile'
              >
                <GiHamburgerMenu />
              </a>
            </div>
            <div className='col text-center'>{titlePage}</div>
            <div className='col h4 text-end'>
              <button
                className='btn text-white'
                type='button'
                data-bs-toggle='offcanvas'
                data-bs-target='#offcanvasTop'
                aria-controls='offcanvasTop'
              >
                <FaSearch className='fs-4' />
              </button>
            </div>
          </div>
        </div>
      </nav>
      <SidebarMobile user={user} setTypeOfTicket={setTypeOfTicket} />
      <SearchMobile />
    </>
  );
};

export default NavbarMobile;
