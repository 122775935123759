import React, { useState, useEffect, useContext } from "react";
import { AiOutlineCalendar } from "react-icons/ai";
import { IoMdNotificationsOutline } from "react-icons/io";
import { FaTicketAlt, FaSearch } from "react-icons/fa";
import { IoBag } from "react-icons/io5";
import "../../App.css";
import ListNavbarNotifications from "../notifiche/ListNavbarNotifications";
import { Link } from "react-router-dom";
import { StateContext } from "../../StateContainer";
import SearchMobile from "../search/SearchMobile";

const SingleIcon = [
  {
    id: 1,
    title: "Calendario",
    className: " single-icon",
    element: <AiOutlineCalendar />,
    badge: false,
    linkTo: "/calendario",
  },

  {
    id: 2,
    title: "Notifiche",
    className: " single-icon ",
    element: <IoMdNotificationsOutline />,
    badge: true,
    linkTo: "",
  },

  {
    id: 3,
    title: "Tutti i tickets",
    className: " single-icon",
    element: <FaTicketAlt />,
    badge: false,
    linkTo: "/tickets",
  },

  {
    id: 4,
    title: "Crea nuovo ticket",
    className: " single-icon",
    element: <FaTicketAlt />,
    badge: false,
    linkTo: "/tickets/create-tickets",
  },

  {
    id: 5,
    title: "Cerca",
    className: " single-icon",
    element: <FaSearch />,
    badge: false,
    linkTo: "",
    onClick: true,
  },
];

const Icons = ({ setTypeOfTicket }) => {
  const state = useContext(StateContext);
  const { result } = state;

  const [openNotificationsForm, setOpenNotificationsForm] = useState(false);
  const [notificationsNumber, setNotificationsNumber] = useState("");

  useEffect(() => {
    const handleClick = (event) => {
      if (!event.target.closest(".icon ")) {
        setOpenNotificationsForm(false);
      }
    };
    document.addEventListener("click", handleClick);
    return () => {
      document.removeEventListener("click", handleClick);
    };
  }, [openNotificationsForm]);

  // useEffect(() => {
  //   console.log('');
  // }, [result, notificationsNumber]);

  return (
    <div className='col p-0 d-flex align-items-center justify-content-between'>
      {SingleIcon.map((el, index) => {
        const { id, title, className, element, badge, linkTo, onClick, type } =
          el;
        return (
          <div key={index} className='d-flex align-content-center'>
            <span
              key={id}
              className={`${className} position-relative m-0 icon px-1 mx-4 mx-lg-0 ps-4`}
              style={{ cursor: "pointer" }}
              title={title}
            >
              {id !== 5 ? element : ""}
              {badge ? (
                notificationsNumber === 0 ? (
                  ""
                ) : (
                  <>
                    <small className='position-absolute top-0 translate-middle badge rounded-pill bg-orange badge-notifiche'>
                      {notificationsNumber}
                    </small>
                  </>
                )
              ) : (
                ""
              )}
              {badge ? (
                <a
                  className='stretched stretched-link '
                  onClick={() =>
                    setOpenNotificationsForm(!openNotificationsForm)
                  }
                ></a>
              ) : id === 4 ? (
                <Link
                  className='stretched stretched-link '
                  style={{ cursor: "pointer" }}
                  to={linkTo}
                  onClick={() => setTypeOfTicket("task")}
                >
                  <div className='badge badge-add-ticket-navbar bg-white rounded-circle text-center position-absolute d-flex align-items-center justify-content-center'>
                    <p className='m-0'>+</p>
                  </div>
                </Link>
              ) : id === 5 ? (
                <>
                  <a
                    className='btn text-white fs-4'
                    type='button'
                    data-bs-toggle='offcanvas'
                    data-bs-target='#offcanvasTop'
                    aria-controls='offcanvasTop'
                  >
                    {element}
                  </a>

                  <SearchMobile />
                </>
              ) : (
                <Link
                  className='stretched stretched-link '
                  style={{ cursor: "pointer" }}
                  to={linkTo}
                ></Link>
              )}
            </span>

            {badge && id === 2 ? (
              <>
                <div
                  className='position-absolute bg-white notification-list-navbar shadow d-none d-lg-block'
                  style={{ zIndex: 9 }}
                >
                  <ListNavbarNotifications
                    notificationsNumber={notificationsNumber}
                    setNotificationsNumber={setNotificationsNumber}
                    openNotificationsForm={openNotificationsForm}
                  />
                </div>
              </>
            ) : (
              ""
            )}
          </div>
        );
      })}
    </div>
  );
};

export default Icons;
