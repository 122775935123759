import React, { useState } from "react";
import { ImAttachment } from "react-icons/im";
import "../../App.css";

const DragAndDrop = ({ onDrop }) => {
  const [isDrag, setIsDrag] = useState(false);
  const [files, setFiles] = useState([]);
  const [alertMessage, setAlertMessage] = useState("");

  const handleDragOver = (e) => {
    e.preventDefault();
    e.stopPropagation();
    e.dataTransfer.dropEffect = "copy";
    setIsDrag(true);
  };

  const handleDragLeave = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setIsDrag(false);
  };

  const handleFiles = (e) => {
    const files = e.target.files || e.dataTransfer.files;
    onDrop(files);
  };

  const handlePaste = (e) => {
    const files = e.clipboardData.files;
    onDrop(files);
  };

  const handleDrop = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setIsDrag(false);
    let newFiles = Array.from(e.dataTransfer.files);

    // filter out any null values
    newFiles = newFiles.filter((file) => file !== null);

    // update the state with the new files
    setFiles([...files, ...newFiles]);
    onDrop(newFiles);
  };

  return (
    <>
      <div className='row'>
        <div className='col-12 mb-2'>
          <div className='float-start'>
            Allegati <ImAttachment />
          </div>
        </div>
        <div className='col-12 mb-2'>
          <div
            onDragOver={handleDragOver}
            onDragLeave={handleDragLeave}
            onDrop={handleDrop}
            onPaste={handlePaste}
            className={`drag-drop-container text-center py-4 ${
              isDrag ? "drag-over" : ""
            }`}
            style={{ borderStyle: "dotted", border: "0.5px grey dotted" }}
          >
            <div className='drag-drop-text'>
              {isDrag
                ? "Rilascia qui il file"
                : "Trascina, incolla o clicca per caricare il file"}
            </div>
            <input
              type='file'
              onChange={handleFiles}
              multiple
              className='mt-2'
            />
          </div>
          {alertMessage ? (
            <p className='text-danger mt-2'>{alertMessage}</p>
          ) : (
            ""
          )}
        </div>
      </div>
    </>
  );
};

export default DragAndDrop;
