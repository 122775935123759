import { useState, useEffect } from "react";
import { GoPrimitiveDot } from "react-icons/go";
import { FaArrowCircleRight } from "react-icons/fa";
import ReactPaginate from "react-paginate";
import { FaBriefcase, FaMedkit } from "react-icons/fa";
import { Link } from "react-router-dom";
import "../../App.css";
import Moment from "react-moment";

const SingRowProjects = ({
  data,
  dataUser,
  setProjectName,
  titleProject,
  clientProject,
  statusProject,
  filterProjectsTicketsActive,
  setFilterProjectsTicketsActive,
}) => {
  const [currentItems, setCurrentItems] = useState([]);
  const [pageCount, setPageCount] = useState(0);
  const [itemOffset, setItemOffset] = useState(0);
  const itemsPerPage = 10;

  useEffect(() => {
    const endOffset = itemOffset + itemsPerPage;

    setCurrentItems(
      data
        .filter((el) =>
          titleProject.toLowerCase() === ""
            ? el
            : el.title.toLowerCase().includes(titleProject.toLowerCase())
        )
        .filter((el) =>
          statusProject.toLowerCase() === ""
            ? el
            : el.status.toLowerCase().includes(statusProject.toLowerCase())
        )
        .filter((el) =>
          clientProject.toLowerCase() === ""
            ? el
            : el.customer.name
                .toLowerCase()
                .includes(clientProject.toLowerCase())
        )

        .slice(itemOffset, endOffset)
    );
    setPageCount(
      Math.ceil(
        data
          .filter((el) =>
            titleProject.toLowerCase() === ""
              ? el
              : el.title.toLowerCase().includes(titleProject.toLowerCase())
          )
          .filter((el) =>
            statusProject.toLowerCase() === ""
              ? el
              : el.status.toLowerCase().includes(statusProject.toLowerCase())
          )
          .filter((el) =>
            clientProject.toLowerCase() === ""
              ? el
              : el.customer.name
                  .toLowerCase()
                  .includes(clientProject.toLowerCase())
          ).length / itemsPerPage
      )
    );
  }, [itemOffset, itemsPerPage, titleProject, statusProject, clientProject]);

  const handlePageClick = (event) => {
    const newOffset =
      (event.selected * itemsPerPage) %
      data
        .filter((el) =>
          titleProject.toLowerCase() === ""
            ? el
            : el.title.toLowerCase().includes(titleProject.toLowerCase())
        )
        .filter((el) =>
          statusProject.toLowerCase() === ""
            ? el
            : el.status.toLowerCase().includes(statusProject.toLowerCase())
        )
        .filter((el) =>
          clientProject.toLowerCase() === ""
            ? el
            : el.customer.name
                .toLowerCase()
                .includes(clientProject.toLowerCase())
        ).length;
    setItemOffset(newOffset);
  };

  const FiltraTicketsDaiProgetti = (titoloProgetto) => {
    setProjectName(titoloProgetto);
    setFilterProjectsTicketsActive(true);
  };

  return (
    <>
      {currentItems.length === 0 ? (
        <tbody className='text-center mt-5'>
          <tr className=''>
            <td className='border-0' colSpan={12}>
              <h5 className='mt-4'>
                Nessun progetto corrisponde alla tua ricerca
              </h5>
            </td>
          </tr>
        </tbody>
      ) : (
        currentItems.map((project, index) => {
          return (
            <tbody className='justify-self-center' key={index}>
              <tr className='justify-self-center' key={project.id}>
                <td
                  className='align-items-center'
                  style={{ verticalAlign: "middle" }}
                >
                  {project.title}
                </td>
                <td
                  className='align-items-center'
                  style={{ verticalAlign: "middle" }}
                >
                  <div>
                    <img
                      src={project.customer.logo}
                      alt=''
                      style={{ width: "auto", height: "50px" }}
                    />
                  </div>
                  <div className='mt-2'>{project.customer.name}</div>
                </td>
                <td
                  className='align-items-center'
                  style={{ verticalAlign: "middle" }}
                >
                  {project.activeTicket || "0"}
                </td>

                <td
                  className='align-items-center text-start'
                  style={{ verticalAlign: "middle" }}
                >
                  <Moment format='DD MMMM YYYY'>{project.created.date}</Moment>
                </td>
                <td
                  className='align-items-center'
                  style={{ verticalAlign: "middle" }}
                >
                  {project.dueDate !== null ? (
                    <Moment format='DD MMMM YYYY'>
                      {project.dueDate.date}
                    </Moment>
                  ) : (
                    "Non definita"
                  )}
                  {dataUser[0].fulgeas && project.dueDate !== null ? (
                    project.dueDate.date < project.created.date ? (
                      <>
                        <span className='m-0'>,</span>
                        <span className='ms-1 badge bg-warning text-dark rounded-pill'>
                          Tempo scaduto
                        </span>
                      </>
                    ) : (
                      <>
                        <span className='m-0'>,</span>
                        <Moment
                          duration={project.created.date}
                          date={`${project.dueDate.date}`}
                          format={"DD giorni HH ore"}
                          className='ms-1'
                        />
                      </>
                    )
                  ) : (
                    ""
                  )}
                </td>
                <td
                  className='align-items-center'
                  style={{ verticalAlign: "middle" }}
                >
                  {project.status === "analysis"
                    ? "In Analisi"
                    : project.status === "engaged"
                    ? "Preso in carico"
                    : project.status === "working"
                    ? "In lavorazione"
                    : project.status === "uat"
                    ? "User Acceptance Test"
                    : project.status === "live"
                    ? "Live"
                    : "Chiuso"}
                </td>

                <td
                  className='fs-3 text-grey align-items-center'
                  style={{ verticalAlign: "middle" }}
                >
                  <Link
                    to='/tickets'
                    className='text-decoration-none'
                    onClick={() => FiltraTicketsDaiProgetti(project.id)}
                  >
                    <FaArrowCircleRight className='text-grey' />
                  </Link>
                </td>
              </tr>
            </tbody>
          );
        })
      )}

      <tbody className='row position-absolute p-0 mt-4' style={{ right: "0" }}>
        <tr className='col-12 p-0'>
          <td className='border-0'>
            <ReactPaginate
              breakLabel='...'
              nextLabel='>>'
              onPageChange={handlePageClick}
              pageRangeDisplayed={1}
              pageCount={pageCount}
              previousLabel='<<'
              renderOnZeroPageCount={null}
              containerClassName={"pagination text-end"}
              pageClassName={"page-item"}
              pageLinkClassName={"page-link"}
              previousClassName={"page-item"}
              previousLinkClassName={"page-link"}
              nextClassName={"page-item"}
              nextLinkClassName={"page-link"}
              breakClassName={"page-item"}
              breakLinkClassName={"page-link"}
              activeClassName={"active activeLink"}
              marginPagesDisplayed={1}
            />
          </td>
        </tr>
      </tbody>
    </>
  );
};

export default SingRowProjects;
