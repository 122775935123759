import React from "react";
import SidebarData from "./data/SidebarData";
import SubMenu from "./SubMenu";

const Sidebar = ({
  sidebar,
  setStatus,
  setOpenTickets,
  setTypeOfTicket,
  setProjectName,
  setType,
  filterProjectsTicketsActive,
  setFilterProjectsTicketsActive,
}) => {
  return (
    <nav
      className={` ${sidebar ? "nav-menu active shadow" : "nav-menu"}`}
      id='sidebar'
      style={{ zIndex: 1, width: "200px" }}
    >
      <div className='m-0 w-100'>
        {SidebarData.map((item, index) => {
          return (
            <SubMenu
              item={item}
              key={index}
              setStatus={setStatus}
              setOpenTickets={setOpenTickets}
              setProjectName={setProjectName}
              setTypeOfTicket={setTypeOfTicket}
              setType={setType}
              filterProjectsTicketsActive={filterProjectsTicketsActive}
              setFilterProjectsTicketsActive={setFilterProjectsTicketsActive}
            />
          );
        })}
      </div>
    </nav>
  );
};

export default Sidebar;
