import React from "react";
import UserTicketsMobile from "./UserTicketsMobile";
import { FaArrowCircleRight } from "react-icons/fa";
import CollapseInformationTickets from "./CollapseInformationTickets";
import { Link, useLocation } from "react-router-dom";

const SingRowMobile = ({ tickets, projectName, openTickets, status, type }) => {
  const location = useLocation();
  return (
    <div className={`row m-0 p-0`}>
      {tickets
        .filter((ticket) =>
          projectName === ""
            ? ticket
            : ticket.project.id === parseInt(projectName)
        )
        .filter((el) =>
          status.toLowerCase() === ""
            ? el
            : el.status.toLowerCase().includes(status.toLowerCase())
        )
        .filter((el) => (openTickets === "" ? el : el.status !== "closed"))

        .filter((el) => (type === "" ? el : el.type === type))

        .map((ticket, index) => {
          return (
            <div className={`col-12 `} key={index}>
              <div
                className={`my-2 rounded-class ${
                  ticket.type === "task"
                    ? "bg-blue border-blue"
                    : "bg-red border-red"
                } overflow-hidden`}
              >
                <div
                  className={`row d-flex p-2 align-items-center position-relative`}
                >
                  <div className='col-auto pe-0'>
                    <UserTicketsMobile data={ticket} key={ticket.id} />
                  </div>
                  <div
                    className='col mx-2 p-0 text-white position-relative'
                    style={{ wordBreak: "break-all" }}
                  >
                    {ticket.title.length > 35
                      ? ticket.title.substr(0, 35) + " ..."
                      : ticket.title}
                  </div>
                  <div
                    className='col-auto p-0 justify-content-end'
                    style={{ zIndex: 2 }}
                  >
                    <Link
                      to={`tickets/${ticket.id}`}
                      className='text-decoration-none text-white text-end'
                    >
                      <FaArrowCircleRight
                        style={{ width: "3em" }}
                        className=' h4 mb-0 fs-2'
                      />
                    </Link>
                  </div>
                  <a
                    className='stretched-link'
                    data-bs-toggle='collapse'
                    href={`#collapseMobile-${ticket.id}`}
                    role='button'
                    aria-expanded='false'
                    aria-controls={`collapseMobile-${ticket.id}`}
                  ></a>
                </div>
                <div className='col-12 overflow-auto'>
                  <CollapseInformationTickets ticket={ticket} />
                </div>
              </div>
            </div>
          );
        })}
      {/* <div
        className={`col-12 d-flex justify-content-center ${
          location.pathname !== "/dashboard" ? "d-none" : ""
        }`}
      >
        <Link
          className='btn bg-tickets text-white mt-3 text-decoration-none'
          to='/tickets'
        >
          Vai ai tickets
        </Link>
      </div> */}
    </div>
  );
};

export default SingRowMobile;
