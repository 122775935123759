import React, { useState, useEffect } from "react";
import useAuth from "../../useAuth";
import { GrLogout } from "react-icons/gr";

const Profile = ({ dataUser }) => {
  const { logout } = useAuth();
  const [openOptionsProfile, setOpenOptionsProfile] = useState(false);

  useEffect(() => {
    const handleClick = (event) => {
      if (!event.target.closest(".cont-profile")) {
        setOpenOptionsProfile(false);
      }
    };
    document.addEventListener("click", handleClick);
    return () => {
      document.removeEventListener("click", handleClick);
    };
  }, [openOptionsProfile]);

  return dataUser.map((profile, index) => {
    return (
      <React.Fragment key={index}>
        <div className='row justify-content-end m-0 position-relative p-0 profile-image-navbar'>
          <div
            className='col-12 m-0 p-0 cont-profile d-flex flex-inline align-items-center'
            onClick={() => setOpenOptionsProfile(!openOptionsProfile)}
          >
            <span className='text-white m-0'>
              {profile.name} {profile.lastname}
            </span>
            {profile.avatar ? (
              <span>
                <div
                  className='image-profile-navbar d-none d-lg-block'
                  style={{
                    background: `url('${profile.avatar}') center center / cover`,
                  }}
                ></div>
              </span>
            ) : (
              <span className='bg-red p-3 rounded-circle text-white image-profile d-none d-lg-inline'>
                {profile.name.charAt(0)}
                {profile.lastname.charAt(0)}
              </span>
            )}

            <div
              className={`font-13 position-absolute bg-white shadow  end-0 text-center ${
                openOptionsProfile ? "" : "d-none"
              }`}
              style={{ top: "50px" }}
            >
              <div className='row align-items-center w-100 px-0 py-3 m-0'>
                <a className='m-0 text-decoration-none text-black'>
                  <p
                    className='py-0 m-0 text-black'
                    data-bs-toggle='modal'
                    data-bs-target='#modalLogout'
                  >
                    <GrLogout />
                    <span className='m-0 ms-2'>Logout</span>
                  </p>
                </a>
              </div>
            </div>
          </div>
        </div>

        <div
          className='modal fade'
          id='modalLogout'
          tabIndex='-1'
          aria-labelledby='modalLogout'
          aria-hidden='true'
        >
          <div className='modal-dialog modal-dialog-centered'>
            <div className='modal-content'>
              <div className='modal-header border-0'>
                <h1
                  className='modal-title fs-5 d-flex align-items-center'
                  id='modalLogout'
                >
                  <GrLogout />
                  <span className='m-0 ms-2 '>Logout</span>
                </h1>
                <button
                  type='button'
                  className='btn-close'
                  data-bs-dismiss='modal'
                  aria-label='Close'
                ></button>
              </div>
              <div className='modal-body'>
                <h5>Confermi di voler uscire dal tuo profilo?</h5>
              </div>
              <div className='modal-footer border-0'>
                <button
                  type='button'
                  className='btn bg-orange text-white'
                  onClick={logout}
                >
                  Confermo
                </button>
                <button
                  type='button'
                  className='btn btn-secondary'
                  data-bs-dismiss='modal'
                >
                  Annulla
                </button>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  });
};

export default Profile;
