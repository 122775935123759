import React, { useContext, useEffect } from "react";
import { StateContext } from "../../StateContainer";
import { Link } from "react-router-dom";
import { MdKeyboardArrowRight } from "react-icons/md";
import SingRowMobile from "../../components/tickets/SingRowMobile";
import ProgettiMobile from "../../components/ProgettiMobile";
import {
  FaTicketAlt,
  FaMedkit,
  FaBriefcase,
  FaFolderOpen,
} from "react-icons/fa";
import { IoMdNotifications } from "react-icons/io";
import SingleNotificationMobile from "../SingleNotificationMobile";

const SecRowCards = ({ dataNotifications, dataProjects, dataTickets }) => {
  const state = useContext(StateContext);
  const {
    setShowMoreNotifications,
    showMoreNotifications,
    search,
    setSearch,
    status,
    setStatus,
    priority,
    setPriority,
    projectName,
    setProjectName,
    id,
    setId,
    typeOfTicket,
    type,
    setType,
    setTypeOfTicket,
    openTickets,
    setOpenTickets,
    filterProjectsTicketsActive,
    setFilterProjectsTicketsActive,
    width,
    setWidth,
    setTitlePage,
    result,
    setResult,
  } = state;

  const openTicketsWithFilter = (id) => {
    setProjectName(id);
    setFilterProjectsTicketsActive(true);
  };

  useEffect(() => {
    setProjectName("");
    setType("");
    setOpenTickets("");
    setStatus("");
  }, []);

  return (
    <section className='p-0'>
      <div className='row m-0 p-0'>
        {/* Notifiche Card */}
        <div
          className={`card col-12 col-xl-4 p-0 last-news-cards text-white rounded d-none d-xl-inline-flex`}
          key='1'
          style={{ height: "500px" }}
        >
          <div
            className={`bg-notifiche card-header p-0 text-start py-1 d-flex align-items-center`}
          >
            <span>
              <IoMdNotifications />
            </span>
            <span>Ultime notifiche</span>
          </div>
          <div className='card-body text-dark overflow-scroll'>
            {dataNotifications
              .map((row) => {
                return row.filter((el) => el.read === false);
              })
              .map((row, index) => {
                return row.length === 0 ? (
                  <p key={index}>Nessuna notifica</p>
                ) : (
                  row.map((el, index) => {
                    return (
                      <div
                        className={`border-orange my-2 row position-relative`}
                        key={index}
                      >
                        <div
                          className={`bg-notifiche col-1 text-center p-0 text-white d-flex align-items-center`}
                        >
                          <span className='m-0 align-middle center-icon mx-auto'>
                            <IoMdNotifications />
                          </span>
                        </div>
                        <div className='col-10 align-items-center py-1'>
                          <span key={index} className='m-0'>
                            {el.message}
                          </span>
                        </div>
                        <div className='col-1'>
                          <span className='float-end py-1'>
                            <MdKeyboardArrowRight />
                          </span>
                        </div>
                        <Link
                          to={`tickets/${el.ticketId}`}
                          className='stretched-link'
                        ></Link>
                      </div>
                    );
                  })
                );
              })}
          </div>
          <div className='card-footer border-0 bg-transparent text-dark col-12 text-end'>
            <Link to='/notifiche' className='text-decoration-none'>
              Vedi Ultime notifiche
            </Link>
          </div>
        </div>

        {/* Progetti Card */}
        <div
          className={`card col-12 col-xl-4 p-0 last-news-cards text-white rounded d-none d-xl-inline-flex`}
          key='2'
          style={{ height: "500px" }}
        >
          <div
            className={`bg-progetti card-header p-0 text-start py-1 d-flex align-items-center`}
          >
            <span>
              <FaFolderOpen />
            </span>
            <span>Ultimi Progetti</span>
          </div>
          <div className='card-body text-dark overflow-scroll'>
            {dataProjects.map((row, index) => {
              return row.length === 0 ? (
                <p key={index}>Nessuna progetto</p>
              ) : (
                row.map((el, index) => {
                  return (
                    <div
                      className={`border-purple my-2 row position-relative`}
                      key={index}
                    >
                      <div
                        className={`bg-progetti col-1 p-0 text-white d-flex align-items-center`}
                      >
                        <span className='m-0 mx-auto'>
                          <FaFolderOpen />
                        </span>
                      </div>
                      <div className='col-10 align-items-center py-1'>
                        <span key={index} className='m-0'>
                          {el.title}
                        </span>
                      </div>
                      <div className='col-1'>
                        <span className='float-end py-1'>
                          <MdKeyboardArrowRight />
                        </span>
                      </div>
                      <Link
                        to='/tickets'
                        onClick={() => openTicketsWithFilter(el.id)}
                        className='stretched-link'
                      ></Link>
                    </div>
                  );
                })
              );
            })}
          </div>
          <div className='card-footer border-0 bg-transparent text-dark col-12 text-end'>
            <Link to='/progetti' className='text-decoration-none'>
              Vedi Ultimi Progetti
            </Link>
          </div>
        </div>

        {/* Tickets Card */}
        <div
          className={`card col-12 col-xl-4 p-0 last-news-cards text-white rounded d-none d-xl-inline-flex`}
          key='3'
          style={{ height: "500px" }}
        >
          <div
            className={`bg-ticket card-header p-0 text-start py-1 d-flex align-items-center`}
          >
            <span>
              <FaTicketAlt />
            </span>
            <span>Ultimi Tickets</span>
          </div>
          <div className='card-body text-dark overflow-scroll'>
            {dataTickets.map((row, index) => {
              return row.length === 0 ? (
                <p key={index}>Nessun ticket</p>
              ) : (
                row.map((el, index) => {
                  return (
                    // <h1 key={index}>{el.title}</h1>
                    <div
                      className={`${
                        el.type === "support" ? "border-red" : "border-blue"
                      } my-2 row position-relative`}
                      key={index}
                    >
                      <div
                        className={`${
                          el.type === "support" ? "bg-red m-0" : "bg-blue m-0"
                        } col-1 p-0 text-center text-white d-flex align-items-center`}
                      >
                        <span className='m-0 mx-auto'>
                          {el.type === "support" ? (
                            <FaBriefcase />
                          ) : (
                            <FaMedkit />
                          )}
                        </span>
                      </div>
                      <div className='col-10 align-items-center py-1'>
                        <span key={index} className='m-0'>
                          #{el.id} {el.title}
                        </span>
                      </div>
                      <div className='col-1'>
                        <span className='float-end py-1'>
                          <MdKeyboardArrowRight />
                        </span>
                      </div>
                      <Link
                        to={`/tickets/${el.id}`}
                        onClick={() => setType(el.type)}
                        className='stretched-link'
                      ></Link>
                    </div>
                  );
                })
              );
            })}
          </div>
          <div className='card-footer border-0 bg-transparent text-dark col-12 text-end'>
            <Link to='/tickets' className='text-decoration-none'>
              Vedi Ultimi Ticket
            </Link>
          </div>
        </div>
      </div>

      <div className='d-grid container gap-3 d-block d-xl-none'>
        {/* Notifiche Mobile */}
        <div>
          <div className='p-0 row d-flex align-items-center position-relative'>
            <div className='col d-flex  justify-content-end'>
              <hr className='w-50' />
            </div>
            <div
              className='col-auto d-flex align-items-center gap-1 p-0 justify-content-center'
              style={{ minWidth: "200px" }}
            >
              <IoMdNotifications />
              <span className='m-0 fs-5 text-uppercase'>Ultime notifiche</span>
            </div>
            <div className='col d-flex justify-content-start'>
              <hr className='w-50' />
            </div>
            <a
              data-bs-toggle='collapse'
              href={`#collapse-notification`}
              role='button'
              aria-expanded='true'
              className='stretched-link'
              aria-controls={`collapse-notification`}
            ></a>
          </div>

          <div className='collapse show' id='collapse-notification'>
            {dataNotifications
              .map((row) => {
                return row.filter((el) => el.read === false);
              })
              .map((row, index) => {
                return row.length === 0 ? (
                  <p className='text-center' key={index}>
                    Nessuna notifica
                  </p>
                ) : (
                  <SingleNotificationMobile
                    setShowMoreNotifications={setShowMoreNotifications}
                    notification={row}
                    setProjectName={setProjectName}
                    result={result}
                    setResult={setResult}
                    setWidth={setWidth}
                  />
                );
              })}
            <div className={`col-12 d-flex justify-content-center`}>
              <Link
                className='btn bg-orange text-white mt-3 text-decoration-none'
                to='/notifiche'
              >
                Vai alle notifiche
              </Link>
            </div>
          </div>
        </div>

        {/* Progetti Mobile */}
        <div>
          <div className='p-0 row d-flex align-items-center position-relative'>
            <div className='col d-flex  justify-content-end position-relative'>
              <hr className='w-50' />
            </div>
            <div
              className='col-auto ps-0 d-flex align-items-center gap-1 p-0 text-center justify-content-center'
              style={{ minWidth: "200px" }}
            >
              <FaFolderOpen />
              <span className='m-0 fs-5 text-uppercase'>Ultimi progetti</span>
            </div>
            <div className='col  d-flex justify-content-start'>
              <hr className='border-3 w-50' />
            </div>
            <a
              data-bs-toggle='collapse'
              href={`#collapse-projects`}
              role='button'
              aria-expanded='true'
              className='stretched-link'
              aria-controls={`collapse-projects`}
            ></a>
          </div>

          <div className='collapse show' id='collapse-projects'>
            {dataProjects.map((row, index) => {
              return row.length === 0 ? (
                <p key={index} className='text-center'>
                  Nessun progetto
                </p>
              ) : (
                <ProgettiMobile
                  key={index}
                  data={row}
                  setProjectName={setProjectName}
                />
              );
            })}
            <div className={`col-12 d-flex justify-content-center`}>
              <Link
                className='btn bg-purple text-white mt-3 text-decoration-none'
                to='/progetti'
              >
                Vai ai progetti
              </Link>
            </div>
          </div>
        </div>

        {/* Tickets Mobile */}
        <div className='p-0'>
          <div className='p-0 row d-flex align-items-center position-relative'>
            <div className='col d-flex  justify-content-end '>
              <hr className='w-50' />
            </div>
            <div
              className='col-auto  d-flex align-items-center gap-1 justify-content-center'
              style={{ minWidth: "200px" }}
            >
              <FaTicketAlt />
              <span className='m-0 fs-5 text-uppercase'>Ultimi Tickets</span>
            </div>
            <div className='col d-flex justify-content-start'>
              <hr className='border-3 w-50' />
            </div>
            <a
              data-bs-toggle='collapse'
              href={`#collapse-mobile`}
              role='button'
              aria-expanded='true'
              className='stretched-link'
              aria-controls={`collapse-mobile`}
            ></a>
          </div>

          <div className='collapse show' id='collapse-mobile'>
            {dataTickets.map((row, index) => {
              return row.length === 0 ? (
                <p key={index} className='text-center'>
                  Nessun ticket
                </p>
              ) : (
                <SingRowMobile
                  key={index}
                  tickets={row}
                  projectName={projectName}
                  dataUser={dataTickets.user}
                  search={search}
                  status={status}
                  priority={priority}
                  prevSearch={search}
                  prevStatus={status}
                  prevPriority={priority}
                  type={type}
                  setType={setType}
                  openTickets={openTickets}
                  setOpenTickets={setOpenTickets}
                />
              );
            })}
            <div className={`col-12 d-flex justify-content-center`}>
              <Link
                className='btn bg-tickets text-white mt-3 text-decoration-none'
                to='/tickets'
              >
                Vai ai tickets
              </Link>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default SecRowCards;
