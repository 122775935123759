import React, { useEffect, useState, useRef } from "react";
import { FaSearch } from "react-icons/fa";
import { TfiClose } from "react-icons/tfi";
import { debounce } from "lodash";
import Loading from "../../components/loading/Loading";
import { Link } from "react-router-dom";
import { useHistory } from "react-router-dom";

const SearchMobile = () => {
  const history = useHistory();
  const [searchQuery, setSearchQuery] = useState("");
  const searchTicket = `${process.env.REACT_APP_BASE_URL}/ticket-search`;
  const token = localStorage.getItem("token");

  const [loading, setLoading] = useState(false);
  const [resultsOfSearch, setResultsOfSearch] = useState([]);

  useEffect(() => {
    setLoading(true);
    fetch(searchTicket, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "Authorization": "Bearer " + token,
      },
      body: JSON.stringify({
        keyphrase: searchQuery,
      }),
    }).then((response) => {
      if (response.status === 401) {
        localStorage.removeItem("token");
        localStorage.removeItem("tokenExpiration");
        window.location.replace("/login");
      } else {
        response
          .json()
          .then((data) => {
            setLoading(false);

            setResultsOfSearch([data.response]);
          })
          .catch((err) => console.log("errore"));
      }
    });
  }, [searchQuery]);

  const [searchTerm, setSearchTerm] = useState("");

  useEffect(() => {
    let timerId;
    if (searchTerm !== "") {
      timerId = setTimeout(() => {
        setSearchQuery(searchTerm);
      }, 1000);
    }

    return () => {
      clearTimeout(timerId);
    };
  }, [searchTerm]);

  const handleInputChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const highlightText = (text, query, contextLength = 30) => {
    if (!query) {
      return text;
    }

    const queryRegex = new RegExp(`(${query})`, "gi");
    const match = queryRegex.exec(text);

    if (!match) {
      return text;
    }

    const start = Math.max(0, match.index - contextLength);
    const end = Math.min(
      text.length,
      match.index + match[0].length + contextLength
    );

    const prefix = start > 0 ? "..." : "";
    const suffix = end < text.length ? "..." : "";

    const highlightedText = text
      .slice(start, end)
      .replace(queryRegex, "<mark>$1</mark>");

    return `${prefix}${highlightedText}${suffix}`;
  };

  return (
    <div
      className='offcanvas offcanvas-top h-100'
      tabIndex='-1'
      id='offcanvasTop'
      aria-labelledby='offcanvasTopLabel'
    >
      <div className='d-flex justify-content-end p-4'>
        <button
          type='button'
          className='text-reset border-0 bg-transparent'
          data-bs-dismiss='offcanvas'
          aria-label='Close'
        >
          <TfiClose className='fs-2 color-yellow' />
        </button>
      </div>
      <div
        className='offcanvas-header text-white d-flex flex-column align-content-center'
        style={{ marginTop: "200px" }}
      >
        <h5 className='d-flex gap-2'>
          <FaSearch />
          Cerca
        </h5>
        <input
          type='search'
          className='bg-transparent border-bottom-light-grey text-white w-75 mb-3'
          value={searchTerm}
          onChange={handleInputChange}
        />
      </div>
      <div className='offcanvas-body text-center'>
        <div className='row'>
          {searchQuery === "" ? (
            ""
          ) : loading ? (
            <Loading />
          ) : (
            resultsOfSearch.map((row) => {
              return row.length === 0 ? (
                <h5>Nessun ticket corrispondente alla tua ricerca</h5>
              ) : (
                row.map((risultato, index) => {
                  return (
                    <div className='col-12 my-2' key={index}>
                      <Link
                        to={`/tickets/${risultato.id}`}
                        onClick={(e) => {
                          e.preventDefault();
                          history.push(`/tickets/${risultato.id}`);
                          window.location.reload();
                        }}
                        className='text-decoration-none text-white'
                      >
                        <div
                          className='text-start rounded p-2 position-relative'
                          style={{ border: "1px white solid" }}
                        >
                          <h5>
                            <b
                              className='color-yellow'
                              style={{ wordBreak: "break-word" }}
                            >
                              <span
                                dangerouslySetInnerHTML={{
                                  __html: highlightText(
                                    risultato.title,
                                    searchQuery
                                  ),
                                }}
                              />
                            </b>
                          </h5>

                          <p style={{ wordBreak: "break-word" }}>
                            <span
                              dangerouslySetInnerHTML={{
                                __html: highlightText(
                                  risultato.stack,
                                  searchQuery
                                ),
                              }}
                            />
                          </p>
                        </div>
                      </Link>
                    </div>
                  );
                })
              );
            })
          )}
        </div>
      </div>
    </div>
  );
};

export default SearchMobile;
