import React, { useState, useEffect } from "react";
import axios from "axios";
import UserMobileNotification from "./UserMobileNotification";
import { MdCancel } from "react-icons/md";
import { BsFillTrashFill } from "react-icons/bs";
import CollapseNotifications from "../components/CollapseNotifications";
import { render } from "react-dom";
// Import the react-swipe-to-delete-component
import SwipeToDelete from "react-swipe-to-delete-component";
// Import styles of the react-swipe-to-delete-component
import "react-swipe-to-delete-component/dist/swipe-to-delete.css";
import { Link, useLocation } from "react-router-dom";

const SingleNotificationMobile = ({
  notification,
  setProjectName,
  showMoreNotifications,
  setShowMoreNotifications,
  setWidth,
}) => {
  const location = useLocation();
  const deleteNotificationUrl = `${process.env.REACT_APP_BASE_URL}/delete-notification`;
  const token = localStorage.getItem("token");

  useEffect(() => {
    const handleResize = () => {
      setWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  const deleteMessageButton = (notificationId) => {
    const config = {
      method: "post",
      url: deleteNotificationUrl,
      headers: {
        Accept: "application/json",
        "Content-type": "application/json",
        "Authorization": "Bearer " + token,
      },
      data: {
        notificationId: notificationId,
      },
    };

    axios(config)
      .then((res) => {})
      .catch((err) => console.log(err));
  };

  return (
    <div className={`row m-0 p-0 d-block d-lg-none`}>
      {notification.map((message, index) => {
        return (
          <div className={`col-12`} key={index}>
            <div
              className={`my-2 rounded-class ${
                message.read ? "bg-light-grey" : "bg-orange text-white"
              } outline-orange overflow-hidden`}
            >
              <SwipeToDelete
                deleteSwipe={0.5}
                background={<BsFillTrashFill className='text-white' />}
                key={message.id}
                onDelete={() => deleteMessageButton(message.id)}
                style={{ zIndex: 1 }}
              >
                <div
                  className={`row d-flex p-2 align-items-center gap-2 position-relative ${
                    message.read ? "bg-light-grey" : "bg-orange text-white"
                  }`}
                >
                  <div className='col-auto pe-0 position-relative'>
                    <div className=''>
                      <UserMobileNotification
                        data={message.author}
                      ></UserMobileNotification>
                    </div>
                  </div>
                  <div
                    className='col p-0 d-flex justify-content-between'
                    style={{ fontSize: "11pt" }}
                  >
                    <p>
                      {message.message.length > 30
                        ? message.message.substr(0, 30) + " ..."
                        : message.message}
                    </p>
                  </div>

                  <a
                    className='stretched-link'
                    data-bs-toggle='collapse'
                    href={`#collapseNotifications-${message.id}`}
                    role='button'
                    aria-expanded='true'
                    aria-controls={`collapseNotifications-${message.id}`}
                  ></a>
                </div>
                <div className='col-12'>
                  <CollapseNotifications
                    data={message}
                    setProjectName={setProjectName}
                  />
                </div>
              </SwipeToDelete>
            </div>
          </div>
        );
      })}

      {/* <div
        className={`col-12 d-flex justify-content-center ${
          location.pathname !== "/dashboard" ? "d-none" : ""
        }`}
      >
        <Link
          className='btn bg-orange text-white mt-3 text-decoration-none'
          to='/notifiche'
        >
          Vai alle notifiche
        </Link>
      </div> */}

      <div
        className={`col-12 d-flex justify-content-center ${
          showMoreNotifications > notification.length ||
          location.pathname === "/dashboard"
            ? "d-none"
            : ""
        }`}
      >
        <button
          className='btn bg-orange text-white mt-3'
          onClick={() => setShowMoreNotifications(showMoreNotifications + 20)}
        >
          Mostra altre notifiche
        </button>
      </div>
    </div>
  );
};

export default SingleNotificationMobile;
