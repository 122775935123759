import React from "react";
import { Link } from "react-router-dom";
import Navbar from "../components/navbar/Navbar";

const Page404 = () => {
  return (
    <section>
      <div className='container mt-5'>
        <div className='row justify-content-center mt-5'>
          <div className='col-12 text-center py-2'>
            <h1>Errore 404</h1>
            <p className='mb-3'>Nessuna pagina corrisponde alla tua ricerca</p>
            <Link to='/dashboard'>Torna alla Home</Link>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Page404;
