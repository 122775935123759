import React, { useEffect, useState } from "react";
import { FaFilter, FaPlusCircle } from "react-icons/fa";
import { RiArrowDownSFill } from "react-icons/ri";
import "../../App.css";
import { filterProjectsData } from "./filterProjectsData";

const Filter = ({
  data,
  setTitleProject,
  setClientProject,
  setStatusProject,
  titleProject,
  statusProject,
  clientProject,
}) => {
  // Filter section

  const [showTypeFilter, setShowTypeFilter] = useState(false);
  const [azzera, setAzzera] = useState(false);

  const [contatore, setContatore] = useState(0);
  const [userinfo, setUserInfo] = useState({
    ricerca: [],
    response: [],
  });

  const HandleChange = (e) => {
    // Destructuring
    setAzzera(false);
    let { value, checked } = e.target;
    const { ricerca } = userinfo;

    // Case 1 : The user checks the box
    if (checked) {
      setUserInfo({
        ricerca: [...ricerca, value],
        response: [...ricerca, value],
      });

      setContatore((old) => old + 1);
    }

    // Case 2  : The user unchecks the box
    else {
      setUserInfo({
        ricerca: ricerca.filter((e) => e !== value),
        response: ricerca.filter((e) => e !== value),
      });
      setContatore((old) => old - 1);
      setClientProject("");
      setStatusProject("");
      setTitleProject("");
    }
  };

  const Azzera = (e) => {
    setAzzera(true);
    setContatore(0);
    setShowTypeFilter(false);
    let { value, checked } = e.target;
    const { ricerca } = userinfo;
    setUserInfo({
      ricerca: ricerca.filter((e) => e === !e),
      response: ricerca.filter((e) => e === !e),
    });
    setStatusProject("");
    setClientProject("");
    setTitleProject("");
  };

  const ShowSection = () => {
    setAzzera(false);
    setShowTypeFilter(!showTypeFilter);
  };

  const selectProgettiFiltro = (event) => {
    if (event.target.value === "defaultSelectProject") {
      setTitleProject("");
    } else {
      setTitleProject(event.target.value);
    }
  };

  const filtraStatoDelProgetto = (event) => {
    if (event.target.value === "defaultStatusProject") {
      setStatusProject("");
    } else {
      setStatusProject(event.target.value);
    }
  };

  const filtraClienteDelProgetto = (event) => {
    if (event.target.value === "defaultCliente") {
      setClientProject("");
    } else {
      setClientProject(event.target.value);
    }
  };
  return (
    <>
      <div className='row mt-3'>
        <div className='col-12 align-self-center p-0'>
          <span className='m-0 align-self-center'>
            <FaFilter />
          </span>
          <span className='align-self-center m-0 mx-2 font-14'>Filtri</span>
          <span className='badge rounded-circle bg-secondary align-self-center m-0'>
            {contatore}
          </span>
          <span className='align-self-center m-0 position-relative'>
            <RiArrowDownSFill onClick={ShowSection} />
            <span className='position-absolute mr-0' style={{ zIndex: 2 }}>
              {!azzera ? (
                <div
                  className={`${
                    showTypeFilter ? "d-block" : "d-none"
                  } shadow bg-white p-2 font-13`}
                  style={{ width: "150px" }}
                >
                  {filterProjectsData.map((singleCheck) => {
                    return (
                      <div className='form-check' key={singleCheck.id}>
                        <input
                          className='form-check-input'
                          type='checkbox'
                          value={singleCheck.nome}
                          onChange={HandleChange}
                          key={singleCheck.id}
                          id={singleCheck.nome}
                        />
                        <label
                          className='form-check-label'
                          htmlFor={singleCheck.nome}
                        >
                          {singleCheck.nome}
                        </label>
                      </div>
                    );
                  })}
                </div>
              ) : (
                ""
              )}
            </span>
          </span>
        </div>
      </div>

      {/* Open filter Section */}

      <div
        className={`${
          contatore > 0 ? `d-flex` : `d-none`
        } row bg-grey py-2 mt-2`}
        style={{ zIndex: 1 }}
      >
        <div
          className='col-8 align-self-center d-flex align-items-center'
          id='element'
          style={{ zIndex: 1 }}
        >
          {!azzera
            ? userinfo.response.map((el, index) => {
                return (
                  <React.Fragment key={index}>
                    {el === "Titolo Progetto" ? (
                      <div className='d-flex align-items-center col-4 ms-3'>
                        <label
                          htmlFor='selectProjects'
                          className='me-1'
                          key={index}
                        >
                          {el}
                        </label>
                        <select
                          id='selectProjects'
                          className='form-select me-0'
                          aria-label='Default select example'
                          onChange={selectProgettiFiltro}
                          value={titleProject}
                        >
                          <option value='defaultSelectProject'>
                            Seleziona un progetto
                          </option>
                          {data.map((row) =>
                            row.map((progetto) => {
                              return (
                                <option
                                  key={progetto.id}
                                  value={progetto.title.toLowerCase()}
                                >
                                  {progetto.title}
                                </option>
                              );
                            })
                          )}
                        </select>
                      </div>
                    ) : (
                      <>
                        {el === "Stato Progetto" ? (
                          <div className='d-flex align-items-center col-4 ms-3'>
                            <label
                              htmlFor='selectProjects'
                              className='me-1'
                              key={index}
                            >
                              {el}
                            </label>
                            <select
                              id='selectProjects'
                              className='form-select me-0'
                              aria-label='Default select example'
                              onChange={filtraStatoDelProgetto}
                              value={statusProject}
                            >
                              <option value='defaultStatusProject'>
                                Scegli lo stato del progetto
                              </option>
                              <option value='analysis'>In analisi</option>
                              <option value='engaged'>Preso in carico</option>
                              <option value='working'>In lavorazione</option>
                              <option value='uat'>User Acceptance Test</option>
                              <option value='live'>Live</option>
                              <option value='closed'>Chiuso</option>
                            </select>
                          </div>
                        ) : (
                          <div className='d-flex align-items-md-center col-4 ms-3'>
                            <label
                              htmlFor='selectStatus'
                              className='me-1'
                              key={index}
                            >
                              {el}
                            </label>
                            <select
                              id='selectStatus'
                              className='form-select'
                              aria-label='Default select example'
                              onChange={filtraClienteDelProgetto}
                              value={clientProject}
                            >
                              <option value='defaultCliente'>
                                Seleziona Cliente
                              </option>
                              {data.map((row) =>
                                row.map((progetto) => {
                                  return (
                                    <option
                                      key={progetto.customer.id}
                                      value={progetto.customer.name.toLowerCase()}
                                    >
                                      {progetto.customer.name}
                                    </option>
                                  );
                                })
                              )}
                            </select>
                          </div>
                        )}
                      </>
                    )}
                  </React.Fragment>
                );
              })
            : ""}
        </div>
        <div className='col-4 text-end'>
          <button className='btn bg-secondary text-white' onClick={Azzera}>
            Azzera
          </button>
        </div>
      </div>
    </>
  );
};

export default Filter;
