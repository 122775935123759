export const filterProjectsData = [
  {
    id: 1,
    nome: "Titolo Progetto",
  },

  {
    id: 2,
    nome: "Stato Progetto",
  },

  {
    id: 3,
    nome: "Cliente",
  },
];
