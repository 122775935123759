import React, { useEffect, useState, useContext } from "react";
import * as FaIcons from "react-icons/fa";
import "../../App.css";
import { IconContext } from "react-icons";
import { Link } from "react-router-dom";
import Icons from "../header-components/Icons";
import Profilo from "../header-components/Profile";
import BottomNavbar from "../bottom-navbar/BottomNavbar";
import Logo from "../../img/logo_fulgeas.svg";
import Sidebar from "../sidebar/Sidebar";
import FulgeasLogoCustom from "../../img/fulgeas_logo_customer_white.png";
import { logout } from "../../useAuth";
import { StateContext } from "../../StateContainer";
import useAuth from "../../useAuth";
import { useHistory } from "react-router-dom";
import NavbarMobile from "./NavbarMobile";

function Navbar({}) {
  const history = useHistory();
  useAuth(history);
  const state = useContext(StateContext);
  const {
    search,
    setSearch,
    status,
    setStatus,
    priority,
    setPriority,
    setProjectName,
    type,
    setType,
    projectName,
    setTypeOfTicket,
    setOpenTickets,
    filterProjectsTicketsActive,
    setFilterProjectsTicketsActive,
    width,
    setWidth,
    sidebar,
    setSidebar,
  } = state;

  const [isLoading, setIsLoading] = useState(false);
  const [dataUser, setDataUser] = useState([]);
  const token = localStorage.getItem("token");
  const url = `${process.env.REACT_APP_BASE_URL}/dashboard`;
  useEffect(() => {
    const abortController = new AbortController();
    setIsLoading(true);
    fetch(url, {
      method: "POST",
      signal: abortController.signal,
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "Authorization": "Bearer " + token,
      },
    }).then((response) =>
      response
        .json()
        .then((data) => {
          setIsLoading(false);
          setDataUser([data.user]);
        })
        .catch((err) => {
          if (err.name === "AbortError") return;
          // if the query has been aborted, do nothing
          throw err;
        })
    );

    return () => {
      abortController.abort();
      // stop the query by aborting on the AbortController on unmount
    };
  }, []);

  const showSidebar = () => {
    setSidebar(!sidebar);
  };

  useEffect(() => {
    const handleResize = () => {
      setWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <>
      {width >= 992 ? (
        <IconContext.Provider value={{ color: "#fff" }}>
          {/* Toggle sulla sinistra */}
          <div
            className='h-100 d-block fixed-top position-sticky w-100'
            style={{ zIndex: 3 }}
          >
            <div className='sidebar-toggle-box m-0 bg-hamburger shadow'>
              <div
                className='icon-reorder tooltips'
                data-original-title='Toggle Navigation'
              >
                <FaIcons.FaBars onClick={showSidebar} className='m-0' />
              </div>
            </div>
          </div>

          {/* Navbar */}
          <div
            className='navbar position-relative p-0 m-0 fixed-top position-sticky shadow'
            style={{ zIndex: 3 }}
            key={1}
          >
            <div className='container-fluid m-0 px-2 text-center'>
              <div className='row m-0 p-0 d-flex' key={3}>
                <Icons
                  className='position-relative'
                  setTypeOfTicket={setTypeOfTicket}
                />
              </div>

              <div className='text-white'>
                {/* Logo Fulgeas versione Desktop */}
                <Link to='/'>
                  <img
                    src={FulgeasLogoCustom}
                    alt={FulgeasLogoCustom}
                    className='m-0 d-block logo-custom-navbar'
                  />
                </Link>
              </div>

              {isLoading ? (
                <div className='spinner-grow text-fulgeas' role='status'></div>
              ) : (
                <>
                  <Profilo dataUser={dataUser} />
                </>
              )}
            </div>
          </div>

          {/* Sidebar */}

          <Sidebar
            showSidebar={showSidebar}
            setStatus={setStatus}
            setOpenTickets={setOpenTickets}
            setTypeOfTicket={setTypeOfTicket}
            setProjectName={setProjectName}
            setType={setType}
            setFilterProjectsTicketsActive={setFilterProjectsTicketsActive}
            filterProjectsTicketsActive={filterProjectsTicketsActive}
            sidebar={sidebar}
            setSidebar={setSidebar}
          />
        </IconContext.Provider>
      ) : (
        <>
          <NavbarMobile user={dataUser} setTypeOfTicket={setTypeOfTicket} />
          <BottomNavbar setTypeOfTicket={setTypeOfTicket} />
        </>
      )}
    </>
  );
}

export default Navbar;
