import React from "react";
import "../../App.css";
import { BsDownload } from "react-icons/bs";
import { RiCloseCircleFill } from "react-icons/ri";
import { GrDocument } from "react-icons/gr";
import { AiOutlineDownload } from "react-icons/ai";

const AttachmentsOfMessages = ({ attachment, index }) => {
  console.log("sono attachment", attachment);
  return (
    <React.Fragment key={index}>
      <div className='card my-2' key={attachment.id}>
        <div className='card-body position-relative'>
          <a
            className=''
            data-bs-toggle='offcanvas'
            href={`#offcanvas-${attachment.id}`}
            style={{ cursor: "pointer" }}
            role='button'
            aria-controls={`offcanvas-${attachment.id}`}
          >
            <div className='position-absolute top-2 left-0 badge bg-dark text-capitalize'>
              {attachment.type.split("/")[0] === "image"
                ? "Immagine"
                : attachment.type.split("/")[0] === "video"
                ? "Video"
                : "File"}
            </div>
            {attachment.type.split("/")[0] === "image" ? (
              <div className='d-flex align-items-center justify-content-center container-preview-file'>
                <img
                  src={`${attachment.url}`}
                  alt=''
                  className=' img-fluid'
                  style={{ height: "10vh" }}
                />
              </div>
            ) : attachment.type.split("/")[0] === "video" ? (
              <div className='d-flex align-items-center justify-content-center'>
                <video
                  src={`${attachment.url}`}
                  alt=''
                  className='img-fluid'
                  style={{ height: "10vh" }}
                />
              </div>
            ) : (
              <div className='d-flex align-items-center justify-content-center fs-1'>
                <GrDocument style={{ height: "10vh" }} />
              </div>
            )}
          </a>
        </div>
        <div className='card-footer'>
          <a
            className=''
            data-bs-toggle='offcanvas'
            href={`#offcanvas-${attachment.id}`}
            style={{ cursor: "pointer" }}
            role='button'
            aria-controls={`offcanvas-${attachment.id}`}
          >
            <p>
              {attachment.title.length > 20
                ? attachment.title.substring(0, 20) + " ..."
                : attachment.title}
            </p>
          </a>
        </div>
      </div>

      {/* Modale dei files */}
      <div
        className='offcanvas offcanvas-top h-100 '
        tabindex='-1'
        id={`offcanvas-${attachment.id}`}
        aria-labelledby='offcanvasExampleLabel'
      >
        <div className='offcanvas-header d-flex align-content-center'>
          <a
            className='btn text-white'
            href={`${attachment.url}`}
            download
            target={"_blank"}
          >
            <AiOutlineDownload className='fs-3' />
          </a>
          <h5 className='offcanvas-title' id='offcanvasExampleLabel'>
            {attachment.title}
          </h5>

          <a
            type='button'
            className='btn text-reset text-white'
            data-bs-dismiss='offcanvas'
            aria-label='Close'
          >
            <RiCloseCircleFill className='fs-2' />
          </a>
        </div>
        <div className='offcanvas-body d-flex align-items-center h-100 justify-content-center'>
          <div className='position-relative w-100 d-flex justify-content-center container-file'>
            {attachment.type.split("/")[0] === "image" ? (
              <img src={`${attachment.url}`} className='img-fluid' />
            ) : attachment.type.split("/")[0] === "video" ? (
              <video className='w-100 h-100 ' controls>
                <source
                  src={`${attachment.url}`}
                  type={`${attachment.type}`}
                ></source>
              </video>
            ) : (
              <h4 className='text-center'>
                {" "}
                <GrDocument /> Anteprima non disponibile
              </h4>
            )}
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default AttachmentsOfMessages;
