import React, { useEffect, useState, useContext, useRef } from "react";
import "../App.css";
import Navbar from "../components/navbar/Navbar";
//Fullcalendar
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import { StateContext } from "../StateContainer";
import useAuth from "../useAuth";
import { useHistory } from "react-router-dom";
import listPlugin from "@fullcalendar/list";

const Calendario = ({}) => {
  const state = useContext(StateContext);
  const {
    setStatus,
    setPriority,
    setProjectName,
    setType,
    setTypeOfTicket,
    setOpenTickets,
    setFilterProjectsTicketsActive,
    filterProjectsTicketsActive,
    width,
    setTitlePage,
    sidebar,
  } = state;

  useEffect(() => {
    setTitlePage("Calendario");
  }, []);

  const history = useHistory();
  useAuth(history);

  const calendarRef = useRef(null);

  const handleEventClick = (info) => {
    // ottieni la data del giorno cliccato
    let date = info.event.start;
    // utilizza la funzione gotoDate per spostare la vista sul giorno cliccato
    calendarRef.current.calendar.gotoDate(date);
    // modifica la visualizzazione per mostrare solo un giorno
    calendarRef.current.calendar.changeView("listDay");
  };

  const [tickets, setTickets] = useState([]);
  const [ticketsMobile, setTicketsMobile] = useState([]);
  const [newTickets, setNewTickets] = useState([]);
  const token = localStorage.getItem("token");

  const url = `${process.env.REACT_APP_BASE_URL}/ticket-list?limit=1000`;
  useEffect(() => {
    fetch(url, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "Authorization": "Bearer " + token,
      },
    }).then((response) => {
      if (response.status === 401) {
        localStorage.removeItem("token");
        localStorage.removeItem("tokenExpiration");
        window.location.replace("/login");
      } else {
        response
          .json()
          .then((data) => {
            setTickets([data.response.tickets]);
          })
          .catch((err) => console.log("errore"));
      }
    });
  }, []);

  useEffect(() => {
    tickets.map((row) => {
      return setNewTickets(
        row
          .filter((validDate) => validDate.dueDate !== null)
          .map((single) => {
            return {
              title: single.title,
              date: single.dueDate.date,
              url: `tickets/${single.id}`,
            };
          })
      );
    });
  }, [tickets]);

  useEffect(() => {
    if (calendarRef.current?.state.viewType === "dayGridDay") {
      setNewTickets(
        tickets.map((row) => {
          return row.map((single) => {
            return {
              title: single.title,
              date: single.dueDate.date,
              url: `tickets/${single.id}`,
            };
          });
        })
      );
    }
  }, [calendarRef, tickets]);

  useEffect(() => {
    let events = [];
    tickets.forEach((row) => {
      row
        .filter((valid) => valid.dueDate !== null)
        .forEach((single) => {
          const date = single.dueDate.date;
          const existingEvent = events.find((event) => event.date === date);
          if (existingEvent) {
            existingEvent.title++;
          } else {
            events.push({
              title: 1,
              date: date,
            });
          }
        });
    });
    setTicketsMobile(events);
  }, [tickets]);

  // Event Render Function To Get Images and Titles

  return (
    <section>
      <Navbar
        setTypeOfTicket={setTypeOfTicket}
        setFilterProjectsTicketsActive={setFilterProjectsTicketsActive}
        filterProjectsTicketsActive={filterProjectsTicketsActive}
        setStatus={setStatus}
        setPriority={setPriority}
        setOpenTickets={setOpenTickets}
        setProjectName={setProjectName}
        setType={setType}
      ></Navbar>
      <div
        className={`container ${
          sidebar && width <= 1780 ? "containerWithSidebarSingle p-0" : ""
        }`}
      >
        <div className='row'>
          <div className='col-12'>
            <div className='text-dark mt-4'>
              <FullCalendar
                editable
                locale={"it"}
                ref={calendarRef}
                selectable
                views={width >= 992 ? "dayGridMonth" : "dayGridWeek"}
                headerToolbar={{
                  start: width < 767 ? "prev,title,next" : "prev,next",
                  center: width >= 767 ? "title" : "",
                  end:
                    width >= 767
                      ? "dayGridMonth dayGridWeek dayGridDay"
                      : "dayGridDay",
                }}
                themeSystem='Simplex'
                plugins={[dayGridPlugin, listPlugin]}
                events={newTickets}
                eventClick={handleEventClick}
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Calendario;
