import React from "react";
import Moment from "react-moment";

const CollapseInformationTickets = ({ ticket }) => {
  return (
    <div
      className='collapse border-0 overflow-auto'
      id={`collapseMobile-${ticket.id}`}
    >
      <div className='card card-body border-0'>
        <p>
          Ticket{" "}
          <b className='text-uppercase '>
            {ticket.type === "task" ? "Richiesta" : "Supporto"}
          </b>
        </p>
        <p>{ticket.title}</p>
        <hr className='color-variant-grey' />
        <p>
          Utente:{" "}
          <b>
            {ticket.issuer.name} {ticket.issuer.lastname}
          </b>
        </p>
        <hr className='color-variant-grey' />
        <p>
          Progetto: <b>{ticket.project.title}</b>
        </p>
        <hr className='color-variant-grey' />
        <p className='d-grid'>
          Data di apertura:{" "}
          <b>
            <Moment format='DD MMMM YYYY'>{ticket.created.date}</Moment>
          </b>
        </p>
        <hr className='color-variant-grey' />
        <p className='d-grid'>
          Data di chiusura:
          {ticket.dueDate === null ? (
            <b>Non definita</b>
          ) : (
            <b>
              <Moment format='DD MMMM YYYY'>{ticket.dueDate.date}</Moment>
            </b>
          )}
        </p>
        <p className={`ms-0 mt-4`} style={{ fontSize: "13px" }}>
          <span
            className={`ms-0 badge-mobile-${ticket.status} py-1 px-2 rounded-pill text-white`}
          >
            {ticket.status === "closed"
              ? "Chiuso"
              : ticket.status === "open"
              ? "Aperto"
              : ticket.status === "review"
              ? "In revisione"
              : "In lavorazione"}
          </span>

          <span
            className={`badge-${ticket.priority} py-1 px-2 rounded-pill text-white `}
          >
            {ticket.priority === "high"
              ? "Alta"
              : ticket.priority === "low"
              ? "Normale"
              : "Urgente"}
          </span>
        </p>
      </div>
    </div>
  );
};

export default CollapseInformationTickets;
