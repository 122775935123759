import React, { useEffect } from "react";
import HomePage from "./pages/HomePage";
import LoginPage from "./pages/Login";
import Progetti from "./pages/Progetti";
import Tickets from "./pages/tickets/Tickets";
import CreateTicket from "./pages/CreateTicket";
import Page404 from "./pages/Page404";
import SinglePageTicket from "./components/tickets/SinglePageTicket";
import Notification from "./pages/Notification";
import Calendario from "./pages/Calendario";

import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
  useLocation,
} from "react-router-dom";

const ProtectedRoute = ({ component: Component, ...rest }) => {
  const currentUrl = useLocation().pathname;
  const isAuthenticated = !!localStorage.getItem("token");

  useEffect(() => {
    if (!isAuthenticated) {
      sessionStorage.setItem("redirectUrl", currentUrl);
    }
  }, [currentUrl, isAuthenticated]);

  return (
    <Route
      {...rest}
      render={(props) =>
        isAuthenticated ? (
          <Component {...props} />
        ) : (
          <Redirect to={{ pathname: "/login" }} />
        )
      }
    />
  );
};

const Routes = () => {
  return (
    <Router>
      <Switch>
        <Route exact path='/login' component={LoginPage} />
        <ProtectedRoute exact path='/dashboard' component={HomePage} />
        <ProtectedRoute path='/' exact>
          <Redirect to='/dashboard' />
        </ProtectedRoute>
        <ProtectedRoute exact path='/tickets' component={Tickets} />
        <ProtectedRoute
          exact
          path='/tickets/create-tickets'
          component={CreateTicket}
        />
        <ProtectedRoute path={`/tickets/:id`} component={SinglePageTicket} />
        <ProtectedRoute path='/progetti' exact component={Progetti} />
        <ProtectedRoute path='/calendario' exact component={Calendario} />
        <ProtectedRoute path='/notifiche' exact component={Notification} />
        <ProtectedRoute path='/:404' component={Page404} />

        <Redirect to='/'></Redirect>
      </Switch>
    </Router>
  );
};

export default Routes;
