export const filterData = [
  {
    id: 1,
    nome: "Titolo",
    select: false,
  },

  {
    id: 4,
    nome: "Stato",
    select: true,
  },

  {
    id: 5,
    nome: "Priorità",
    select: true,
  },

  {
    id: 6,
    nome: "Progetto",
    select: true,
  },

  {
    id: 7,
    nome: "Utente",
    select: true,
  },
];
