import React from "react";
import { Link } from "react-router-dom";
import Moment from "react-moment";

const CollapseNotifications = ({ data, setProjectName }) => {
  return (
    <div
      className={`collapse ${
        data.read ? "bg-light-grey text-black" : "bg-orange text-white"
      }`}
      id={`collapseNotifications-${data.id}`}
    >
      <div className='p-3'>
        <h6>
          Ticket{" "}
          <b>{data.ticket.type === "support" ? "SUPPORTO" : "RICHIESTA"}</b>
        </h6>
        <p>{data.message}</p>
        <hr className='color-variant-grey' />
        <p>
          <Link
            to='/tickets'
            className={`text-decoration-none ${
              data.read ? "text-black" : "text-white"
            }`}
            onClick={() => setProjectName(data.project.id)}
          >
            Progetto:
            <b>{data.project.title}</b>
          </Link>
        </p>
        <hr className='color-variant-grey' />
        <p>
          Data di apertura:
          <b className='ms-1'>
            <Moment format='d MMMM YYYY'>{data.created.date}</Moment>
          </b>
        </p>
        <Link
          to={`/tickets/${data.ticketId}`}
          className={`btn mt-4 w-100 ${
            data.read ? "bg-orange text-white" : "bg-white text-orange"
          } text-decoration-none rounded-pill`}
        >
          Vai al ticket
        </Link>
      </div>
    </div>
  );
};

export default CollapseNotifications;
