import React, { useState, useEffect, useContext } from "react";
import "../App.css";
import { Link } from "react-router-dom";
import Navbar from "../components/navbar/Navbar";
import Loading from "../components/loading/Loading";
import FilterProjects from "../components/projects/FilterProjects";
import SingRowProjects from "../components/projects/SingRowProjects";
import { StateContext } from "../StateContainer";
import ProgettiMobile from "../components/ProgettiMobile";

const Progetti = ({}) => {
  const state = useContext(StateContext);
  const {
    setStatus,
    setProjectName,
    setType,
    setTypeOfTicket,
    setOpenTickets,
    setFilterProjectsTicketsActive,
    filterProjectsTicketsActive,
    projectName,
    setTitlePage,
    width,
    sidebar,
  } = state;

  useEffect(() => {
    setTitlePage("Progetti");
  }, []);

  // Fetchin data
  const [titleProject, setTitleProject] = useState("");
  const [statusProject, setStatusProject] = useState("");
  const [clientProject, setClientProject] = useState("");
  const [loading, setLoading] = useState(false);
  const [projects, setProjects] = useState([]);
  const [dataUser, setDataUser] = useState([]);
  const token = localStorage.getItem("token");
  const url = `${process.env.REACT_APP_BASE_URL}/project-list`;
  useEffect(() => {
    setLoading(true);
    fetch(url, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "Authorization": "Bearer " + token,
      },
    }).then((response) => {
      if (response.status === 401) {
        localStorage.removeItem("token");
        localStorage.removeItem("tokenExpiration");
        window.location.replace("/login");
      } else {
        response
          .json()
          .then((data) => {
            setLoading(false);
            setDataUser([data.user]);
            setProjects([data.response.projects]);
          })
          .catch((err) => console.log("errore"));
      }
    });
  }, [titleProject, statusProject, clientProject]);

  return (
    <section className=' move'>
      <Navbar
        setTypeOfTicket={setTypeOfTicket}
        setStatus={setStatus}
        setOpenTickets={setOpenTickets}
        setProjectName={setProjectName}
        setType={setType}
        setFilterProjectsTicketsActive={setFilterProjectsTicketsActive}
        filterProjectsTicketsActive={filterProjectsTicketsActive}
      />
      <div
        className={`container p-0 mt-3 d-none d-lg-block ${
          sidebar && width <= 1780 ? "containerWithSidebar" : ""
        }`}
      >
        {/* Sezione Titolo */}
        <div className='row text-start'>
          <h1 className='p-0'>Progetti</h1>
          <p className='p-0'>
            Home /{" "}
            <Link
              to='/progetti'
              className='text-decoration-none text-dark pe-auto'
            >
              Progetti
            </Link>
          </p>
          <hr />
        </div>

        {/* Titolo Ticket Aperti */}
        {projects.length === 0 ? (
          ""
        ) : (
          <div>
            <div className='row text-start bg-purple'>
              <div className='col-12'>
                <p className='m-0 py-1 text-white font-13'>
                  <b>Tutti i progetti</b>
                </p>
              </div>
            </div>
            <FilterProjects
              data={projects}
              setTitleProject={setTitleProject}
              setClientProject={setClientProject}
              setStatusProject={setStatusProject}
              projectName={projectName}
              titleProject={titleProject}
              statusProject={statusProject}
              clientProject={clientProject}
            />
          </div>
        )}

        {/* La Row dei titoli*/}

        {loading ? (
          <Loading />
        ) : projects.length === 0 ? (
          <h5 className='text-center mt-5'>Nessuna progetto trovato</h5>
        ) : (
          <div>
            <table className='table mt-4 text-start fs-table position-relative'>
              <thead>
                <tr>
                  <th scope='col'>Titolo Progetto</th>
                  <th scope='col'>Cliente</th>
                  <th scope='col'>Ticket Attivi</th>
                  <th scope='col'>Data inizio progetto</th>
                  <th scope='col'>Deadline</th>
                  <th scope='col'>Stato</th>
                  <th scope='col'></th>
                </tr>
              </thead>

              {projects.map((row, index) => {
                return (
                  <SingRowProjects
                    data={row}
                    dataUser={dataUser}
                    key={index}
                    setProjectName={setProjectName}
                    titleProject={titleProject}
                    clientProject={clientProject}
                    statusProject={statusProject}
                    setFilterProjectsTicketsActive={
                      setFilterProjectsTicketsActive
                    }
                    filterProjectsTicketsActive={setFilterProjectsTicketsActive}
                  />
                );
              })}
            </table>
          </div>
        )}
      </div>
      <div className='d-block d-lg-none'>
        {loading ? (
          <Loading />
        ) : (
          <div className='d-block d-lg-none'>
            {projects.map((row, index) => {
              return (
                <ProgettiMobile
                  key={index}
                  data={row}
                  setProjectName={setProjectName}
                ></ProgettiMobile>
              );
            })}
          </div>
        )}
      </div>
      <div style={{ height: "100px" }} className='d-block d-lg-none'></div>
    </section>
  );
};

export default Progetti;
