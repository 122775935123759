import React from "react";
import Icons from "../header-components/Icons";
import { AiOutlineCalendar } from "react-icons/ai";
import { IoMdNotificationsOutline } from "react-icons/io";
import { FaTicketAlt } from "react-icons/fa";
import { Link } from "react-router-dom";
import AddTicket from "../../img/add-ticket.svg";
const BottomNavbar = ({ setTypeOfTicket }) => {
  const SingleIconBottomNav = [
    {
      id: 1,
      className: " single-icon",
      element: <AiOutlineCalendar />,
      badge: false,
      linkTo: "/calendario",
    },

    {
      id: 2,
      className: " single-icon ",
      element: <IoMdNotificationsOutline />,
      badge: true,
      linkTo: "/notifiche",
    },

    {
      id: 3,
      className: " single-icon",
      element: <FaTicketAlt />,
      badge: false,
      linkTo: "/tickets",
    },

    {
      id: 4,
      className: " single-icon",
      element: <FaTicketAlt />,
      badge: false,
      linkTo: "/tickets/create-tickets",
    },
  ];
  return (
    <React.Fragment>
      <nav className='nav bg-dark text-white fixed-bottom d-lg-none py-2 w-100 justify-content-around'>
        <div className='container p-0 m-0 '>
          <div className='row m-0 p-0'>
            <div className='col-12 p-0 d-flex align-items-center justify-content-around fs-1 py-1'>
              {SingleIconBottomNav.map((icon, index) => {
                return (
                  <Link
                    key={index}
                    to={icon.linkTo}
                    className='text-white'
                    onClick={() => setTypeOfTicket(icon.type)}
                  >
                    <div className='m-0 position-relative p-0'>
                      {icon.element}
                      {icon.id === 4 ? (
                        <div className='badge p-0 m-0 badge-add-ticket bg-white text-dark rounded-circle text-center position-absolute '>
                          <p className=''>+</p>
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                  </Link>
                );
              })}
            </div>
          </div>
        </div>
      </nav>
    </React.Fragment>
  );
};

export default BottomNavbar;
