import React, { useState, useEffect, useContext } from "react";
import "../../App.css";
import { Link } from "react-router-dom";
import Navbar from "../../components/navbar/Navbar";
import { FaBriefcase, FaMedkit } from "react-icons/fa";
import Loading from "../../components/loading/Loading";
import SingRowTicket from "../../components/tickets/singRowTicket";
import Filter from "../../helpers/Filter";
import { StateContext } from "../../StateContainer";
import { RiArrowUpSFill } from "react-icons/ri";
import SingRowMobile from "../../components/tickets/SingRowMobile";
import { MdCancel } from "react-icons/md";

const Tickets = ({}) => {
  const state = useContext(StateContext);
  const {
    search,
    setSearch,
    status,
    setStatus,
    priority,
    setPriority,
    projectName,
    setProjectName,
    id,
    setId,
    typeOfTicket,
    type,
    setType,
    setTypeOfTicket,
    openTickets,
    setOpenTickets,
    filterProjectsTicketsActive,
    setFilterProjectsTicketsActive,
    width,
    setWidth,
    setTitlePage,
    sidebar,
  } = state;

  const [projects, setProjects] = useState([]);
  const [dataUser, setDataUser] = useState([]);
  const urlProject = `${process.env.REACT_APP_BASE_URL}/project-list`;
  useEffect(() => {
    setLoading(true);
    fetch(urlProject, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "Authorization": "Bearer " + token,
      },
    }).then((response) => {
      if (response.status === 401) {
        localStorage.removeItem("token");
        localStorage.removeItem("tokenExpiration");
        window.location.replace("/login");
      } else {
        response
          .json()
          .then((data) => {
            setLoading(false);
            setDataUser([data.user]);
            setProjects([data.response.projects]);
          })
          .catch((err) => console.log("errore"));
      }
    });
  }, []);

  // Fetchin data
  const [loading, setLoading] = useState(false);
  const [userTickets, setUserTickets] = useState([]);
  const [tickets, setTickets] = useState([]);
  const token = localStorage.getItem("token");
  const [updateOrder, setUpdateOrder] = useState("updated");
  const [selectUser, setSelectUser] = useState(0);

  const url = `${process.env.REACT_APP_BASE_URL}/ticket-list?limit=1000&order=${updateOrder}&sort=DESC`;

  useEffect(() => {
    setLoading(true);
    fetch(url, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "Authorization": "Bearer " + token,
      },
    }).then((response) => {
      if (response.status === 401) {
        localStorage.removeItem("token");
        localStorage.removeItem("tokenExpiration");
        window.location.replace("/login");
      } else {
        response
          .json()
          .then((data) => {
            setLoading(false);
            setUserTickets([data.user]);
            setTickets([data.response.tickets]);
          })
          .catch((err) => console.log("errore"));
      }
    });
  }, [
    search,
    priority,
    status,
    projectName,
    typeOfTicket,
    updateOrder,
    selectUser,
  ]);

  useEffect(() => {
    const handleResize = () => {
      setWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <section className=' move'>
      <Navbar
        setOpenTickets={setOpenTickets}
        setTypeOfTicket={setTypeOfTicket}
        setStatus={setStatus}
        setPriority={setPriority}
        setProjectName={setProjectName}
        setType={setType}
        filterProjectsTicketsActive={filterProjectsTicketsActive}
        setFilterProjectsTicketsActive={setFilterProjectsTicketsActive}
      />
      {width >= 992 ? (
        <div
          className={`container mt-3  ${
            sidebar && width <= 1780 ? "containerWithSidebar" : ""
          }`}
        >
          {/* Sezione Titolo */}
          <div className='row text-start'>
            <h1 className='p-0'>Tickets</h1>
            <p className='p-0'>
              Home /{" "}
              <Link
                to='/tickets'
                className='text-decoration-none text-dark pe-auto'
              >
                Tickets
              </Link>
            </p>
            <hr />

            {/* Bottoni */}
            <Link
              to='/tickets/create-tickets'
              className='btn bg-blue w-25 me-2 text-start text-white'
              onClick={() => setTypeOfTicket("task")}
            >
              <FaBriefcase className='me-2' /> Nuovo ticket
            </Link>
            <Link
              to='/tickets/create-tickets'
              className='btn bg-red w-25 me-2 text-start text-white'
              onClick={() => setTypeOfTicket("support")}
            >
              <FaMedkit className='me-2' /> Nuovo Supporto
            </Link>
          </div>

          {tickets.length === 0 ? (
            ""
          ) : (
            <React.Fragment>
              <div className='row mt-3 text-start bg-grey'>
                <div className='col-12'>
                  <p className='m-0 py-1 bg-grey font-13'>
                    <b>Tickets aperti</b>
                  </p>
                </div>
              </div>

              <Filter
                setOpenTickets={setOpenTickets}
                tickets={tickets}
                setSearch={setSearch}
                setStatus={setStatus}
                setPriority={setPriority}
                search={search}
                status={status}
                priority={priority}
                setProjectName={setProjectName}
                setType={setType}
                projectName={projectName}
                filterProjectsTicketsActive={filterProjectsTicketsActive}
                setFilterProjectsTicketsActive={setFilterProjectsTicketsActive}
                setUpdateOrder={setUpdateOrder}
                selectUser={selectUser}
                setSelectUser={setSelectUser}
              />
            </React.Fragment>
          )}
          {/* La Row dei titoli*/}

          {loading ? (
            <Loading />
          ) : tickets.length === 0 ? (
            <h5 className='text-center mt-5'>Nessun ticket trovato</h5>
          ) : (
            <>
              <table className='table mt-4 text-start fs-table position-relative'>
                <thead>
                  <tr>
                    <th
                      scope='col'
                      onClick={() => setUpdateOrder("issuer")}
                      style={{ cursor: "pointer" }}
                    >
                      <span className='m-0 freccia-reorder'>
                        Utente
                        <RiArrowUpSFill className='fs-5' />
                      </span>
                    </th>
                    <th
                      scope='col'
                      onClick={() => setUpdateOrder("title")}
                      style={{ cursor: "pointer" }}
                    >
                      <span className='m-0 freccia-reorder'>
                        Titolo Ticket
                        <RiArrowUpSFill className='fs-5' />
                      </span>
                    </th>
                    <th
                      scope='col'
                      onClick={() => setUpdateOrder("project")}
                      style={{ cursor: "pointer" }}
                    >
                      <span className='m-0 freccia-reorder'>
                        Progetto
                        <RiArrowUpSFill className='fs-5' />
                      </span>
                    </th>
                    <th
                      scope='col'
                      onClick={() => setUpdateOrder("updated")}
                      style={{ cursor: "pointer" }}
                    >
                      <span className='m-0 freccia-reorder'>
                        Data Apertura
                        <RiArrowUpSFill className='fs-5' />
                      </span>
                    </th>
                    <th
                      scope='col'
                      onClick={() => setUpdateOrder("dueDate")}
                      style={{ cursor: "pointer" }}
                    >
                      <span className='m-0 freccia-reorder'>
                        Deadline
                        <RiArrowUpSFill className='fs-5' />
                      </span>
                    </th>
                    <th
                      scope='col'
                      onClick={() => setUpdateOrder("type")}
                      style={{ cursor: "pointer" }}
                    >
                      <span className='m-0 freccia-reorder'>
                        Tipologia
                        <RiArrowUpSFill className='fs-5' />
                      </span>
                    </th>
                    <th
                      scope='col'
                      onClick={() => setUpdateOrder("status")}
                      style={{ cursor: "pointer" }}
                    >
                      <span className='m-0 freccia-reorder'>
                        Stato
                        <RiArrowUpSFill className='fs-5' />
                      </span>
                    </th>
                    <th
                      scope='col'
                      onClick={() => setUpdateOrder("priority")}
                      style={{ cursor: "pointer" }}
                    >
                      <span className='m-0 freccia-reorder'>
                        Priorità
                        <RiArrowUpSFill className='fs-5' />
                      </span>
                    </th>
                    <th scope='col'></th>
                  </tr>
                </thead>

                {tickets.map((row, index) => {
                  return (
                    <SingRowTicket
                      data={row}
                      dataUser={userTickets}
                      search={search}
                      status={status}
                      priority={priority}
                      prevSearch={search}
                      prevStatus={status}
                      prevPriority={priority}
                      width={width}
                      key={index}
                      projectName={projectName}
                      type={type}
                      setType={setType}
                      openTickets={openTickets}
                      setOpenTickets={setOpenTickets}
                      setSelectUser={setSelectUser}
                      selectUser={selectUser}
                    />
                  );
                })}
              </table>
            </>
          )}
        </div>
      ) : loading ? (
        <Loading />
      ) : (
        <>
          <div className='my-4'>
            {projectName === "" ? (
              ""
            ) : (
              <span className='text-capitalize'>
                Tickets del progetto:{" "}
                <b>
                  {projects.map((row) =>
                    row
                      .filter((project) => project.id === projectName)
                      .map((project) => {
                        return project.title;
                      })
                  )}
                </b>
                <MdCancel
                  className='fs-2 ms-1 text-purple'
                  onClick={() => setProjectName("")}
                />
                <br />
              </span>
            )}
            {status !== "closed" ? (
              ""
            ) : (
              <span className='text-capitalize'>
                Filtro Stato: <b>Chiuso</b>
                <MdCancel
                  className='fs-2 ms-1 text-purple'
                  onClick={() => setStatus("")}
                />
                <br />
              </span>
            )}

            {openTickets === "" ? (
              ""
            ) : (
              <span className='text-capitalize'>
                Filtro: <b>Aperti</b>
                <MdCancel
                  className='fs-2 ms-1 text-purple'
                  onClick={() => setOpenTickets("")}
                />
                <br />
              </span>
            )}

            {type === "" ? (
              ""
            ) : (
              <span>
                Filtro: <b>{type === "task" ? "Richiesta" : "Supporto"}</b>
                <MdCancel
                  className='fs-2 ms-1 text-purple'
                  onClick={() => setType("")}
                />
                <br />
              </span>
            )}
          </div>
          {tickets.map((row, index) => {
            return (
              <SingRowMobile
                key={index}
                tickets={row}
                projectName={projectName}
                dataUser={userTickets}
                search={search}
                status={status}
                priority={priority}
                prevSearch={search}
                prevStatus={status}
                prevPriority={priority}
                type={type}
                setType={setType}
                openTickets={openTickets}
                setOpenTickets={setOpenTickets}
                selectUser={selectUser}
                setSelectUser={setSelectUser}
              />
            );
          })}
        </>
      )}
      <div style={{ height: "100px" }} className='d-block d-lg-none'></div>
    </section>
  );
};

export default Tickets;
