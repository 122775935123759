import React from "react";

const UserMobileNotification = ({ data }) => {
  return (
    <div className='row justify-content-end m-0 p-0 '>
      <div className='col-12 d-flex align-items-center p-0'>
        {data !== null ? (
          data.avatar ? (
            <div
              className='profile-single-page border-profile'
              style={{
                background: `url('${data.avatar}') center center / cover`,
              }}
            ></div>
          ) : (
            <div className='bg-variant-blue rounded-circle profile-single-page m-0 text-center text-white d-flex align-items-center text-center justify-content-center border-profile'>
              {data.name.charAt(0)}
              {data.lastname.charAt(0)}
            </div>
          )
        ) : (
          ""
        )}
      </div>
    </div>
  );
};

export default UserMobileNotification;
