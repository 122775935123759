import React, { useState, useEffect, useContext } from "react";
import Navbar from "../components/navbar/Navbar";
import { Link, Redirect } from "react-router-dom";
import { GrDocument } from "react-icons/gr";
import { MdCancel } from "react-icons/md";
import $ from "jquery";
import "../App.css";
import axios from "axios";
import { StateContext } from "../StateContainer";
import ReactQuill from "react-quill";
import DragAndDrop from "../components/bottom-navbar/DragAndDrop";
import Autolinker from "autolinker";
import DateTime from "react-datetime";
import "react-datetime/css/react-datetime.css";
import moment from "moment";

const CreateTicket = ({}) => {
  const state = useContext(StateContext);
  const {
    setStatus,
    projectName,
    setProjectName,
    typeOfTicket,
    setType,
    setTypeOfTicket,
    setOpenTickets,
    filterProjectsTicketsActive,
    setFilterProjectsTicketsActive,
    width,
    setWidth,
    setTitlePage,
    sidebar,
  } = state;

  const [contentData, setContentData] = useState([]);
  const [optionMenu, setOptionMenu] = useState(0);
  const [isDisabled, setIsDisabled] = useState(false);
  const [titleOfTicket, setTitleOfTicket] = useState("");
  const [descriptionOfTicket, setDescriptionOfTicket] = useState("");
  const [dueDateOfTicket, setDueDateOfTicket] = useState("");
  const [creatTicketSuccess, setCreatTicketSuccess] = useState();
  const [errorOfNewTicket, setErrorOfNewTicket] = useState(false);
  const [idOfTheNewTicket, setIdOfTheNewTicket] = useState([]);

  useEffect(() => {
    setTitlePage("Nuovo ticket");
  }, []);

  let dueDate = document.getElementById("dueDate");
  if (dueDate != null) {
    dueDate = dueDate.value.replace("T", " ");
  } else {
    dueDate = null;
  }

  const priority = $("#priority option:selected").val();

  const [loading, setLoading] = useState(false);
  const [currentUser, setCurrentUser] = useState([]);
  const [createTicket, setCreateTicket] = useState([]);
  const [watchers, setWatchers] = useState([]);
  const [success, setSuccess] = useState([]);
  const token = localStorage.getItem("token");

  const projectList = `${process.env.REACT_APP_BASE_URL}/project-list`;
  const projectWatchers =
    `${process.env.REACT_APP_BASE_URL}/project-watchers?projectId=` +
    projectName;
  const createTheTicket = `${process.env.REACT_APP_BASE_URL}/ticket-create`;

  const richiestaPresignedUrl = `${process.env.REACT_APP_BASE_URL}/attachment-presigned-url`;

  const uploadFilesToBackend = `${process.env.REACT_APP_BASE_URL}/upload-attachment`;

  useEffect(() => {
    console.log("content");
  }, [contentData]);

  useEffect(() => {
    console.log("proj", projectName);
  }, [projectName]);

  useEffect(() => {
    setLoading(true);
    fetch(projectList, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "Authorization": "Bearer " + token,
      },
    }).then((response) => {
      if (response.status === 401) {
        localStorage.removeItem("token");
        window.location.replace("/login");
      } else {
        response
          .json()
          .then((data) => {
            setLoading(false);
            setSuccess(data.status);
            setCreateTicket([data.response]);
            setCurrentUser([data.user][0]);
          })
          .catch((error) => {
            if (error.name === "AbortError") return;
            // if the query has been aborted, do nothing
            throw error;
          });
      }
    });
    return () => {};
  }, []);

  const [selectedWatchers, setSelectedWatchers] = useState([]);

  useEffect(() => {
    setLoading(true);
    fetch(projectWatchers, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "Authorization": "Bearer " + token,
      },
    }).then((response) => {
      if (response.status === 401) {
        localStorage.removeItem("token");
        localStorage.removeItem("tokenExpiration");
        window.location.replace("/login");
      } else {
        response
          .json()
          .then((data) => {
            setLoading(false);
            setWatchers([data]);
          })
          .catch((err) => console.log(err));
      }
    });
    return () => {};
  }, [projectName]);

  useEffect(() => {
    setSelectedWatchers(
      watchers
        .filter((w) => w.response)
        .flatMap((w) => w.response.map((watcher) => watcher.id))
        .filter((id) => id !== currentUser.id)
    );
  }, [watchers, currentUser]);

  const handleCheckboxChange = (watcherId) => {
    // check if the watcher is already in the list of selected watchers
    const isWatcherSelected = selectedWatchers.includes(watcherId);
    if (isWatcherSelected) {
      // remove the watcher from the list of selected watchers
      setSelectedWatchers(selectedWatchers.filter((id) => id !== watcherId));
    } else {
      // add the watcher to the list of selected watchers
      setSelectedWatchers([...selectedWatchers, watcherId]);
    }
  };

  const sendDatatoBackend = (event) => {
    setIsDisabled(true);
    event.preventDefault();
    setErrorOfNewTicket(false);

    const RequestedOptions = {
      method: "POST",
      headers: {
        "Accept": "application/json",
        "Content-Type": "application/json",
        "Authorization": "Bearer " + token,
      },
      body: JSON.stringify({
        projectId: projectName,
        title: titleOfTicket,
        dueDate:
          dueDateOfTicket !== ""
            ? moment(dueDateOfTicket).format("YYYY-MM-DD HH:mm")
            : "",
        description: descriptionOfTicket === "" ? "@@@" : descriptionOfTicket,
        priority: priority,
        type: typeOfTicket,
        watchers: `${currentUser.id}, ${selectedWatchers}`,
      }),
    };
    fetch(createTheTicket, RequestedOptions)
      .then((response) => {
        if (response.status === 401) {
          localStorage.removeItem("token");
          localStorage.removeItem("tokenExpiration");
          window.location.replace("/login");
        } else {
          response.json().then((data) => {
            setIdOfTheNewTicket(data.status ? [data.response.id] : "");
            setCreatTicketSuccess(data.status ? true : false);
            setErrorOfNewTicket(data.status ? false : true);
            setIsDisabled(data.status ? true : false);

            console.log("primo step", contentData);

            for (let i = 0; i < contentData.length; i++) {
              fetch(uploadFilesToBackend, {
                method: "POST",
                headers: {
                  Accept: "application/json",
                  "Content-Type": "application/json",
                  "Authorization": "Bearer " + token,
                },
                body: JSON.stringify({
                  messageId: data.response.messages[0].id,
                  keyName: contentData[i].name,
                  type: contentData[i].type,
                  size: contentData[i].size,
                  fileName: contentData[i].name,
                }),
              }).then((response) =>
                response
                  .json()
                  .then((data) => {
                    console.log("secondo step", contentData);

                    window.location.reload();
                  })
                  .catch((err) => console.log(err))
              );
            }
          });
        }
      })

      .catch((err) => console.log(err));
  };

  console.log(contentData);

  const [uploadProgress, setUploadProgress] = useState([]);
  const [currentRequestId, setCurrentRequestId] = useState(null);

  const UploadTheFiles = (files) => {
    // const files = event.target.files;

    for (let i = 0; i < files.length; i++) {
      // Crea una nuova fonte di annullamento per ogni file
      const cancelFile = axios.CancelToken.source();
      setUploadProgress((prevProgress) => [
        ...prevProgress,
        {
          file: files[i],
          progress: 0,
          status: "uploading",
          cancelToken: cancelFile,
        },
      ]);
      setContentData((currentScene) => [...currentScene, files[i]]);

      const requestId = `upload_${files[i].name}`;
      setCurrentRequestId(requestId);

      const config = {
        method: "POST",
        url: richiestaPresignedUrl,
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "Authorization": "Bearer " + token,
        },
        data: {
          keyName: `${files[i].name}`,
        },
      };

      axios(config)
        .then((res) => {
          delete axios.defaults.headers.common["Authorization"];
          const configSecondPart = {
            method: "put",
            url: res.data.response.presignedUrl,
            headers: {
              Accept: `${files[i].type}`,
              "Content-Type": `${files[i].type}`,
            },
            data: files[i],
            cancelToken: cancelFile.token,
            onUploadProgress: (progressEvent) => {
              let percentage = Math.round(
                (progressEvent.loaded * 100) / progressEvent.total
              );
              setUploadProgress((prevProgress) =>
                prevProgress.map((file) => {
                  if (file.file.name === files[i].name) {
                    return { ...file, progress: percentage };
                  }
                  return file;
                })
              );
            },
          };
          axios(configSecondPart)
            .then((res) => {
              setUploadProgress((prevProgress) => [
                ...prevProgress.map((file) => {
                  if (file.file.name === files[i].name) {
                    return { ...file, progress: 100, status: "completed" };
                  }
                  return file;
                }),
              ]);
            })
            .catch((err) => {
              if (axios.isCancel(err)) {
                console.log(`Request ${requestId} canceled`);
              } else {
                console.log("AXIOS ERROR: ", err);
              }
            });
        })
        .catch((err) => {
          if (axios.isCancel(err)) {
            console.log(`Request ${requestId} canceled`);
          } else {
            console.log("AXIOS ERROR: ", err);
          }
        });
    }
  };

  const removeFile = (fileName) => {
    setUploadProgress((prevProgress) =>
      prevProgress.filter((file) => {
        if (file.file.name === fileName) {
          file.cancelToken.cancel(`Cancelled request for ${fileName}`);
          return false;
        }
        return true;
      })
    );
    setContentData((currentScene) =>
      currentScene.filter((file) => file.name !== fileName)
    );
    if (uploadProgress.length === 0) {
      document.getElementById("fileInput").value = "Nessun file selezionato";
      setTimeout(() => {
        document.getElementById("fileInput").value = "";
      }, 500);
    }
  };

  const HandleChangeOfDescription = (value, delta, source, editor) => {
    if (
      source === "user" &&
      (delta.ops[delta.ops.length - 1].insert === "\n" ||
        delta.ops[delta.ops.length - 1].insert === " ")
    ) {
      const linkedText = Autolinker.link(value);
      setDescriptionOfTicket(linkedText);
    } else {
      setDescriptionOfTicket(value);
    }
  };

  const HandleSubmit = (e) => {
    e.preventDefault();
  };

  useEffect(() => {
    const handleResize = () => {
      setWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <section>
      <Navbar
        setTypeOfTicket={setTypeOfTicket}
        setStatus={setStatus}
        setOpenTickets={setOpenTickets}
        setProjectName={setProjectName}
        setType={setType}
        filterProjectsTicketsActive={filterProjectsTicketsActive}
        setFilterProjectsTicketsActive={setFilterProjectsTicketsActive}
      />
      <div
        className={` p-0 mt-3 py-3 ${
          sidebar && width <= 1780 ? "containerWithSidebarSingle" : ""
        } container`}
      >
        {/* TITOLO PAGINA */}
        <div className='row text-start m-0 p-0'>
          <h1 className='mb-0'>Nuova richiesta</h1>
          <p className='my-3 my-xl-0'>
            Home /{" "}
            <Link
              to='/tickets'
              className='text-decoration-none text-dark pe-auto'
            >
              Tickets
            </Link>
          </p>
          <hr className='d-none d-xl-block' />
        </div>

        {errorOfNewTicket === true ? (
          <div
            className='text-red'
            id='errorMessage'
            style={{ scrollBehavior: "smooth" }}
          >
            Attenzione, i seguenti campi non sono stati compilati:
            {titleOfTicket === "" ? (
              <>
                <p>- Titolo</p>
              </>
            ) : (
              ""
            )}
            {projectName === "" ? (
              <>
                <p>- Progetto</p>
              </>
            ) : (
              ""
            )}
            {dueDateOfTicket === "" ? (
              <>
                <p>- Data di chiusura</p>
              </>
            ) : (
              ""
            )}{" "}
            {selectedWatchers.length === 0 ? (
              <>
                <p>- Visibilità del ticket</p>
              </>
            ) : (
              ""
            )}{" "}
            <br />
            <br />
          </div>
        ) : (
          ""
        )}
        {creatTicketSuccess ? <Redirect to={`${idOfTheNewTicket}`} /> : ""}
        <form>
          <div className='row m-0 p-0'>
            <div className='col-12 col-xl-4 text-start'>
              <div className='col-12'>
                <h6 className='mt-2 mt-xl-0'>Titolo richiesta</h6>
                <textarea
                  type='text-area'
                  id='titleTicket'
                  placeholder='Scrivi il titolo'
                  className='w-100 ps-1 pt-1'
                  value={titleOfTicket}
                  onChange={(e) => setTitleOfTicket(e.target.value)}
                  required
                ></textarea>
              </div>
              <div className='col-12 mt-4'>
                <h6>Tipologia</h6>
                <select
                  name=''
                  id='requestOrSupport'
                  className='w-100 py-2'
                  required
                  value={typeOfTicket}
                  onChange={(e) => setTypeOfTicket(e.target.value)}
                >
                  <option value='task'>Richiesta</option>
                  <option value='support'>Supporto</option>
                </select>
              </div>
              <div className='col-12 mt-4'>
                <h6>Progetto</h6>
                <select
                  name=''
                  value={projectName}
                  onChange={(event) => setProjectName(event.target.value)}
                  className='w-100 py-2'
                  required
                >
                  <option value={0}>Seleziona un progetto</option>
                  {createTicket.map((row) => {
                    console.log(createTicket);
                    return row.projects.map((project, index) => {
                      return (
                        <React.Fragment key={index}>
                          <option
                            type='progetto'
                            className='projectsMenu'
                            value={project.id}
                            selected={projectName === project.id}
                          >
                            {project.title}
                          </option>
                        </React.Fragment>
                      );
                    });
                  })}
                </select>
              </div>
              <div className='col-12 mt-4'>
                <h6>Priorità</h6>
                <select
                  name=''
                  id='priority'
                  className='w-100 py-2'
                  required
                  defaultValue={"low"}
                >
                  <option value='urgent'>Urgente</option>
                  <option value='high'>Alta</option>
                  <option value='low'>Normale</option>
                </select>
              </div>
              <div className='col-12 mt-4'>
                <h6>Data di chiusura</h6>

                <DateTime
                  value={dueDateOfTicket}
                  onChange={setDueDateOfTicket}
                  dateFormat={"YYYY-MM-DD"}
                  timeFormat={"HH:00"}
                  onFocus={(e) => {
                    e.currentTarget.type = "datetime-local";
                  }}
                  onBlur={(e) => {
                    e.currentTarget.type = "text";
                  }}
                />
              </div>

              {width >= 992 ? (
                <>
                  <div className='col-12 mt-4'>
                    <h6>Visibilità del Ticket</h6>

                    {watchers.map((row, index) => {
                      return row.response ? (
                        row.response.map((watcher) => {
                          return (
                            <div
                              className='my-4 d-flex align-items-center'
                              key={watcher.id}
                            >
                              <input
                                type='checkbox'
                                className={`me-2 checkedUsers`}
                                disabled={
                                  currentUser.id === watcher.id
                                    ? "disabled"
                                    : ""
                                }
                                value={watcher.id}
                                onChange={() =>
                                  handleCheckboxChange(watcher.id)
                                }
                                checked={selectedWatchers.includes(watcher.id)}
                              />
                              {watcher.avatar ? (
                                <div className='d-flex align-items-center'>
                                  <div
                                    className='profile-single-page shadow-sm position-relative'
                                    style={{
                                      background: `url('${watcher.avatar}') center center / cover`,
                                    }}
                                  ></div>
                                </div>
                              ) : (
                                <div className='d-flex align-items-center justify-content-center'>
                                  <div className='profile-single-page shadow-sm text-center bg-red text-white d-flex justify-content-center'>
                                    <h2>
                                      {watcher.name.charAt(0)}
                                      {watcher.lastname.charAt(0)}
                                    </h2>
                                  </div>
                                </div>
                              )}

                              <span>
                                {watcher.name} {watcher.lastname}{" "}
                                {currentUser.lastname === watcher.lastname ? (
                                  <i className='text-grey'>(Tu)</i>
                                ) : (
                                  ""
                                )}
                              </span>
                            </div>
                          );
                        })
                      ) : (
                        <p className='py-3' key={index}>
                          Nessun progetto selezionato
                        </p>
                      );
                    })}
                  </div>

                  <div className='col-12 mt-4'>
                    <h6 style={{ wordBreak: "break-word" }}>
                      Seleziona o deseleziona gli utenti che hanno visibilità su
                      questo ticket
                    </h6>
                    <a
                      className='btn border-dark bg-white rounded-pill w-100'
                      href='mailto:info@fulgeas.com?subject=Richiesta di Aggiunta Utenti'
                    >
                      Devi aggiungere un utente? clicca qui
                    </a>
                  </div>

                  <div className='mt-5 '>
                    <a
                      className={`btn bg-variant-green text-white ${
                        isDisabled ? "disabled" : ""
                      }`}
                      onClick={sendDatatoBackend}
                      id='salvaButton'
                    >
                      Salva
                    </a>
                    <Link
                      to='/tickets'
                      className='btn bg-variant-red text-white ms-2'
                    >
                      Cancella
                    </Link>
                  </div>
                </>
              ) : (
                ""
              )}
            </div>

            {/* INIZIO SEZIONE: l form della descrizione */}
            <div className='col-12 col-xl-8' style={{ height: "100%" }}>
              <div
                onSubmit={HandleSubmit}
                id='formRispondiCreateTicket'
                className={`mb-5 border-red rounded mt-5 mt-xl-0 ${
                  typeOfTicket === "task" ? "border-blue" : "border-red"
                }`}
              >
                <div className={`col-12 card`}>
                  <div
                    className={`card-header text-white py-3 ${
                      typeOfTicket === "task" ? "bg-blue" : "bg-red"
                    }`}
                  >
                    Scrivi i dettagli della richiesta
                  </div>
                  <div className='col-12 card-body'>
                    <ReactQuill
                      theme='snow'
                      name=''
                      className='border-0 w-100'
                      value={descriptionOfTicket}
                      onChange={HandleChangeOfDescription}
                      required
                    />
                    <div className='col-12'>
                      <div className='card-body allegati rounded'>
                        <div className='row m-0 p-0'>
                          <div className='col-12 position-relative'>
                            {width > 992 ? (
                              <DragAndDrop
                                id='fileInput'
                                onDrop={UploadTheFiles}
                              />
                            ) : (
                              <input
                                type='file'
                                onChange={(ev) =>
                                  UploadTheFiles(ev.target.files)
                                }
                                multiple
                                className='mt-2 w-100'
                              />
                            )}

                            {uploadProgress.map((item, index) => (
                              <div
                                class='progress-bar-container my-3'
                                key={item.file.name}
                              >
                                {item.status !== "completed" && (
                                  <>
                                    <p>{item.file.name}</p>

                                    <div className='row d-flex align-items-center m-0 p-0'>
                                      <div className='col-11'>
                                        <div class='progress'>
                                          <div
                                            class='progress-value text-white text-center'
                                            role='progressbar'
                                            aria-label='Example with label'
                                            style={{
                                              width: `${item.progress}%`,
                                            }}
                                            aria-valuenow={`${item.progress}`}
                                            aria-valuemin='0'
                                            aria-valuemax='100'
                                          >
                                            {item.progress} %
                                          </div>
                                        </div>
                                      </div>
                                      <div className='col-1 p-0'>
                                        <a
                                          className='btn text-orange p-0'
                                          onClick={() =>
                                            removeFile(item.file.name)
                                          }
                                        >
                                          <MdCancel />
                                        </a>
                                      </div>
                                    </div>
                                  </>
                                )}
                              </div>
                            ))}
                          </div>

                          <div className='col-12'>
                            {uploadProgress.map((file, index) => {
                              return (
                                <span
                                  key={index}
                                  className='d-flex align-items-center m-0'
                                >
                                  <GrDocument className='me-1' />{" "}
                                  {file.file.name}
                                  <a
                                    className='btn text-orange p-0 ms-1'
                                    onClick={() => removeFile(file.file.name)}
                                  >
                                    <MdCancel />
                                  </a>
                                </span>
                              );
                            })}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {width < 992 ? (
                <>
                  <div className='col-12 mt-4'>
                    <h6>Visibilità del Ticket</h6>

                    {watchers.map((row, index) => {
                      return row.response ? (
                        row.response.map((watcher) => {
                          return (
                            <div
                              className='my-4 d-flex align-items-center'
                              key={watcher.id}
                            >
                              <input
                                type='checkbox'
                                className={`me-2 checkedUsers`}
                                disabled={
                                  currentUser.id === watcher.id
                                    ? "disabled"
                                    : ""
                                }
                                value={watcher.id}
                                onChange={() =>
                                  handleCheckboxChange(watcher.id)
                                }
                                checked={selectedWatchers.includes(watcher.id)}
                              />
                              {watcher.avatar ? (
                                <div className='d-flex align-items-center'>
                                  <div
                                    className='profile-single-page shadow-sm position-relative'
                                    style={{
                                      background: `url('${watcher.avatar}') center center / cover`,
                                    }}
                                  ></div>
                                </div>
                              ) : (
                                <div className='d-flex align-items-center'>
                                  <div className='profile-single-page-span shadow-sm text-center bg-red text-white d-flex align-items-center justify-content-center'>
                                    <h2 className='m-0'>
                                      {watcher.name.charAt(0)}
                                      {watcher.lastname.charAt(0)}
                                    </h2>
                                  </div>
                                </div>
                              )}

                              <span>
                                {watcher.name} {watcher.lastname}{" "}
                                {currentUser.lastname === watcher.lastname ? (
                                  <i className='text-grey'>(Tu)</i>
                                ) : (
                                  ""
                                )}
                              </span>
                            </div>
                          );
                        })
                      ) : (
                        <p className='py-3' key={index}>
                          Nessun progetto selezionato
                        </p>
                      );
                    })}
                  </div>

                  <div className='col-12 mt-4'>
                    <h6>
                      Seleziona o deseleziona gli utenti che hanno visibilità su
                      questo ticket
                    </h6>
                    <a
                      className='btn border-dark bg-white rounded-pill w-100'
                      href='mailto:info@fulgeas.com?subject=Richiesta di Aggiunta Utenti'
                    >
                      Devi aggiungere un utente? clicca qui
                    </a>
                  </div>

                  <div className='mt-5 row'>
                    <div className='col-6 text-center'>
                      <a
                        className={`btn bg-variant-green text-white w-100 ${
                          isDisabled ? "disabled" : ""
                        }`}
                        onClick={sendDatatoBackend}
                        id='salvaButton'
                      >
                        Salva
                      </a>
                    </div>
                    <div className='col-6 text-center'>
                      <Link
                        to='/tickets'
                        className='btn bg-variant-red text-white w-100'
                      >
                        Cancella
                      </Link>
                    </div>
                  </div>
                </>
              ) : (
                ""
              )}
            </div>
          </div>
        </form>
      </div>
      <div style={{ height: "100px" }} className='d-block d-xl-none'></div>
    </section>
  );
};

export default CreateTicket;
