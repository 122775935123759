import React from 'react';
import '../../App.css'

const Loading = () => {
  return (
    <div className="row justify-content-center text-center my-5 py-5">
        <div className="col-12">
        <div className="spinner-border text-fulgeas" role="status"></div>
        <div className='mt-2'>Sto caricando ...</div>
        </div>
    </div>
  )
}

export default Loading