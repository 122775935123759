import React from "react";
import "../../App.css";

const UserMobileNavbar = ({ data }) => {
  return (
    <>
      {data.avatar ? (
        <div
          className='image-profile-navbar-mobile mx-auto'
          style={{
            background: `url('${data.avatar}') center center / cover`,
          }}
        ></div>
      ) : (
        <div
          className='bg-red mx-auto d-flex align-items-center rounded-circle'
          style={{ width: "85px", height: "85px" }}
        >
          <div className='mx-auto'>
            <h1 className='m-0'>
              {data.name.charAt(0)}
              {data.lastname.charAt(0)}
            </h1>
          </div>
        </div>
      )}

      <div className='text-white m-0 mt-2'>
        {data.name} {data.lastname}
      </div>
    </>
  );
};

export default UserMobileNavbar;
