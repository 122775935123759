import { useState, useEffect, useContext } from "react";
import UserTickets from "./UserTickets";
import { FaArrowCircleRight } from "react-icons/fa";
import ReactPaginate from "react-paginate";
import { FaBriefcase, FaMedkit } from "react-icons/fa";
import { Link, useParams } from "react-router-dom";
import { StateContext } from "../../StateContainer";
import "../../App.css";
import dayjs from "dayjs";
import $ from "jquery";

import Moment from "react-moment";

const SingRowTicket = ({
  data,
  dataUser,
  search,
  status,
  priority,
  projectName,
  type,
  openTickets,
  width,
  selectUser,
}) => {
  const [currentItems, setCurrentItems] = useState([]);
  const [pageCount, setPageCount] = useState(0);
  const [itemOffset, setItemOffset] = useState(0);
  const itemsPerPage = 10;

  useEffect(() => {
    const endOffset = itemOffset + itemsPerPage;
    setCurrentItems(
      data
        .filter((el) =>
          search.toLowerCase() === ""
            ? el
            : el.title.toLowerCase().includes(search.toLowerCase())
        )
        .filter((el) =>
          priority.toLowerCase() === ""
            ? el
            : el.priority.toLowerCase().includes(priority.toLowerCase())
        )

        .filter((el) =>
          selectUser === 0 ? el : el.issuer.id === parseInt(selectUser)
        )

        .filter((el) =>
          status.toLowerCase() === ""
            ? el
            : el.status.toLowerCase().includes(status.toLowerCase())
        )
        .filter((el) =>
          projectName === ""
            ? el
            : el.project.id === parseInt(projectName) && el.status !== "closed"
        )

        .filter((el) => (openTickets === "" ? el : el.status !== "closed"))

        .filter((el) => (type === "" ? el : el.type === type))
        .slice(itemOffset, endOffset)
    );

    setPageCount(
      Math.ceil(
        data
          .filter((el) =>
            search.toLowerCase() === ""
              ? el
              : el.title.toLowerCase().includes(search.toLowerCase())
          )
          .filter((el) =>
            priority.toLowerCase() === ""
              ? el
              : el.priority.toLowerCase().includes(priority.toLowerCase())
          )
          .filter((el) =>
            selectUser === 0 ? el : el.issuer.id === parseInt(selectUser)
          )

          .filter((el) =>
            projectName === ""
              ? el
              : el.project.id === parseInt(projectName) &&
                el.status !== "closed"
          )

          .filter((el) => (type === "" ? el : el.type === type))
          .filter((el) => (openTickets === "" ? el : el.status !== "closed"))

          .filter((el) =>
            status.toLowerCase() === ""
              ? el
              : el.status.toLowerCase().includes(status.toLowerCase())
          ).length / itemsPerPage
      )
    );
  }, [
    itemOffset,
    itemsPerPage,
    data,
    search,
    priority,
    status,
    openTickets,
    selectUser,
  ]);

  const handlePageClick = (event) => {
    const newOffset =
      (event.selected * itemsPerPage) %
      data
        .filter((el) =>
          priority.toLowerCase() === ""
            ? el
            : el.priority.toLowerCase().includes(priority.toLowerCase())
        )

        .filter((el) =>
          selectUser === 0 ? el : el.issuer.id === parseInt(selectUser)
        )

        .filter((el) =>
          search.toLowerCase() === ""
            ? el
            : el.title.toLowerCase().includes(search.toLowerCase())
        )
        .filter((el) => (type === "" ? el : el.type === type))

        .filter((el) =>
          projectName === ""
            ? el
            : el.project.id === parseInt(projectName) && el.status !== "closed"
        )

        .filter((el) => (openTickets === "" ? el : el.status !== "closed"))
        .filter((el) =>
          status.toLowerCase() === ""
            ? el
            : el.status.toLowerCase().includes(status.toLowerCase())
        ).length;
    setItemOffset(newOffset);
  };

  let { idPage } = useParams();

  return (
    <>
      {currentItems.length === 0 ? (
        <tbody className='text-center m-0 col-12 border-0'>
          <tr>
            <td colSpan={12} className='border-0'>
              <h4 className='my-4'>
                Nessun ticket corrisponde alla tua ricerca
              </h4>
            </td>
          </tr>
        </tbody>
      ) : (
        currentItems

          // Filtra il titolo
          .filter((item) => {
            return search.toLowerCase() === ""
              ? item
              : item.title.toLowerCase().includes(search);
          })

          //Tutti i tickets tranne quelli chiusi

          .filter((el) => (openTickets === "" ? el : el.status !== "closed"))

          // Filtra lo stato (aperto, chiuso, ...)
          .filter((stato) => {
            return status.toLowerCase() === ""
              ? stato
              : stato.status.toLowerCase().includes(status);
          })

          .filter((el) => {
            return selectUser === 0
              ? el
              : el.issuer.id === parseInt(selectUser);
          })

          // Filtra la priorità (alta, media, bassa)
          .filter((prior) => {
            return priority.toLowerCase() === ""
              ? prior
              : prior.priority.toLowerCase().includes(priority.toLowerCase());
          })

          // Filtra il tipo (supporto o richiesta)
          .filter((item) => {
            return type === "" ? item : item.type === type;
          })

          // Filtra in base al dettaglio del progetto che passo dentro alla pagina "Progetti"
          .filter((item) => {
            return projectName === ""
              ? item
              : item.project.id === parseInt(projectName);
          })

          .filter((el) =>
            projectName === ""
              ? el
              : el.project.id === parseInt(projectName) &&
                el.status !== "closed"
          )
          //Mappa il contenuto
          .map((ticket, index) => {
            return (
              <tbody
                className='justify-self-center '
                id='tbody'
                key={ticket.id}
                value={ticket.id}
              >
                <tr
                  className='align-items-center'
                  style={{ verticalAlign: "middle" }}
                  key={ticket.id}
                  id='tbody-ticket'
                  value={ticket.id}
                >
                  <UserTickets data={ticket} key={ticket.id} />
                  <td
                    className=''
                    style={{
                      width: "auto",
                      maxWidth: "300px",
                      wordBreak: "break-word",
                    }}
                  >
                    {ticket.title.length > 20 && width <= 1780
                      ? ticket.title.substr(0, 20) + " ..."
                      : ticket.title}
                  </td>
                  <td className=''>{ticket.project.title}</td>
                  <td className=''>
                    <Moment format='DD MMMM YYYY'>{ticket.created.date}</Moment>
                  </td>

                  <td className=''>
                    {ticket.dueDate === null ? (
                      "Non definita"
                    ) : (
                      <Moment format='DD MMMM YYYY '>
                        {ticket.dueDate.date}
                      </Moment>
                    )}

                    {ticket.dueDate === null ? (
                      ""
                    ) : Date.now() > Date.parse(ticket.dueDate.date) ? (
                      <>
                        <br />
                        <span className='ms-1 badge bg-warning text-dark rounded-pill my-2'>
                          Tempo scaduto
                        </span>
                      </>
                    ) : (
                      <>
                        <br />
                        <span className='mt-2 ms-0'>
                          {dayjs(ticket.dueDate.date).diff(dayjs(), "day")}{" "}
                          giorni
                        </span>
                        <span className='ms-1'>
                          {dayjs(ticket.dueDate.date).diff(dayjs(), "hour") %
                            24}{" "}
                          ore
                        </span>
                      </>
                    )}
                  </td>

                  <td className=''>
                    {ticket.type === "task" ? (
                      <>
                        <span className='m-0'>
                          <FaBriefcase className='me-2' />
                          Richiesta
                        </span>
                      </>
                    ) : (
                      <>
                        <span className='m-0'>
                          <FaMedkit className='me-2' />
                          Supporto
                        </span>
                      </>
                    )}
                  </td>
                  <td className='text-start '>
                    <p
                      className={` badge badge-${ticket.status} py-1 px-2 rounded-pill`}
                    >
                      {ticket.status === "review"
                        ? "In revisione"
                        : ticket.status === "working"
                        ? "In lavorazione"
                        : ticket.status === "open"
                        ? "Aperto"
                        : "Chiuso"}
                    </p>
                  </td>
                  <td className=''>
                    <p
                      className={`badge px-2 badge-priority rounded-pill ${
                        ticket.priority === "high"
                          ? "bg-orange"
                          : ticket.priority === "urgent"
                          ? "bg-red"
                          : "bg-green"
                      } m-0`}
                    >
                      {ticket.priority === "high"
                        ? "Alta"
                        : ticket.priority === "urgent"
                        ? "Urgente"
                        : "Normale"}
                    </p>
                  </td>

                  <td className='fs-3 text-grey'>
                    <Link
                      to={`tickets/${ticket.id}`}
                      className='text-decoration-none'
                    >
                      <FaArrowCircleRight className='text-decoration-none text-grey' />
                    </Link>
                  </td>
                </tr>
              </tbody>
            );
          })
      )}

      <tfoot
        className='row position-absolute p-0 mt-4 border-0'
        style={{ right: "0" }}
      >
        <tr className='col-12 p-0'>
          <td className='border-0' style={{ border: 0 }}>
            <ReactPaginate
              breakLabel='...'
              nextLabel='>>'
              onPageChange={handlePageClick}
              pageRangeDisplayed={1}
              pageCount={pageCount}
              previousLabel='<<'
              renderOnZeroPageCount={""}
              containerClassName={"pagination text-end"}
              pageClassName={"page-item"}
              pageLinkClassName={"page-link"}
              previousClassName={"page-item"}
              previousLinkClassName={"page-link"}
              nextClassName={"page-item"}
              nextLinkClassName={"page-link"}
              breakClassName={"page-item"}
              breakLinkClassName={"page-link"}
              activeClassName={"active activeLink"}
              marginPagesDisplayed={1}
            />
          </td>
        </tr>
      </tfoot>
    </>
  );
};

export default SingRowTicket;
