import axios from "axios";
import React, { useEffect, useState, useContext } from "react";
import Loading from "../components/loading/Loading";
import SingleNotification from "./SingleNotification";
import Navbar from "../components/navbar/Navbar";
import { Link } from "react-router-dom";
import FilterNotifications from "../components/notifiche/FilterNotifications";
import { StateContext } from "../StateContainer";
import SingleNotificationMobile from "../components/SingleNotificationMobile";

const Notification = ({}) => {
  const state = useContext(StateContext);
  const {
    showMoreNotifications,
    setShowMoreNotifications,
    setStatus,
    setProjectName,
    setType,
    setTypeOfTicket,
    setOpenTickets,
    setFilterProjectsTicketsActive,
    filterProjectsTicketsActive,
    setTitlePage,
    result,
    setResult,
    sidebar,
    width,
    setWidth,
  } = state;

  useEffect(() => {
    setTitlePage("Notifiche");
  }, []);

  useEffect(() => {
    const handleResize = () => {
      setWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const [readNotifications, setReadNotifications] = useState("");
  const ListOfNotifications = `${
    process.env.REACT_APP_BASE_URL
  }/user-notifications?limit=${width >= 992 ? "1000" : showMoreNotifications}`;
  const token = localStorage.getItem("token");
  const [loading, setLoading] = useState(false);
  const [singleNotificationData, setSingleNotificationData] = useState([]);
  const [progettoNotifications, setProgettoNotifications] = useState("");

  useEffect(() => {
    setLoading(true);
    const config = {
      method: "post",
      url: ListOfNotifications,
      headers: {
        Accept: "application/json",
        "Content-type": "application/json",
        "Authorization": "Bearer " + token,
      },
    };
    axios(config)
      .then((res) => {
        setLoading(false);
        setSingleNotificationData(res.data.response.notifications);
      })
      .catch((error) => {
        if (error.response.status === 401) {
          localStorage.removeItem("token");
          localStorage.removeItem("tokenExpiration");
          window.location.replace("/login");
        } else {
          console.log(error);
        }
      });
  }, [result, progettoNotifications, showMoreNotifications, readNotifications]);
  return (
    <>
      <section className='notifiche move text-start'>
        <Navbar
          setTypeOfTicket={setTypeOfTicket}
          setStatus={setStatus}
          setOpenTickets={setOpenTickets}
          setProjectName={setProjectName}
          setType={setType}
          setFilterProjectsTicketsActive={setFilterProjectsTicketsActive}
          filterProjectsTicketsActive={filterProjectsTicketsActive}
        />
        <div
          className={`container p-0 my-3 d-none d-lg-block ${
            sidebar && width <= 1780 ? "containerWithSidebar" : ""
          }`}
        >
          {/* Sezione Titolo */}
          <div className='row'>
            <h1 className='p-0'>Notifiche</h1>
            <p className='p-0'>
              Home /{" "}
              <Link
                to='/notifiche'
                className='text-decoration-none text-dark pe-auto'
              >
                Notifiche
              </Link>
            </p>
            <hr />
          </div>

          {singleNotificationData.length === 0 ? (
            ""
          ) : (
            <React.Fragment>
              <div className='row text-start bg-orange'>
                <div className='col-12'>
                  <p className='m-0 py-1 text-white font-13'>
                    <b>Tutte le notifiche</b>
                  </p>
                </div>
              </div>
              <FilterNotifications
                setProgettoNotifications={setProgettoNotifications}
                setProjectName={setProgettoNotifications}
                projectName={progettoNotifications}
                setReadNotifications={setReadNotifications}
                setResult={setResult}
              />
            </React.Fragment>
          )}
          <div>
            {loading ? (
              <Loading />
            ) : (
              <div className=''>
                <table className='table fs-table mt-3'>
                  <thead className='mb-2'>
                    <tr className=' my-5'>
                      <th role={"col"} style={{ cursor: "pointer" }}>
                        <span className='m-0'>Contenuto notifica</span>
                      </th>
                      <th role={"col"} style={{ cursor: "pointer" }}>
                        <span className='m-0'>Ticket</span>
                      </th>
                      <th role={"col"} style={{ cursor: "pointer" }}>
                        <span className='m-0'>Progetto</span>
                      </th>
                      <th role={"col"} style={{ cursor: "pointer" }}>
                        <span className='m-0'>Data</span>
                      </th>
                      <th role={"col"} style={{ cursor: "pointer" }}></th>
                    </tr>
                  </thead>

                  <SingleNotification
                    data={singleNotificationData}
                    setNotification={setSingleNotificationData}
                    setResult={setResult}
                    progettoNotifications={progettoNotifications}
                    setProjectName={setProjectName}
                    setFilterProjectsTicketsActive={
                      setFilterProjectsTicketsActive
                    }
                    setWidth={setWidth}
                  />
                </table>
              </div>
            )}
          </div>
        </div>
        <div className='d-block d-lg-none'>
          {loading ? (
            <Loading />
          ) : (
            <SingleNotificationMobile
              notification={singleNotificationData}
              setResult={setResult}
              setProjectName={setProjectName}
              showMoreNotifications={showMoreNotifications}
              setShowMoreNotifications={setShowMoreNotifications}
              setWidth={setWidth}
            ></SingleNotificationMobile>
          )}
        </div>
        <div style={{ height: "100px" }} className='d-block d-lg-none'></div>
      </section>
    </>
  );
};

export default Notification;
