import React, { useEffect, useState, useContext } from "react";
import useAuth from "../useAuth";
import { useHistory } from "react-router-dom";
import "../App.css";
import { Link } from "react-router-dom";
import NumeroRichieste from "../components/homepage/NumeroRichieste";
import SecRowCards from "../components/homepage/SecRowCards";
import Widgets from "../components/homepage/Widgets";
import Navbar from "../components/navbar/Navbar";
import Loading from "../components/loading/Loading";
import { StateContext } from "../StateContainer";

function HomePage({}) {
  const history = useHistory();
  useAuth(history);
  const state = useContext(StateContext);
  const {
    showMoreNotifications,
    setShowMoreNotifications,
    search,
    setSearch,
    status,
    setStatus,
    priority,
    setPriority,
    setProjectName,
    type,
    setType,
    projectName,
    setTypeOfTicket,
    setOpenTickets,
    filterProjectsTicketsActive,
    setFilterProjectsTicketsActive,
    setTitlePage,
    width,
    sidebar,
  } = state;
  const [tickets, setTickets] = useState([]);

  useEffect(() => {
    setTitlePage("Dashboard");
  }, []);

  const urlTickets = `${process.env.REACT_APP_BASE_URL}/ticket-list?limit=1000`;
  useEffect(() => {
    setLoading(true);
    fetch(urlTickets, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "Authorization": "Bearer " + token,
      },
    }).then((response) => {
      if (response.status === 401) {
        localStorage.removeItem("token");
        localStorage.removeItem("tokenExpiration");
        window.location.replace("/login");
      } else {
        response
          .json()
          .then((data) => {
            setFilterProjectsTicketsActive(false);
            setLoading(false);
            setTickets([data.response.tickets]);
          })
          .catch((err) => console.log("errore"));
      }
    });
  }, []);

  const [projects, setProjects] = useState([]);
  const urlProject = `${process.env.REACT_APP_BASE_URL}/project-list`;
  useEffect(() => {
    setLoading(true);
    fetch(urlProject, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "Authorization": "Bearer " + token,
      },
    }).then((response) => {
      if (response.status === 401) {
        localStorage.removeItem("token");
        localStorage.removeItem("tokenExpiration");
        window.location.replace("/login");
      } else {
        response
          .json()
          .then((data) => {
            setLoading(false);
            setProjects([data.response.projects]);
          })
          .catch((err) => console.log("errore"));
      }
    });
  }, []);

  const [loading, setLoading] = useState(false);
  const [dataCounters, setDataCounters] = useState([]);
  const [numberOfTickets, setNumberOfTickets] = useState([]);
  const [numberOfProjects, setNumberOfProjects] = useState([]);
  const [numberOfNotifications, setNumberOfNotifications] = useState([]);
  const token = localStorage.getItem("token");
  const url = `${process.env.REACT_APP_BASE_URL}/dashboard`;

  useEffect(() => {
    setLoading(true);
    fetch(url, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "Authorization": "Bearer " + token,
      },
    }).then((response) => {
      if (response.status === 401) {
        localStorage.removeItem("token");
        localStorage.removeItem("tokenExpiration");
        window.location.replace("/login");
      } else {
        response
          .json()
          .then((data) => {
            setLoading(false);

            setDataCounters([data.response.counters]);
            setNumberOfTickets([data.response.latestTickets]);
            setNumberOfProjects([data.response.latestProjects]);
            setNumberOfNotifications([data.response.latestNotifications]);
          })
          .catch((err) => console.log(err));
      }
    });
  }, []);

  return (
    <section className='home move text-start w-100'>
      <Navbar
        setTypeOfTicket={setTypeOfTicket}
        setOpenTickets={setOpenTickets}
        setStatus={setStatus}
        setPriority={setPriority}
        setProjectName={setProjectName}
        setType={setType}
        filterProjectsTicketsActive={filterProjectsTicketsActive}
        setFilterProjectsTicketsActive={setFilterProjectsTicketsActive}
      />
      <div
        className={` p-0 mt-3 ${
          sidebar && width <= 1780 ? "containerWithSidebarSingle" : ""
        } container`}
      >
        <div className='row p-0 m-0 d-none d-xl-block'>
          <h1>Dashboard</h1>
          <p>
            Home /{" "}
            <Link to='/' className='text-decoration-none text-dark pe-auto'>
              Dashboard
            </Link>
          </p>
          <hr />
        </div>

        {loading ? (
          <Loading />
        ) : (
          <>
            <div className='row m-0 p-0 justify-content-around justify-content-xl-between'>
              <NumeroRichieste
                data={dataCounters}
                search={search}
                status={status}
                priority={priority}
                setSearch={setSearch}
                setStatus={setStatus}
                setPriority={setPriority}
                setType={setType}
                type={type}
                totalProjects={projects}
                totalTickets={tickets}
                setOpenTickets={setOpenTickets}
                filterProjectsTicketsActive={filterProjectsTicketsActive}
                setFilterProjectsTicketsActive={setFilterProjectsTicketsActive}
              />
            </div>

            <div className='row m-0 p-0 justify-content-xl-between justify-content-center mt-3'>
              <SecRowCards
                dataTickets={numberOfTickets}
                setShowMoreNotifications={setShowMoreNotifications}
                dataProjects={numberOfProjects}
                dataNotifications={numberOfNotifications}
                setType={setType}
                filterProjectsTicketsActive={filterProjectsTicketsActive}
                setFilterProjectsTicketsActive={setFilterProjectsTicketsActive}
                setProjectName={setProjectName}
                projectName={projectName}
              />
            </div>

            <div className='row m-0 p-0 justify-content-xl-between justify-content-center mt-3'>
              <Widgets />
            </div>
            <div
              style={{ height: "100px" }}
              className='d-block d-xl-none'
            ></div>
          </>
        )}
      </div>
    </section>
  );
}

export default HomePage;
