import { useEffect, useState, useContext } from "react";
import axios from "axios";
import "../../App.css";
import { Link } from "react-router-dom";
import Moment from "react-moment";
import { StateContext } from "../../StateContainer";

const ListNavbarNotifications = ({
  setNotificationsNumber,
  notificationsNumber,
  openNotificationsForm,
}) => {
  const state = useContext(StateContext);
  const { result } = state;

  const ListOfNotifications = `${process.env.REACT_APP_BASE_URL}/user-notifications`;
  const token = localStorage.getItem("token");
  const [singleNotificationData, setSingleNotificationData] = useState([]);

  useEffect(() => {
    const config = {
      method: "post",
      url: ListOfNotifications,
      headers: {
        Accept: "application/json",
        "Content-type": "application/json",
        "Authorization": "Bearer " + token,
      },
    };
    axios(config).then((res) => {
      setSingleNotificationData(res.data.response.notifications);
    });
  }, [notificationsNumber, result]);

  useEffect(() => {
    setNotificationsNumber(
      singleNotificationData.filter((el) => el.read === false).length
    );
  }, [result, singleNotificationData]);

  return (
    <div
      className={`font-13 position-relative text-center ${
        openNotificationsForm ? "" : "d-none"
      }`}
    >
      {notificationsNumber === 0 ? (
        <p className='m-0 py-2'>Nessuna notifica</p>
      ) : (
        singleNotificationData
          .filter((notification) => notification.read === false)
          .slice(0, 4)
          .map((notification, index) => {
            return (
              <div
                className='row d-flex align-items-center w-100 px-2 py-3 m-0 position-relative text-black rowNotification position-relative justify-content-around'
                key={index}
              >
                <div className='h4 text-start m-0 col-2 p-0'>
                  {notification.author.avatar ? (
                    <div>
                      <div
                        className='notification-profile rounded-circle'
                        style={{
                          background: `url('${notification.author.avatar}') center center / cover`,
                        }}
                      ></div>
                    </div>
                  ) : (
                    <div className='bg-red rounded-circle text-white notification-profile text-center'>
                      {notification.author.name.charAt(0)}
                      {notification.author.lastname.charAt(0)}
                    </div>
                  )}
                </div>
                <div className='col-10 align-baseline text-start font-10'>
                  Ticket Numero: #{notification.ticket.id}
                  <p className='font-12 my-1'>
                    <b>
                      {notification.ticket.title.length > 30
                        ? notification.ticket.title.substr(0, 30) + "..."
                        : notification.ticket.title}
                    </b>
                  </p>
                  <p className='font-10'>
                    <Moment format='DD MMMM YYYY, HH:mm'>
                      {notification.ticket.created.date}
                    </Moment>
                  </p>
                </div>
                <a
                  className='stretched-link'
                  href={`/tickets/${notification.ticketId}`}
                ></a>
              </div>
            );
          })
      )}
      <hr className='m-1' />
      <Link to='/notifiche' className='m-0 text-decoration-none'>
        <p className='py-2 m-0'>Vedi tutte le notifiche</p>
      </Link>
    </div>
  );
};

export default ListNavbarNotifications;
