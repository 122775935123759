import React, { useState, useContext } from "react";
import { Link } from "react-router-dom";
import LogoSidebarMobile from "../../img/logo-sidebar-menu-mobile.svg";
import { RiArrowDownSFill } from "react-icons/ri";
import { TfiClose } from "react-icons/tfi";
import UserMobileNavbar from "../navbar/UserMobile";
import SidebarData from "./data/SidebarData";
import useAuth from "../../useAuth";
import { StateContext } from "../../StateContainer";

const SidebarMobile = ({ user, setTypeOfTicket }) => {
  const state = useContext(StateContext);
  const { setStatus, setProjectName, setType, setOpenTickets } = state;
  const [rotateArrow, setRotateArrow] = useState(false);
  const { logout } = useAuth();

  const OpenTickets = () => {
    setOpenTickets("notclosed");
    setStatus("");
    setProjectName("");
    setType("");
  };
  const CloseTickets = () => {
    setOpenTickets("");
    setStatus("closed");
    setProjectName("");
  };
  const AllTickets = () => {
    setType("");
    setStatus("");
    setOpenTickets("");
    setProjectName("");
  };

  return (
    <div
      className='offcanvas offcanvas-start w-100'
      key={1}
      tabIndex='-1'
      id='offcanvasMobile'
      aria-labelledby='offcanvasMobileLabel'
    >
      <div className='offcanvas-header '>
        <h5 className='offcanvas-title' id='offcanvasMobileLabel'>
          <img
            src={`${LogoSidebarMobile}`}
            alt=''
            style={{ width: "140px", height: "auto" }}
          />
        </h5>
        <button
          type='button'
          className='text-reset border-0 bg-transparent'
          data-bs-dismiss='offcanvas'
          aria-label='Close'
        >
          <TfiClose className='fs-2 text-white' />
        </button>
      </div>
      <div className='offcanvas-body'>
        <div className='row'>
          <div className='col-12 text-white d-grid justify-content-center'>
            {user.map((singleUser, index) => {
              return <UserMobileNavbar data={singleUser} key={index} />;
            })}
          </div>
          <div className='col-12 text-center'>
            <div className='mt-5'>
              {SidebarData.map((page, index) => {
                return (
                  <React.Fragment key={index}>
                    <div className='display-6 text-uppercase' key={index}>
                      {page.subnav ? (
                        <React.Fragment key={index}>
                          <a
                            key={index}
                            className='text-decoration-none text-white'
                            data-bs-toggle='collapse'
                            href='#collapseExample'
                            role='button'
                            aria-expanded='false'
                            aria-controls='collapseExample'
                            onClick={() => setRotateArrow(!rotateArrow)}
                          >
                            <span className='ms-0 me-2'>{page.title}</span>
                            <RiArrowDownSFill
                              className={`fs-5 arrow ${
                                rotateArrow ? "arrow-up" : ""
                              }`}
                            />
                          </a>

                          <div className='collapse' id='collapseExample'>
                            <div className='card card-body bg-transparent'>
                              {page.subnav.map((subItem, index) => {
                                return (
                                  <div className='my-3 fs-4' key={index}>
                                    <Link
                                      to='/tickets'
                                      data-bs-dismiss='offcanvas'
                                      className='text-decoration-none text-white'
                                      key={index}
                                      onClick={
                                        subItem.id === 1
                                          ? AllTickets
                                          : subItem.id === 2
                                          ? OpenTickets
                                          : CloseTickets
                                      }
                                    >
                                      {subItem.title}
                                    </Link>
                                  </div>
                                );
                              })}
                            </div>
                          </div>
                        </React.Fragment>
                      ) : (
                        <Link
                          to={page.path}
                          key={index}
                          className='text-decoration-none text-white'
                          data-bs-dismiss='offcanvas'
                        >
                          {page.title}
                        </Link>
                      )}
                    </div>
                    <div className='d-flex justify-content-center w-100 color-yellow'>
                      <hr style={{ width: "30px", height: "1px" }} />
                    </div>
                  </React.Fragment>
                );
              })}
              <div className='display-6'>
                <Link
                  className='text-decoration-none text-white text-uppercase'
                  to={"/tickets/create-tickets"}
                  data-bs-dismiss='offcanvas'
                  onClick={() => setTypeOfTicket("task")}
                >
                  Nuova Richiesta
                </Link>
              </div>
              <div className='d-flex justify-content-center w-100 color-yellow'>
                <hr style={{ width: "30px", height: "1px" }} />
              </div>
              <div className='display-6'>
                <Link
                  className='text-decoration-none text-uppercase text-white'
                  data-bs-dismiss='offcanvas'
                  to={"/tickets/create-tickets"}
                  onClick={() => setTypeOfTicket("support")}
                >
                  Nuovo Supporto
                </Link>
              </div>

              <div className='mt-5 '>
                <a
                  data-bs-dismiss='offcanvas'
                  className='color-yellow text-decoration-none'
                  onClick={logout}
                >
                  Logout
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SidebarMobile;
