import React, { useState } from "react";
import { createContext } from "react";

export const StateContext = createContext();

const StateContainer = ({ children }) => {
  // useState

  const [showMoreNotifications, setShowMoreNotifications] = useState(20);

  const [search, setSearch] = useState("");
  const [status, setStatus] = useState("");
  const [priority, setPriority] = useState("");
  const [projectName, setProjectName] = useState("");
  const [type, setType] = useState("");
  const [openTickets, setOpenTickets] = useState([]);
  const [support, setSupport] = useState("");
  const [typeOfTicket, setTypeOfTicket] = useState("task");
  const [id, setId] = useState("");
  const [result, setResult] = useState("");
  const [width, setWidth] = useState(window.innerWidth);
  const [sidebar, setSidebar] = useState(true);
  const [filterProjectsTicketsActive, setFilterProjectsTicketsActive] =
    useState(false);
  const [titlePage, setTitlePage] = useState("Dashboard");
  //rest of the state

  return (
    <StateContext.Provider
      value={{
        showMoreNotifications,
        setShowMoreNotifications,
        result,
        setResult,
        setTitlePage,
        titlePage,
        filterProjectsTicketsActive,
        setFilterProjectsTicketsActive,
        search,
        width,
        setWidth,
        setSearch,
        status,
        setStatus,
        priority,
        setPriority,
        projectName,
        setProjectName,
        type,
        setType,
        openTickets,
        setOpenTickets,
        support,
        setSupport,
        typeOfTicket,
        setTypeOfTicket,
        id,
        setId,
        sidebar,
        setSidebar,
        //rest of the state
      }}
    >
      {children}
    </StateContext.Provider>
  );
};

export default StateContainer;
