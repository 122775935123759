import React from "react";
import { FaFolderOpen } from "react-icons/fa";
import { FaArrowCircleRight } from "react-icons/fa";
import { Link } from "react-router-dom";
import CollapseProjects from "./CollapseProjects";

const ProgettiMobile = ({ data, setProjectName }) => {
  return (
    <React.Fragment>
      {data.map((project, index) => {
        return (
          <div className={`col-12 `} key={index}>
            <div className={`my-2 mx-2 rounded-class bg-purple `}>
              <div
                className={`row d-flex rounded-3 m-0 p-0 align-items-center justify-content-between position-relative `}
              >
                <div className='col-auto p-0 text-white position-relative d-flex align-items-center justify-content-between '>
                  <FaFolderOpen className='mx-3 fs-2' />
                  {project.title.length > 30
                    ? project.title.substr(0, 30) + " ..."
                    : project.title}
                </div>
                <div
                  className='col-auto p-0 fs-3 float-end p-3 text-white '
                  style={{ zIndex: 2 }}
                >
                  <Link
                    to='/tickets'
                    className='text-white text-decoration-none fs-2'
                    onClick={() => setProjectName(project.id)}
                  >
                    <FaArrowCircleRight />
                  </Link>
                </div>
                <a
                  className='stretched-link'
                  data-bs-toggle='collapse'
                  href={`#collapseProjects-${project.id}`}
                  role='button'
                  aria-expanded='true'
                  aria-controls={`collapseProjects-${project.id}`}
                ></a>
              </div>
              <div>
                <CollapseProjects
                  data={project}
                  setProjectName={setProjectName}
                ></CollapseProjects>
              </div>
            </div>
          </div>
        );
      })}
    </React.Fragment>
  );
};

export default ProgettiMobile;
