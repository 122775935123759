import React, { useEffect, useState } from "react";
import "../../App.css";
import * as FaIcons from "react-icons/fa";
import { AiFillCloseCircle } from "react-icons/ai";
import { MdArrowDropDownCircle } from "react-icons/md";
import { Link } from "react-router-dom";
import Calendario from "./CalendarDashboard";
import { AiOutlineCalendar } from "react-icons/ai";
import { IoMdAnalytics } from "react-icons/io";
import { Calendar } from "@fullcalendar/core";

const statisticsForms = [
  {
    id: 1,
    titolo: "Calendario",
    href: "calendario",
    icon: <AiOutlineCalendar />,
  },

  // {
  //   id: 2,
  //   titolo: "Statistica Generale",
  //   href: "",
  //   icon: <IoMdAnalytics />,
  // },
];

const Widgets = () => {
  return (
    <div className='d-none d-xl-block'>
      {statisticsForms.map((singleCard) => {
        const { id, titolo, href, icon } = singleCard;
        return (
          <div
            className={`card col-12 p-0 mt-3 text-white rounded statistica `}
            key={id}
          >
            <div
              className={`bg-dark-grey card-header p-0 text-start py-1 widget-text d-flex align-items-center`}
            >
              <Link to={href} className='text-white ms-2'>
                {icon}
              </Link>
              <span>{titolo}</span>
            </div>

            <div className='card-body bg-muted overflow-hidden overflow-scroll text-black'>
              <Calendario />
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default Widgets;
