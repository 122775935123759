import React from "react";
import * as MdIcons from "react-icons/md";
import * as AiIcons from "react-icons/ai";
import * as RiIcons from "react-icons/ri";
import * as IoIcons5 from "react-icons/io5";
import * as FaIcons from "react-icons/fa";

const SidebarData = [
  {
    title: "Dashboard",
    path: "/dashboard",
    icon: <MdIcons.MdDashboard />,
    cPath: "bg-dashboard",
  },

  {
    title: "Progetti",
    path: "/progetti",
    icon: <FaIcons.FaFolderOpen />,
    cPath: "bg-progetti",
  },

  {
    title: "Tickets",
    path: "/tickets",
    icon: <FaIcons.FaTicketAlt />,
    iconClosed: <RiIcons.RiArrowDownSFill />,
    iconOpen: <RiIcons.RiArrowUpSFill />,
    cPath: "bg-tickets",
    subnav: [
      {
        title: "Tutti",
        id: 1,
        path: "/tickets",
        icon: <AiIcons.AiFillBackward />,
        cPath: "bg-ticket",
      },

      {
        title: "Aperti",
        id: 2,
        path: "/tickets",
        icon: <AiIcons.AiFillBackward />,
        cPath: "bg-ticket",
      },

      {
        title: "Chiusi",
        id: 3,
        path: "/tickets",
        icon: <AiIcons.AiFillBackward />,
        cPath: "bg-ticket",
      },
    ],
  },

  {
    title: "Notifiche",
    path: "/notifiche",
    icon: <IoIcons5.IoNotifications />,
    cPath: "bg-notifiche",
  },
];

export default SidebarData;
