import React, { useEffect, useState } from "react";

//Fullcalendar
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin from "@fullcalendar/interaction";

const Calendario = () => {
  const [loading, setLoading] = useState(false);
  const [events, setEvents] = useState([]);
  const [data, setData] = useState([]);
  const [success, setSuccess] = useState("");
  const [userTickets, setUserTickets] = useState("");
  const [tickets, setTickets] = useState([]);
  const [newTickets, setNewTickets] = useState([]);
  const token = localStorage.getItem("token");
  const [renderEventContent, setRenderEventContent] = useState("");

  const url = `${process.env.REACT_APP_BASE_URL}/ticket-list?limit=1000`;
  useEffect(() => {
    setLoading(true);
    fetch(url, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "Authorization": "Bearer " + token,
      },
    }).then((response) =>
      response
        .json()
        .then((data) => {
          setData(data);
          setLoading(false);
          setSuccess(data.status);
          setUserTickets([data.user]);
          setTickets([data.response.tickets]);
        })
        .catch((err) => console.log("errore"))
    );
  }, []);

  useEffect(() => {
    tickets.map((row) => {
      return setNewTickets(
        row
          .filter((single) => single.dueDate !== null)
          .map((single) => {
            return {
              title: single.title,
              date: single.dueDate.date || null,
              url: `tickets/${single.id}`,
            };
          })
      );
    });
  }, [tickets]);

  // Event Render Function To Get Images and Titles

  return (
    <section>
      <div className='container'>
        <div className='row'>
          <div className='col-12'>
            <div className='text-dark'>
              <FullCalendar
                editable
                locale={"it"}
                selectable
                initialView='dayGridWeek'
                headerToolbar={{
                  start: "prev,next",
                  end: "dayGridWeek dayGridDay",
                  center: "title",
                }}
                themeSystem='Simplex'
                plugins={[dayGridPlugin]}
                events={newTickets}
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Calendario;
