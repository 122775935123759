import React, { useEffect, useReducer, useState, useContext } from "react";
import { FaBriefcase, FaMedkit } from "react-icons/fa";
import { FiMail } from "react-icons/fi";
import { ImAttachment } from "react-icons/im";
import { GrDocument } from "react-icons/gr";
import { IoIosArrowDown, IoMdArrowDroprightCircle } from "react-icons/io";
import { IoArrowUndoSharp } from "react-icons/io5";
import { Link, useHistory, useParams } from "react-router-dom";
import "../../App.css";
import Logo from "../../img/logo_fulgeas.svg";
import Loading from "../loading/Loading";
import Navbar from "../navbar/Navbar";
import Moment from "react-moment";
import "moment/locale/it";
import $ from "jquery";
import { Parser } from "html-to-react";
import axios from "axios";
import AttachmentsOfMessages from "./AttachmentsOfMessages";
import { StateContext } from "../../StateContainer";
import DragAndDrop from "../bottom-navbar/DragAndDrop";
import { MdCancel } from "react-icons/md";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import Autolinker from "autolinker";

const SinglePageTicket = ({}) => {
  const respondButton = document.getElementById("addNewMessageButton");

  const state = useContext(StateContext);
  const {
    setStatus,
    setProjectName,
    width,
    setWidth,
    setType,
    setTypeOfTicket,
    sidebar,
    setOpenTickets,
    setFilterProjectsTicketsActive,
    filterProjectsTicketsActive,
    setTitlePage,
  } = state;
  // Servono per modificare gli stati del ticket
  const [impostedNewValue, setImpostedNewValue] = useState([]);
  const [updateStatusOfTicket, setUpdateStatusOfTicket] = useState("");
  const [updatePriorityOfTicket, setUpdatePriorityOfTicket] = useState("");
  const [displayFormResponse, setDisplayFormResponse] = useState(false);
  const [textInTheTextarea, setTextInTheTextarea] = useState("");
  const [getNotifyValue, setGetNotifyValue] = useState("");
  // Prova per aggiornare useEffect
  const [reducerValue, forceUpdate] = useReducer((x) => x + 1, 0);
  const [loading, setLoading] = useState(false);
  const [singleTicket, setSingleTicket] = useState([]);
  const [contentData, setContentData] = useState([]);
  const token = localStorage.getItem("token");
  const ticketDetail = `${process.env.REACT_APP_BASE_URL}/ticket-detail`;
  const ticketNewMessage = `${process.env.REACT_APP_BASE_URL}/ticket-add-message`;
  const switchNotify = `${process.env.REACT_APP_BASE_URL}/ticket-switch-notify`;
  const updateTicket = `${process.env.REACT_APP_BASE_URL}/ticket-update`;
  const richiestaPresignedUrl = `${process.env.REACT_APP_BASE_URL}/attachment-presigned-url`;
  const uploadFilesToBackend = `${process.env.REACT_APP_BASE_URL}/upload-attachment`;

  const history = useHistory();
  const { id } = useParams();

  useEffect(() => {
    setTitlePage("Singolo ticket");
  }, []);

  // Riporta tutti i messaggi
  useEffect(() => {
    setLoading(true);
    fetch(ticketDetail, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "Authorization": "Bearer " + token,
      },
      body: JSON.stringify({
        ticketId: id,
      }),
    })
      .then((response) => {
        if (response.status === 401) {
          localStorage.removeItem("token");
          localStorage.removeItem("tokenExpiration");
          window.location.replace("/login");
        } else if (!response.ok) {
          throw Error(response.statusText);
        }
        return response.json();
      })
      .then((data) => {
        console.log(data);
        setLoading(false);
        setUpdateStatusOfTicket(data.response.tickets.status);
        setUpdatePriorityOfTicket(data.response.tickets.priority);
        setSingleTicket([data.response.tickets]);
        setGetNotifyValue(
          data.response.tickets.watchers
            .filter((el) => data.user.id === el.user_id)
            .map((el) => el.notify)
            .pop()
        );
      })
      .catch((err) => {
        console.log(err);
        if (!token) {
          history.push("/login");
        } else {
          history.push("/404");
        }
      });
    return () => {};
  }, [reducerValue]);

  // Aggiunge il messaggio

  console.log("watcher", singleTicket);

  const HandleSubmit = (e) => {
    e.preventDefault();
  };

  const createANewComment = (event) => {
    event.preventDefault();
    setLoading(true);

    const config = {
      method: "post",
      url: ticketNewMessage,
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "Authorization": "Bearer " + token,
      },
      data: {
        ticketId: id,
        message: textInTheTextarea,
      },
    };

    axios(config)
      .then((res) => {
        setLoading(false);
        setDisplayFormResponse(false);
        setTextInTheTextarea("");
        forceUpdate();
        respondButton.scrollIntoView({ behavior: "smooth" });

        for (let item of uploadProgress) {
          if (item.status === "completed") {
            const configOfTheAttachments = {
              method: "post",
              url: uploadFilesToBackend,
              headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                "Authorization": "Bearer " + token,
              },
              data: {
                messageId: res.data.response.id,
                keyName: item.file.name,
                type: item.file.type,
                size: item.file.size,
                fileName: item.file.name,
              },
            };

            axios(configOfTheAttachments).then((resSecond) => {
              setUploadProgress(
                uploadProgress.map((p) =>
                  p.file.name === item.file.name
                    ? { ...p, status: "uploaded" }
                    : p
                )
              );
              forceUpdate();
              respondButton.scrollIntoView({ behavior: "smooth" });
              setContentData([]);
              setUploadProgress([]);
            });
          }
        }
      })
      .catch((err) => console.log(err));
  };

  // Fa un update della priority e dello status del ticket

  $("#updateStatusOfTicket").on("change", function () {
    setUpdateStatusOfTicket($("#updateStatusOfTicket option:selected").val());
  });

  $("#updatePriorityOfTicket").on("change", function () {
    setUpdatePriorityOfTicket(
      $("#updatePriorityOfTicket option:selected").val()
    );
  });

  const UpdateTypeOfTicket = () => {
    fetch(updateTicket, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "Authorization": "Bearer " + token,
      },
      body: JSON.stringify({
        ticketId: id,
        priority: updatePriorityOfTicket,
        status: updateStatusOfTicket,
      }),
    }).then((response) =>
      response
        .json()
        .then((data) => {
          setImpostedNewValue(data.response);
          forceUpdate();
        })
        .catch((err) => console.log("errore"))
    );
  };

  // Switcha le notifiche

  const SwitchNotification = () => {
    fetch(switchNotify, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "Authorization": "Bearer " + token,
      },
      body: JSON.stringify({
        ticketId: id,
      }),
    })
      .then((response) =>
        response.json().then((data) => {
          setGetNotifyValue(data.response.notify);
        })
      )
      .catch((err) => console.log("errore"));
  };

  // Carica gli allegati su S3

  const [uploadProgress, setUploadProgress] = useState([]);
  const [currentRequestId, setCurrentRequestId] = useState(null);

  const [cancelUpload, setCancelUpload] = useState(null);

  const UploadTheFiles = (files) => {
    // const files = event.target.files;

    for (let i = 0; i < files.length; i++) {
      // Crea una nuova fonte di annullamento per ogni file
      const cancelFile = axios.CancelToken.source();
      setUploadProgress((prevProgress) => [
        ...prevProgress,
        {
          file: files[i],
          progress: 0,
          status: "uploading",
          cancelToken: cancelFile,
        },
      ]);
      setContentData((currentScene) => [...currentScene, files[i]]);

      const requestId = `upload_${files[i].name}`;
      setCurrentRequestId(requestId);

      const config = {
        method: "POST",
        url: richiestaPresignedUrl,
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "Authorization": "Bearer " + token,
        },
        data: {
          keyName: `${files[i].name}`,
        },
      };

      axios(config)
        .then((res) => {
          delete axios.defaults.headers.common["Authorization"];
          const configSecondPart = {
            method: "put",
            url: res.data.response.presignedUrl,
            headers: {
              Accept: `${files[i].type}`,
              "Content-Type": `${files[i].type}`,
            },
            data: files[i],
            cancelToken: cancelFile.token,
            onUploadProgress: (progressEvent) => {
              let percentage = Math.round(
                (progressEvent.loaded * 100) / progressEvent.total
              );
              setUploadProgress((prevProgress) =>
                prevProgress.map((file) => {
                  if (file.file.name === files[i].name) {
                    return { ...file, progress: percentage };
                  }
                  return file;
                })
              );
            },
          };
          axios(configSecondPart)
            .then((res) => {
              setUploadProgress((prevProgress) => [
                ...prevProgress.map((file) => {
                  if (file.file.name === files[i].name) {
                    return { ...file, progress: 100, status: "completed" };
                  }
                  return file;
                }),
              ]);
            })
            .catch((err) => {
              if (axios.isCancel(err)) {
                console.log(`Request ${requestId} canceled`);
              } else {
                console.log("AXIOS ERROR: ", err);
              }
            });
        })
        .catch((err) => {
          if (axios.isCancel(err)) {
            console.log(`Request ${requestId} canceled`);
          } else {
            console.log("AXIOS ERROR: ", err);
          }
        });
    }
  };

  const CancellaButton = () => {
    setDisplayFormResponse(!displayFormResponse);
    setTextInTheTextarea("");
    // Interrompi tutte le richieste in corso
    uploadProgress.forEach((file) => {
      file.cancelToken.cancel(`Cancelled request for ${file.file.name}`);
    });
    //rimuovi i file dalla lista di uploadProgress
    setUploadProgress([]);
    setContentData([]);

    setCancelUpload((cancel) => {
      setCurrentRequestId("current_request");
      return cancel;
    });
  };

  const removeFile = (fileName) => {
    setUploadProgress((prevProgress) =>
      prevProgress.filter((file) => {
        if (file.file.name === fileName) {
          file.cancelToken.cancel(`Cancelled request for ${fileName}`);
          return false;
        }
        return true;
      })
    );
    setContentData((currentScene) =>
      currentScene.filter((file) => file.name !== fileName)
    );
    if (uploadProgress.length === 0) {
      document.getElementById("fileInput").value = "Nessun file selezionato";
      setTimeout(() => {
        document.getElementById("fileInput").value = "";
      }, 500);
    }
  };

  const changeTextResponse = (value) => {
    const linkedText = Autolinker.link(value);
    setTextInTheTextarea(linkedText);
  };
  const uploading = uploadProgress.some((file) => file.status === "uploading");
  const emptyTextArea = !textInTheTextarea;

  useEffect(() => {
    const handleResize = () => {
      setWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <section className=''>
      <Navbar
        setTypeOfTicket={setTypeOfTicket}
        setStatus={setStatus}
        setProjectName={setProjectName}
        setType={setType}
        setOpenTickets={setOpenTickets}
        setFilterProjectsTicketsActive={setFilterProjectsTicketsActive}
        filterProjectsTicketsActive={filterProjectsTicketsActive}
      />

      <div
        className={` mt-3 ${
          sidebar && width <= 1780 ? "containerWithSidebarSingle" : ""
        } container`}
      >
        <div className={`row m-0 p-0 `}>
          {/* TITOLO PAGINA */}
          {singleTicket.map((single, index, array) => {
            return (
              <React.Fragment key={index}>
                <div className='col-12'>
                  <div className='row'>
                    <div className='col-12'>
                      <h1 className='text-break'>{single.title}</h1>
                      <span className='m-0 text-break'>
                        Home /
                        <Link
                          to='/tickets'
                          className='text-decoration-none text-dark pe-auto'
                        >
                          Tickets
                        </Link>{" "}
                        /{single.title}
                      </span>
                    </div>

                    <div className='col-12 my-4 wrapper vcenter-item'>
                      <div
                        className={`badge d-flex align-items-center py-0 ${
                          single.type === "task" ? "bg-blue" : "bg-red"
                        } ms-0 font-14 align-baseline rounded-pill float-start`}
                      >
                        {single.type === "task" ? (
                          <>
                            <FaBriefcase />
                            <span className='m-2'> Richiesta </span>
                          </>
                        ) : (
                          <>
                            <FaMedkit />
                            <span className='m-2'> Supporto </span>
                          </>
                        )}
                      </div>

                      <div className='selectSinglePageTicket'>
                        <select
                          className={`form-select float-start py-0 rounded-pill font-14 ${
                            updateStatusOfTicket === "open"
                              ? "border-open"
                              : updateStatusOfTicket === "working"
                              ? "border-working"
                              : updateStatusOfTicket === "review"
                              ? "border-review"
                              : "border-closed"
                          }`}
                          aria-label='status del ticket'
                          id={"updateStatusOfTicket"}
                          value={updateStatusOfTicket}
                          onChange={UpdateTypeOfTicket}
                        >
                          <option value={"open"}>Aperto</option>
                          <option value={"working"}>In lavorazione</option>
                          <option value={"review"}>Review</option>
                          <option value={"closed"}>Chiuso</option>
                        </select>
                      </div>

                      <div
                        className={`selectSinglePageTicket ${
                          updateStatusOfTicket === "closed" ? "d-none" : ""
                        }`}
                      >
                        <select
                          className={`form-select ps-2 py-0 rounded-pill font-14 ${
                            updatePriorityOfTicket === "low"
                              ? "border-priority-low"
                              : updatePriorityOfTicket === "high"
                              ? "border-priority-high"
                              : "border-priority-urgent"
                          }`}
                          aria-label='status del ticket'
                          id={"updatePriorityOfTicket"}
                          onChange={UpdateTypeOfTicket}
                          value={updatePriorityOfTicket}
                        >
                          <option value={"low"}>Priorità Normale</option>
                          <option value={"high"}>Priorità Alta</option>
                          <option value={"urgent"}>Priorità Urgente</option>
                        </select>
                      </div>
                    </div>
                  </div>
                </div>

                {width < 992 ? (
                  <>
                    <div>
                      <span
                        className='ms-0 d-flex align-items-center mb-1'
                        style={{ fontSize: "11pt" }}
                      >
                        <b className='me-2'>Data di crezione:</b>
                        <Moment format='DD MMMM YYYY HH:mm' key={index}>
                          {single.created.date}
                        </Moment>
                      </span>
                    </div>

                    {array.length - 1 === index ? (
                      <div>
                        <span
                          className='ms-0 d-flex align-items-center'
                          style={{ fontSize: "11pt" }}
                        >
                          <b className='me-2'>Ultimo aggiornamento:</b>
                          <Moment format='DD MMMM YYYY HH:mm' key={index}>
                            {single.created.date}
                          </Moment>
                        </span>
                      </div>
                    ) : (
                      ""
                    )}
                  </>
                ) : (
                  ""
                )}
              </React.Fragment>
            );
          })}
          <div className={`col-12 col-lg-9 position-relative `}>
            {loading ? (
              <Loading />
            ) : (
              singleTicket.map((row) =>
                row.messages.map((singleMessage, index) => {
                  return (
                    <React.Fragment key={index}>
                      {/* Utente Primario */}

                      <div
                        className={`row m-0 p-0 py-2 py-lg-0 position-relative my-2 my-lg-0 $`}
                      >
                        <div
                          className={`col-2 text-center position-relative ${
                            row.issuer.id !== singleMessage.author.id &&
                            width < 992
                              ? "order-last float-end"
                              : "p-0 m-0 order-first"
                          }`}
                        >
                          {singleMessage.author.avatar ? (
                            singleMessage.author.fulgeas ? (
                              <div className=''>
                                <div
                                  className='profile-single-page shadow-sm position-relative'
                                  style={{
                                    background: `url('${singleMessage.author.avatar}') center center / cover`,
                                  }}
                                >
                                  <div
                                    className='position-absolute notify-badge'
                                    style={{ left: 0, bottom: 0 }}
                                  >
                                    <img src={Logo} />
                                  </div>
                                </div>
                              </div>
                            ) : (
                              <img
                                src={singleMessage.author.avatar}
                                className='profile-chat-image'
                              />
                            )
                          ) : singleMessage.author.fulgeas ? (
                            <>
                              <div
                                className={`profile-single-page bg-red text-white shadow-sm position-relative d-flex align-items-center justify-content-center ${
                                  row.issuer.id !== singleMessage.author.id &&
                                  width < 992
                                    ? "float-end m-0 text-end"
                                    : ""
                                }`}
                              >
                                <h4 className='order-last text-center m-0'>
                                  {singleMessage.author.name.charAt(0)}
                                  {singleMessage.author.lastname.charAt(0)}
                                </h4>
                                <div
                                  className='position-absolute notify-badge'
                                  style={{ left: 0, bottom: 0 }}
                                >
                                  <img src={Logo} />
                                </div>
                              </div>
                            </>
                          ) : (
                            <div className='d-flex align-items-center order'>
                              <div className='profile-single-page bg-red text-white shadow-sm position-relative d-flex align-items-center justify-content-center  '>
                                <h4 className='order-last text-center m-0'>
                                  {singleMessage.author.name.charAt(0)}
                                  {singleMessage.author.lastname.charAt(0)}
                                </h4>
                              </div>
                            </div>
                          )}
                        </div>

                        <div
                          className={`col col-lg-10 text-start p-3 ${
                            width >= 992
                              ? "bg-white"
                              : "bg-light-grey rounded-mobile"
                          } ${
                            row.issuer.id === singleMessage.author.id
                              ? `text-start ${
                                  width < 992
                                    ? `order-last ${
                                        row.type === "task"
                                          ? "border-blue border-2 bg-light-grey"
                                          : "border-red  border-1"
                                      }`
                                    : "order-first"
                                }`
                              : ""
                          }`}
                        >
                          <div className='col-12'>
                            <span className='ms-0'>
                              <b>
                                {singleMessage.author.name}{" "}
                                {singleMessage.author.lastname}
                              </b>
                            </span>
                            <span className='d-none d-lg-inline-block'>|</span>
                            <span className='d-none  d-lg-inline-block'>
                              {row.project.title}
                            </span>
                            <span className='d-none d-lg-inline-block'>|</span>
                            <span className='d-none d-lg-inline-block'>
                              <Moment format='DD MMMM YYYY, HH:mm'>
                                {singleMessage.created.date}
                              </Moment>
                            </span>
                          </div>

                          <div className='col-12 m-0 p-0 position-relative'>
                            <p
                              className='p-0 m-0 messaggi'
                              style={{
                                hyphens: "auto",
                              }}
                            >
                              {" "}
                              {Parser().parse(singleMessage.text)}
                            </p>
                            <div className='m-0 py-2'>
                              {width >= 992 ? (
                                <div className='row align-items-center position-relative justify-content-start'>
                                  {singleMessage.attachments.map(
                                    (attachment, index) => {
                                      return (
                                        <div className='col-6 col-xl-4'>
                                          <AttachmentsOfMessages
                                            attachment={attachment}
                                            key={attachment.id}
                                          />
                                        </div>
                                      );
                                    }
                                  )}
                                </div>
                              ) : (
                                <>
                                  {singleMessage.attachments.length === 0 ? (
                                    ""
                                  ) : (
                                    <div id='totalMobile'>
                                      <button
                                        type='button'
                                        class='btn btn-primary'
                                        data-bs-toggle='modal'
                                        data-bs-target={`#staticBackdropLabel-${singleMessage.id}`}
                                      >
                                        <ImAttachment className='me-1 text-white' />{" "}
                                        Allegati{" "}
                                      </button>

                                      <div
                                        class='modal fade'
                                        id={`staticBackdropLabel-${singleMessage.id}`}
                                        data-bs-backdrop='static'
                                        data-bs-keyboard='false'
                                        tabindex='-1'
                                        aria-labelledby={`staticBackdropLabel-${singleMessage.id}`}
                                        aria-hidden='true'
                                      >
                                        <div class='modal-dialog modal-dialog-centered'>
                                          <div class='modal-content mx-auto'>
                                            <div class='modal-header bg-grey'>
                                              <h5
                                                class='modal-title'
                                                id={`staticBackdropLabel-${singleMessage.id}`}
                                              >
                                                <ImAttachment className='me-1 text-black' />{" "}
                                                <b>Allegati</b>
                                              </h5>
                                              <button
                                                type='button'
                                                class='btn-close'
                                                data-bs-dismiss='modal'
                                                aria-label='Close'
                                              ></button>
                                            </div>
                                            <div class='modal-body'>
                                              {singleMessage.attachments.map(
                                                (attachment) => {
                                                  return (
                                                    <div
                                                      className=''
                                                      id={`attatchmentMobile-${singleMessage.id}`}
                                                    >
                                                      <a
                                                        href={`${attachment.url}`}
                                                        key={attachment.id}
                                                      >
                                                        <GrDocument className='me-2' />
                                                        {attachment.title}
                                                      </a>
                                                      <hr />
                                                    </div>
                                                  );
                                                }
                                              )}
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  )}
                                </>
                              )}
                            </div>
                          </div>
                        </div>
                        <hr className='d-none d-lg-inline-block mt-3' />
                      </div>

                      {/* Rispondi button */}
                    </React.Fragment>
                  );
                })
              )
            )}
            <a
              className={`btn bg-variant-grey text-white text-decoration-none my-4 float-lg-start ${
                width >= 992 ? "w-auto" : "w-100"
              }`}
              onClick={() => setDisplayFormResponse(!displayFormResponse)}
              id='addNewMessageButton'
              href='#formRispondi'
            >
              <IoArrowUndoSharp /> Rispondi
            </a>

            {/* Row di risposta */}
            {displayFormResponse === true ? (
              <form onSubmit={HandleSubmit} id='formRispondi' className='mb-5'>
                <div className={`col-12 card mt-2`}>
                  <div className='card-header bg-grey py-4'></div>
                  <div className='col-12 card-body'>
                    {width >= 992 ? (
                      <ReactQuill
                        theme='snow'
                        name=''
                        className='border-0 w-100'
                        value={textInTheTextarea}
                        onChange={changeTextResponse}
                        required
                      />
                    ) : (
                      <ReactQuill
                        theme='snow'
                        name=''
                        className='border-0 w-100'
                        value={textInTheTextarea}
                        onChange={changeTextResponse}
                        required
                        style={{
                          height: window.innerHeight > 700 ? "400px" : "200px",
                          width: window.innerWidth < 769 ? "90%" : "100%",
                        }}
                      />
                    )}
                    <div className='col-12'>
                      <div className='card-body allegati rounded'>
                        <div className='row'>
                          <div className='col-12'>
                            {width >= 992 ? (
                              <DragAndDrop
                                id='fileInput'
                                onDrop={UploadTheFiles}
                              />
                            ) : (
                              <input
                                type='file'
                                onChange={(ev) =>
                                  UploadTheFiles(ev.target.files)
                                }
                                multiple
                                className='mt-2'
                              />
                            )}

                            {uploadProgress.map((item, index) => (
                              <div
                                class='progress-bar-container my-3'
                                key={item.file.name}
                              >
                                {item.status !== "completed" && (
                                  <>
                                    <p>{item.file.name}</p>

                                    <div className='row d-flex align-items-center'>
                                      <div className='col-11'>
                                        <div class='progress'>
                                          <div
                                            class='progress-value text-white text-center'
                                            role='progressbar'
                                            aria-label='Example with label'
                                            style={{
                                              width: `${item.progress}%`,
                                            }}
                                            aria-valuenow={`${item.progress}`}
                                            aria-valuemin='0'
                                            aria-valuemax='100'
                                          >
                                            {item.progress} %
                                          </div>
                                        </div>
                                      </div>
                                      <div className='col-1 p-0'>
                                        <a
                                          className='btn text-orange p-0'
                                          onClick={() =>
                                            removeFile(item.file.name)
                                          }
                                        >
                                          <MdCancel />
                                        </a>
                                      </div>
                                    </div>
                                  </>
                                )}
                              </div>
                            ))}
                          </div>

                          <div className='col-12'>
                            {uploadProgress.map((file, index) => {
                              return (
                                <span
                                  key={index}
                                  className='d-flex align-items-center m-0'
                                >
                                  <GrDocument className='me-1' />{" "}
                                  {file.file.name}
                                  <a
                                    className='btn text-orange p-0 ms-1'
                                    onClick={() => removeFile(file.file.name)}
                                  >
                                    <MdCancel />
                                  </a>
                                </span>
                              );
                            })}
                          </div>
                        </div>
                      </div>
                    </div>
                    <a
                      className={`btn bg-green text-decoration-none text-white mt-2 ${
                        uploading || emptyTextArea ? "disabled" : ""
                      }`}
                      onClick={createANewComment}
                      disabled={uploading || emptyTextArea}
                    >
                      Invia
                    </a>
                    <a
                      className='btn bg-red text-decoration-none text-white mt-2 ms-3'
                      onClick={CancellaButton}
                    >
                      Cancella
                    </a>
                  </div>
                </div>
              </form>
            ) : (
              ""
            )}
          </div>

          <div className='col-3 d-none d-lg-block position-static '>
            <div className='col-12 bg-blue rounded p-2 text-white'>
              <p>Data apertura del ticket</p>
              <p className='my-2'>
                <b>
                  {singleTicket.map((single, index) => {
                    return (
                      <Moment format='DD MMMM YYYY' key={index}>
                        {single.created.date}
                      </Moment>
                    );
                  })}
                </b>
              </p>
              <p>Ultimo aggiornamento</p>
              <p className='my-2'>
                <b>
                  {singleTicket.map((row) =>
                    row.messages.map((single, index, array) => {
                      return array.length - 1 === index ? (
                        <Moment format='DD MMMM YYYY' key={index}>
                          {single.created.date}
                        </Moment>
                      ) : (
                        ""
                      );
                    })
                  )}
                </b>
              </p>
            </div>
            <div className='row mt-3'>
              <div className='col-12'>
                <a
                  className={`btn bg-red py-3 text-white w-100 d-flex align-items-center justify-content-center gap-1 ${
                    getNotifyValue === true ? "bg-green" : "bg-red"
                  }`}
                  onClick={SwitchNotification}
                  key={1}
                >
                  <FiMail /> Mail di notifica:
                  {getNotifyValue === true ? " Attivate" : " Disattivate"}
                </a>
              </div>
            </div>

            {/* Elenco dei watcher */}
            <div className='card mt-3 d-none d-lg-block'>
              <div className='card-body'>
                <h5>
                  <b>Watchers</b>
                </h5>
                {singleTicket.map((row) =>
                  row.watchers.map((watcher, index) => {
                    return (
                      <React.Fragment key={index}>
                        <div className='col-12 d-flex align-items-center mt-3 position-relative'>
                          {watcher.avatar ? (
                            <span className='m-0'>
                              <div
                                className='image-profile-watcher-ticket d-none d-lg-block'
                                style={{
                                  background: `url('${watcher.avatar}') center center / cover`,
                                }}
                              ></div>
                            </span>
                          ) : (
                            <div className='rounded-circle bg-danger text-white image-profile-watcher-ticket d-flex align-items-center justify-content-center'>
                              <h4 className='m-0 text-center no-avatar-letters-ticket'>
                                {watcher.name.charAt(0)}
                                {watcher.lastname.charAt(0)}
                              </h4>
                            </div>
                          )}
                          <span className='watcher-ticket-info'>
                            {watcher.name} {watcher.lastname}
                          </span>

                          {watcher.fulgeas ? (
                            <div className='position-absolute left-0 bottom-0 rounded-circle bg-variant-blue fulgeas-staff d-flex align-items-center justify-content-center'>
                              <img src={`${Logo}`} alt='' />
                            </div>
                          ) : (
                            ""
                          )}
                        </div>
                      </React.Fragment>
                    );
                  })
                )}
              </div>
            </div>

            <div className='card mt-3'>
              <div className='card-header bg-blue text-white'>
                <span className='m-0'>
                  <ImAttachment /> Elenco allegati del ticket
                </span>
                <span className='m-0 float-end'>
                  <IoIosArrowDown />
                </span>
              </div>
              <div
                className='card-body overflow-scroll'
                style={{ height: "400px" }}
              >
                <ul className='p-0'>
                  {singleTicket.map((row) =>
                    row.messages.map((singleMessage) =>
                      singleMessage.attachments.map((attachment, index) => {
                        return (
                          <>
                            <a
                              className='my-4 text-decoration-none text-dark'
                              key={attachment.id}
                              data-bs-toggle='collapse'
                              href={`#attatchmentPreview${attachment.id}`}
                              role='button'
                              aria-expanded='false'
                              aria-controls={`attatchmentPreview${attachment.id}`}
                            >
                              <p className='my-3 media-of-ticket-title'>
                                <GrDocument className='me-1 fs-6' />{" "}
                                {attachment.title}
                              </p>
                            </a>
                            <div
                              className='collapse py-3'
                              id={`attatchmentPreview${attachment.id}`}
                            >
                              <div className='col-12'>
                                <AttachmentsOfMessages
                                  attachment={attachment}
                                  index={attachment.id}
                                />
                              </div>
                            </div>
                          </>
                        );
                      })
                    )
                  )}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div style={{ height: "100px" }} className='d-block d-lg-none'></div>
    </section>
  );
};

export default SinglePageTicket;
