import React, { useEffect, useState, useRef } from "react";
import { FaFilter } from "react-icons/fa";
import { RiArrowDownSFill } from "react-icons/ri";
import { filterData } from "./data/filterData";
import "../App.css";

const Filter = ({
  setPriority,
  setSearch,
  setStatus,
  setProjectName,
  setOpenTickets,
  setType,
  projectName,
  status,
  priority,
  filterProjectsTicketsActive,
  setFilterProjectsTicketsActive,
  setUpdateOrder,
  tickets,
  selectUser,
  setSelectUser,
}) => {
  const [loading, setLoading] = useState(false);
  const [projects, setProjects] = useState([]);
  const [dataUser, setDataUser] = useState([]);
  const token = localStorage.getItem("token");
  const mySpanRef = useRef(null);
  const url = `${process.env.REACT_APP_BASE_URL}/project-list`;
  useEffect(() => {
    setLoading(true);
    fetch(url, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "Authorization": "Bearer " + token,
      },
    }).then((response) => {
      if (response.status === 401) {
        localStorage.removeItem("token");
        localStorage.removeItem("tokenExpiration");
        window.location.replace("/login");
      } else {
        response
          .json()
          .then((data) => {
            setLoading(false);
            setDataUser([data.user]);
            setProjects([data.response.projects]);
          })
          .catch((err) => console.log("errore"));
      }
    });
  }, []);

  // Filter section

  const [showTypeFilter, setShowTypeFilter] = useState(false);
  const [azzera, setAzzera] = useState(false);

  const [contatore, setContatore] = useState(0);
  const [userinfo, setUserInfo] = useState({
    ricerca: [],
    response: [],
  });

  const HandleChange = (e) => {
    // Destructuring
    setAzzera(false);
    let { value, checked } = e.target;
    const { ricerca } = userinfo;

    // Case 1 : The user checks the box
    if (checked) {
      setUserInfo({
        ricerca: [...ricerca, value],
        response: [...ricerca, value],
      });

      setContatore((old) => old + 1);
    }

    // Case 2  : The user unchecks the box
    else {
      setUserInfo({
        ricerca: ricerca.filter((e) => e !== value),
        response: ricerca.filter((e) => e !== value),
      });
      setContatore((old) => old - 1);
      if (value === "Priorità") {
        setPriority("");
      } else if (value === "Progetto") {
        setProjectName("");
      } else if (value === "Stato") {
        setStatus("");
      } else if (value === "Utente") {
        setSelectUser(0);
      } else {
        setSearch("");
      }
    }
  };

  const Azzera = (e) => {
    setFilterProjectsTicketsActive(false);
    setAzzera(true);
    setContatore(0);
    setShowTypeFilter(false);
    let { value, checked } = e.target;
    const { ricerca } = userinfo;
    setUserInfo({
      ricerca: ricerca.filter((e) => e === !e),
      response: ricerca.filter((e) => e === !e),
    });
    setPriority("");
    setStatus("");
    setSearch("");
    setSelectUser(0);
    setProjectName("");
  };

  const ShowSection = (event) => {
    event.stopPropagation(); //ferma la propagazione dell'evento click sui figli del bottone
    setAzzera(false);
    setShowTypeFilter(!showTypeFilter);
  };

  const TicketsAperti = () => {
    setType("");
    setOpenTickets("notclosed");
    setStatus("");
    setProjectName("");
    setUpdateOrder("");
    setSelectUser(0);
  };

  const AllTheTickets = () => {
    setOpenTickets("");
    setStatus("");
    setProjectName("");
    setType("");
    setUpdateOrder("");
    setSelectUser(0);
  };

  const TicketsChiusi = () => {
    setType("");
    setOpenTickets("");
    setProjectName("");
    setUpdateOrder("");
    setStatus("closed");
    setSelectUser(0);
  };

  const selectProgettiFiltro = (event) => {
    if (event.target.value === "defaultSelectProject") {
      setProjectName("");
    } else {
      setProjectName(event.target.value);
    }
  };

  const FiltraStatoDelTicket = (el) => {
    setOpenTickets("");
    if (el.target.value === "defaultSelectStatus") {
      setStatus("");
    } else {
      setStatus(el.target.value);
    }
  };

  const FiltraPriorityDelTicket = (el) => {
    if (el.target.value === "defaultSelectPriority") {
      setPriority("");
    } else {
      setPriority(el.target.value);
    }
  };

  useEffect(() => {
    const { ricerca } = userinfo;
    if (projectName !== "") {
      setUserInfo({
        ricerca: ["Progetto"],
        response: ["Progetto"],
      });
      setContatore(1);
    } else {
      setUserInfo({
        ricerca: ricerca.filter((e) => e === !e),
        response: ricerca.filter((e) => e === !e),
      });
      setContatore(0);
    }
  }, [filterProjectsTicketsActive]);

  const form = document.querySelector(".form-check");

  const selectUserOption = (event) => {
    if (event.target.value === "defaultSelectUser") {
      setSelectUser(0);
    } else {
      setSelectUser(event.target.value);
    }
  };

  const uniqueUsers = projects
    .flatMap((row) => row.map((project) => project.users))
    .flat()
    .reduce((acc, user) => {
      if (!acc.some((u) => u.id === user.id)) {
        acc.push(user);
      }
      return acc;
    }, []);

  useEffect(() => {
    function handleClickOutside(event) {
      if (mySpanRef.current && !mySpanRef.current.contains(event.target)) {
        setShowTypeFilter(false);
      }
    }

    // Aggiungi l'event listener sulla finestra
    window.addEventListener("click", handleClickOutside);

    // Pulisci l'event listener quando il componente viene smontato
    return () => {
      window.removeEventListener("click", handleClickOutside);
    };
  }, [mySpanRef]);

  return (
    <>
      <div className='row mt-3'>
        <div className='col-8 align-self-center p-0'>
          <span className='m-0 align-self-center'>
            <FaFilter onClick={ShowSection} style={{ cursor: "pointer" }} />
          </span>
          <span
            className='align-self-center m-0 mx-2 font-14'
            onClick={ShowSection}
            style={{ cursor: "pointer" }}
          >
            Filtri
          </span>
          <span
            className='badge rounded-circle bg-secondary align-self-center m-0'
            onClick={ShowSection}
            style={{ cursor: "pointer" }}
          >
            {contatore}
          </span>
          <span className='align-self-center m-0 position-relative'>
            <RiArrowDownSFill
              onClick={ShowSection}
              style={{ cursor: "pointer" }}
            />
            <span
              className='position-absolute mr-0'
              style={{ zIndex: 2 }}
              ref={mySpanRef}
            >
              {!azzera ? (
                <div
                  className={`${
                    showTypeFilter ? "d-block" : "d-none"
                  } shadow bg-white p-2 font-13`}
                  style={{ width: "150px" }}
                >
                  {filterData.map((singleCheck) => {
                    return (
                      <div className='form-check' key={singleCheck.id}>
                        <input
                          className='form-check-input'
                          type='checkbox'
                          value={singleCheck.nome}
                          onChange={HandleChange}
                          key={singleCheck.id}
                          id={singleCheck.nome}
                          defaultChecked={
                            singleCheck.nome === "Progetto" &&
                            filterProjectsTicketsActive
                              ? true
                              : undefined
                          }
                        />
                        <label
                          className='form-check-label'
                          htmlFor={singleCheck.nome}
                        >
                          {singleCheck.nome}
                        </label>
                      </div>
                    );
                  })}
                </div>
              ) : (
                ""
              )}
            </span>
          </span>
        </div>
        <div className='col-4 text-end p-0 font-13'>
          <a
            className='m-0 me-3 text-decoration-none'
            style={{ cursor: "pointer" }}
            onClick={AllTheTickets}
          >
            {" "}
            Tutti i ticket{" "}
          </a>
          <a
            className='m-0 me-3 text-decoration-none'
            style={{ cursor: "pointer" }}
            onClick={TicketsAperti}
          >
            {" "}
            Ticket Aperti{" "}
          </a>
          <a
            className='m-0 text-decoration-none'
            style={{ cursor: "pointer" }}
            onClick={TicketsChiusi}
          >
            {" "}
            Ticket Chiusi{" "}
          </a>
        </div>
      </div>

      {/* Open filter Section */}

      <div
        className={`${
          contatore > 0 ? `d-flex` : `d-none`
        } row bg-grey py-2 mt-2`}
        style={{ zIndex: 1 }}
      >
        <div
          className='col-8 align-self-center'
          id='element'
          style={{ zIndex: 1 }}
        >
          <div className='row allFilterOptions'>
            {!azzera
              ? userinfo.response.map((el, index) => {
                  return (
                    <React.Fragment key={index}>
                      {el === "Titolo" ? (
                        <div
                          className='col-3 d-flex align-items-center'
                          key={index}
                        >
                          <label htmlFor='selectPriority' className='me-1'>
                            {el}
                          </label>
                          <input
                            name={el}
                            onChange={(e) =>
                              setSearch(e.target.value.toLowerCase())
                            }
                          />
                        </div>
                      ) : (
                        <>
                          {el === "Progetto" ? (
                            <div className='d-flex align-items-center col-3'>
                              <label
                                htmlFor='selectProjects'
                                className='me-1'
                                key={index}
                              >
                                {el}
                              </label>
                              <select
                                id='selectProjects'
                                className='form-select me-0'
                                aria-label='Default select example'
                                onChange={selectProgettiFiltro}
                                value={
                                  filterProjectsTicketsActive
                                    ? projectName
                                    : undefined
                                }
                              >
                                <option value='defaultSelectProject'>
                                  Seleziona un progetto
                                </option>
                                {projects.map((row) =>
                                  row.map((progetto) => {
                                    return (
                                      <option
                                        key={progetto.id}
                                        value={progetto.id}
                                      >
                                        {progetto.title}
                                      </option>
                                    );
                                  })
                                )}
                              </select>
                            </div>
                          ) : el === "Stato" ? (
                            <div className='d-flex align-items-md-center col-3'>
                              <label
                                htmlFor='selectStatus'
                                className='me-1'
                                key={index}
                              >
                                {el}
                              </label>
                              <select
                                id='selectStatus'
                                className='form-select'
                                aria-label='Default select example'
                                onChange={FiltraStatoDelTicket}
                                value={status}
                              >
                                <option value='defaultSelectStatus'>
                                  Seleziona Stato
                                </option>
                                <option value='open'>Aperto</option>
                                <option value='closed'>Chiuso</option>
                                <option value='working'>In lavorazione</option>
                                <option value='review'>In revisione</option>
                              </select>
                            </div>
                          ) : el === "Utente" ? (
                            <div className='d-flex align-items-center col-3'>
                              <label
                                htmlFor='selectUsers'
                                className='me-1'
                                key={index}
                              >
                                {el}
                              </label>
                              <select
                                id='selectUsers'
                                className='form-select me-0'
                                aria-label='Default select example'
                                value={selectUser}
                                onChange={selectUserOption}
                              >
                                <option value='defaultSelectUser'>
                                  Seleziona un utente
                                </option>

                                {uniqueUsers.map((user, index) => (
                                  <option
                                    key={user.id}
                                    value={user.id}
                                    id={`selectUsers-${index}`}
                                  >
                                    {user.name} {user.lastname}
                                  </option>
                                ))}
                              </select>
                            </div>
                          ) : (
                            <div className='d-flex align-items-md-center col-3'>
                              <label
                                htmlFor='selectPriority'
                                className='me-1'
                                key={index}
                              >
                                {el}
                              </label>
                              <select
                                id='selectPriority'
                                className='form-select'
                                aria-label='Default select example'
                                value={priority}
                                onChange={FiltraPriorityDelTicket}
                              >
                                <option value='defaultSelectPriority'>
                                  Seleziona Priorità
                                </option>
                                <option value='low'>Normale</option>
                                <option value='high'>Alta</option>
                                <option value='urgent'>Urgente</option>
                              </select>
                            </div>
                          )}
                        </>
                      )}
                    </React.Fragment>
                  );
                })
              : ""}
          </div>
        </div>
        <div className='col-4 text-end'>
          <button className='btn bg-secondary text-white' onClick={Azzera}>
            Azzera
          </button>
        </div>
      </div>
    </>
  );
};

export default Filter;
