import React, { useEffect, useState } from "react";
import { FaFilter, FaPlusCircle } from "react-icons/fa";
import { RiArrowDownSFill } from "react-icons/ri";
import "../../App.css";
import { filterNotificationsData } from "./filterNoitificationsData";
import { BsCheck2All } from "react-icons/bs";

const Filter = ({
  setProgettoNotifications,
  setProjectName,
  projectName,
  setReadNotifications,
  setResult,
}) => {
  // Filter section

  const [showTypeFilter, setShowTypeFilter] = useState(false);
  const [azzera, setAzzera] = useState(false);

  const [loading, setLoading] = useState(false);
  const [projects, setProjects] = useState([]);
  const [dataUser, setDataUser] = useState([]);
  const [prova, setProva] = useState("");
  const [contatore, setContatore] = useState(0);
  const [userinfo, setUserInfo] = useState({
    ricerca: [],
    response: [],
  });

  const token = localStorage.getItem("token");
  const url = `${process.env.REACT_APP_BASE_URL}/project-list`;
  useEffect(() => {
    setLoading(true);
    fetch(url, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "Authorization": "Bearer " + token,
      },
    }).then((response) => {
      if (response.status === 401) {
        localStorage.removeItem("token");
        localStorage.removeItem("tokenExpiration");
        window.location.replace("/login");
      } else {
        response
          .json()
          .then((data) => {
            setLoading(false);
            setDataUser([data.user]);
            setProjects([data.response.projects]);
          })
          .catch((err) => console.log("errore"));
      }
    });
  }, []);

  const HandleChange = (e) => {
    // Destructuring
    setAzzera(false);
    let { value, checked } = e.target;
    const { ricerca } = userinfo;

    // Case 1 : The user checks the box
    if (checked) {
      setUserInfo({
        ricerca: [...ricerca, value],
        response: [...ricerca, value],
      });

      setContatore((old) => old + 1);
    }

    // Case 2  : The user unchecks the box
    else {
      setUserInfo({
        ricerca: ricerca.filter((e) => e !== value),
        response: ricerca.filter((e) => e !== value),
      });
      setContatore((old) => old - 1);
      setProgettoNotifications("");
    }
  };

  const Azzera = (e) => {
    setAzzera(true);
    setContatore(0);
    setShowTypeFilter(false);
    let { value, checked } = e.target;
    const { ricerca } = userinfo;
    setUserInfo({
      ricerca: ricerca.filter((e) => e === !e),
      response: ricerca.filter((e) => e === !e),
    });
    setProgettoNotifications("");
  };

  const ShowSection = () => {
    setAzzera(false);
    setShowTypeFilter(!showTypeFilter);
  };

  const selectProgettiFiltro = (event) => {
    if (event.target.value === "defaultSelectProject") {
      setProjectName("");
    } else {
      setProjectName(event.target.value);
    }
  };

  const readNotification = `${process.env.REACT_APP_BASE_URL}/read-all-notification`;
  const ReadAllNotifications = () => {
    fetch(readNotification, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "Authorization": "Bearer " + token,
      },
    }).then((response) => {
      setReadNotifications(response);
      setResult(response);
    });
  };

  return (
    <>
      <div className='row mt-3  '>
        <div className='col-12 align-self-center p-0 d-flex align-content-center'>
          <span className='m-0 align-self-center'>
            <FaFilter />
          </span>
          <span className='align-self-center m-0 mx-2 font-14'>Filtri</span>
          <span className='badge rounded-circle bg-secondary align-self-center m-0'>
            {contatore}
          </span>
          <span className='align-self-center m-0 position-relative'>
            <RiArrowDownSFill onClick={ShowSection} />
            <span className='position-absolute mr-0' style={{ zIndex: 2 }}>
              {!azzera ? (
                <div
                  className={`${
                    showTypeFilter ? "d-block" : "d-none"
                  } shadow bg-white p-2 font-13`}
                  style={{ width: "150px" }}
                >
                  {filterNotificationsData.map((singleCheck) => {
                    return (
                      <div className='form-check' key={singleCheck.id}>
                        <input
                          className='form-check-input'
                          type='checkbox'
                          value={singleCheck.nome}
                          onChange={HandleChange}
                          key={singleCheck.id}
                          id={singleCheck.nome}
                        />
                        <label
                          className='form-check-label'
                          htmlFor={singleCheck.nome}
                        >
                          {singleCheck.nome}
                        </label>
                      </div>
                    );
                  })}
                </div>
              ) : (
                ""
              )}
            </span>
          </span>
          <div className='w-100 d-flex justify-content-end'>
            <button
              className='btn btn-secondary '
              onClick={ReadAllNotifications}
            >
              <BsCheck2All /> Segna le notifiche come lette
            </button>
          </div>
        </div>
      </div>

      {/* Open filter Section */}

      <div
        className={`${
          contatore > 0 ? `d-flex` : `d-none`
        } row bg-grey py-2 mt-2`}
        style={{ zIndex: 1 }}
      >
        <div
          className='col-8 align-self-center'
          id='element'
          style={{ zIndex: 1 }}
        >
          {!azzera
            ? userinfo.response.map((el, index) => {
                return (
                  <React.Fragment key={index}>
                    <div className='d-flex align-items-center col-3'>
                      <label
                        htmlFor='selectProjects'
                        className='me-1'
                        key={index}
                      >
                        {el}
                      </label>
                      <select
                        id='selectProjects'
                        className='form-select me-0'
                        aria-label='Default select example'
                        onChange={selectProgettiFiltro}
                        value={undefined}
                      >
                        <option value='defaultSelectProject'>
                          Seleziona un progetto
                        </option>
                        {projects.map((row) =>
                          row.map((progetto) => {
                            return (
                              <option
                                key={progetto.id}
                                value={progetto.title.toLowerCase()}
                              >
                                {progetto.title}
                              </option>
                            );
                          })
                        )}
                      </select>
                    </div>
                  </React.Fragment>
                );
              })
            : ""}
        </div>
        <div className='col-4 text-end'>
          <button className='btn bg-secondary text-white' onClick={Azzera}>
            Azzera
          </button>
        </div>
      </div>
    </>
  );
};

export default Filter;
