import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import useAuth from "./useAuth";
import StateContainer from "./StateContainer";
import Routers from "./routes";

function App() {
  const history = useHistory();
  useAuth(history);
  return (
    <StateContainer>
      <div className='App'>
        <Routers />
      </div>
    </StateContainer>
  );
}

export default App;
