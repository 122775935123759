import { useEffect, useState } from "react";

function useAuth() {
  const [authenticated, setAuthenticated] = useState(false);

  useEffect(() => {
    const token = localStorage.getItem("token");
    const tokenExpiration = localStorage.getItem("tokenExpiration");

    if (!token || !tokenExpiration) {
      return;
    }

    setAuthenticated(true);
  }, []);

  function login(token) {
    localStorage.setItem("token", token);
    const redirectUrl = sessionStorage.getItem("redirectUrl");
    if (redirectUrl) {
      sessionStorage.removeItem("redirectUrl");
      window.location.assign(redirectUrl);
    } else {
      window.location.assign("/dashboard");
    }
  }

  function logout() {
    localStorage.removeItem("token");
    localStorage.removeItem("tokenExpiration");
    setAuthenticated(false);
    window.location.href = "/login";
  }

  return { authenticated, login, logout };
}

export default useAuth;
