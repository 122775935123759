import React from "react";
import Moment from "react-moment";

const CollapseProjects = ({ data }) => {
  return (
    <div
      className='collapse bg-white border-purple rounded-bottom overflow-auto'
      id={`collapseProjects-${data.id}`}
    >
      <div className='p-3'>
        <h6>
          Progetto: <b>{data.title}</b>
        </h6>
        <hr className='color-variant-grey' />
        <p>
          Cliente:
          <b> {data.customer.name}</b>
        </p>
        <hr className='color-variant-grey' />
        <p>
          Data di apertura:
          <b className='ms-1'>
            <Moment format='D MMMM YYYY'>{data.created.date}</Moment>
          </b>
        </p>
        <hr className='color-variant-grey' />
        <p>
          Data di chiusura:
          <b className='ms-1'>
            {data.dueDate !== null ? (
              <Moment format='D MMMM YYYY'>{data.dueDate.date}</Moment>
            ) : (
              "Non definita"
            )}
          </b>
        </p>
      </div>
    </div>
  );
};

export default CollapseProjects;
