import React from "react";
import { BsFiles } from "react-icons/bs";
import { FaTicketAlt } from "react-icons/fa";
import { RiSuitcaseFill } from "react-icons/ri";
import { FaBriefcaseMedical, FaFolderOpen } from "react-icons/fa";
import { AiOutlineArrowRight } from "react-icons/ai";
import "../../App.css";
import { Link } from "react-router-dom";

const NumeroRichieste = ({
  data,
  search,
  status,
  priority,
  setSearch,
  setStatus,
  setPriority,
  type,
  setType,
  totalProjects,
  totalTickets,
  setOpenTickets,
  openTickets,
}) => {
  const openTasks = () => {
    setType("task");
    setOpenTickets(openTickets);
    setStatus("");
  };
  const openSupports = () => {
    setType("support");
    setOpenTickets(openTickets);
    setStatus("");
  };

  const AllOpenTickets = () => {
    setType("");
    setOpenTickets(openTickets);
    setStatus("");
  };
  return (
    <div className='row p-0 m-0 d-flex gap-2 justify-content-around'>
      {/* Prima Card */}

      <div
        className={`bg-prima-card card col-6 col-xl-3 my-2 my-xl-0 rounded-0 prime-cards text-white position-relative`}
        key='1'
      >
        <div className='card-header border-0 bg-transparent text-start float-start p-0 py-2 d-flex align-items-center justify-content-center justify-content-xl-start'>
          <span className='pe-2 d-none d-xl-block' style={{ fontSize: "14pt" }}>
            <FaFolderOpen />
          </span>
          <span className='m-0 fs-xl-5'>PROGETTI</span>
        </div>
        <div className='card-body d-flex justify-content-center justify-content-xl-between align-items-baseline'>
          {data.map((el, index) => {
            return (
              <span key={index} className='float-start m-0 '>
                <React.Fragment key={index}>
                  {totalProjects.map((row, id) => {
                    return (
                      <span key={id} className='m-0 display d-flex gap-2'>
                        <FaFolderOpen className='fs-1 d-block d-xl-none' />
                        <p className='display-4'>{row.length}</p>
                      </span>
                    );
                  })}
                </React.Fragment>
              </span>
            );
          })}
          <span className='text-end d-none d-xl-block'>
            <Link to='/progetti' className='text-decoration-none text-white'>
              Vedi tutti <AiOutlineArrowRight />
            </Link>
          </span>
        </div>
        <Link
          to='/progetti'
          className='d-block d-xl-none stretched-link'
        ></Link>
      </div>

      {/* Seconda Card */}

      <div
        className={`bg-seconda-card card col-6 col-xl-3 my-2 my-xl-0 rounded-0 prime-cards text-white position-relative`}
        key='2'
      >
        <div className='card-header bg-transparent border-0 text-start float-start p-0 py-2 d-flex align-items-center justify-content-center justify-content-xl-start'>
          <span className='pe-2 d-none d-xl-block'>
            <FaTicketAlt style={{ fontSize: "14pt" }} />
          </span>
          <span className='m-0 fs-5'>TICKETS APERTI</span>
        </div>
        <div className='card-body d-flex justify-content-center justify-content-xl-between align-items-baseline'>
          {data.map((el, index) => {
            return (
              <React.Fragment key={index}>
                {totalTickets
                  .map((row) =>
                    row.filter((ticket) => ticket.status !== "closed")
                  )
                  .map((row, id) => {
                    return (
                      <span key={id} className='m-0 display d-flex gap-2'>
                        <FaTicketAlt className='fs-1 d-block d-xl-none' />
                        <p className='display-4'>{row.length}</p>
                      </span>
                    );
                  })}
              </React.Fragment>
            );
          })}
          <span className='text-end d-none d-xl-block'>
            <Link
              to='/tickets'
              onClick={AllOpenTickets}
              className='text-decoration-none text-white'
            >
              {" "}
              Vedi tutti <AiOutlineArrowRight />
            </Link>
          </span>
        </div>
        <Link
          to='/tickets'
          onClick={AllOpenTickets}
          className='stretched-link d-block d-xl-none'
        >
          {" "}
        </Link>
      </div>

      {/* Terza Card */}

      <div
        className={`bg-terza-card card col-6 col-xl-3 my-2 my-xl-0 rounded-0 prime-cards text-white position-relative`}
        key='3'
      >
        <div className='card-header bg-transparent bottom-0  border-0 text-start float-start p-0 py-2 d-flex align-items-center justify-content-center justify-content-xl-start'>
          <span className='pe-2 d-none d-xl-block'>
            <RiSuitcaseFill style={{ fontSize: "14pt" }} />
          </span>
          <span className='m-0 fs-5'>RICHIESTE APERTE</span>
        </div>
        <div className='card-body d-flex justify-content-center justify-content-xl-between align-items-baseline'>
          {data.map((el, index) => {
            return (
              <React.Fragment key={index}>
                {totalTickets
                  .map((row) =>
                    row.filter(
                      (ticket) =>
                        ticket.type === "task" && ticket.status !== "closed"
                    )
                  )
                  .map((row, id) => {
                    return (
                      <span key={id} className='m-0 display d-flex gap-2'>
                        <RiSuitcaseFill className='fs-1 d-block d-xl-none' />
                        <p className='display-4'>{row.length}</p>
                      </span>
                    );
                  })}
              </React.Fragment>
            );
          })}
          <span className='text-end d-none d-xl-block'>
            <Link
              to='/tickets'
              onClick={openTasks}
              className='text-white text-decoration-none'
            >
              Vedi tutti <AiOutlineArrowRight />
            </Link>
          </span>
        </div>
        <Link
          to='/tickets'
          onClick={openTasks}
          className='d-block d-xl-none stretched-link'
        ></Link>
      </div>

      {/* Quarta Card */}

      <div
        className={`bg-quarta-card card col-6 col-xl-3 my-2 my-xl-0 rounded-0 prime-cards text-white position-relative`}
        key='4'
      >
        <div className='card-header bg-transparent border-0 text-start float-start p-0 py-2 d-flex align-items-center justify-content-center justify-content-xl-start'>
          <span className='pe-2 d-none d-xl-block'>
            <FaBriefcaseMedical style={{ fontSize: "14pt" }} />
          </span>
          <span className='m-0 fs-5'>SUPPORTI APERTI</span>
        </div>
        <div className='card-body d-flex justify-content-center justify-content-xl-between align-items-baseline'>
          {data.map((el, index) => {
            return (
              <React.Fragment key={index}>
                {totalTickets
                  .map((row) =>
                    row.filter(
                      (ticket) =>
                        ticket.type === "support" && ticket.status !== "closed"
                    )
                  )
                  .map((row, id) => {
                    return (
                      <span key={id} className='m-0 display d-flex gap-2'>
                        <FaBriefcaseMedical className='fs-1 d-block d-xl-none' />
                        <p className='display-4'>{row.length}</p>
                      </span>
                    );
                  })}
              </React.Fragment>
            );
          })}
          <span className='text-end d-none d-xl-block'>
            <Link
              to='/tickets'
              onClick={openSupports}
              className='text-white text-decoration-none'
            >
              Vedi tutti <AiOutlineArrowRight />
            </Link>
          </span>
        </div>
        <Link
          to='/tickets'
          onClick={openSupports}
          className='stretched-link d-block d-xl-none'
        ></Link>
      </div>
    </div>
  );
};

export default NumeroRichieste;
