import React, { useState } from "react";
import axios from "axios";
import useAuth from "../useAuth";
import ExtendedLogo from "../img/customer_platform_logo.svg";
import { FiPhoneCall, FiMail } from "react-icons/fi";
import { AiFillEye, AiFillEyeInvisible } from "react-icons/ai";

function Login() {
  const [error, setError] = useState();
  const [loading, setLoading] = useState(false);
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const { login } = useAuth();

  const handleSubmit = (event) => {
    event.preventDefault();
    setLoading(true);
    const loginPayload = { email, password };

    axios
      .post(`${process.env.REACT_APP_BASE_URL}/login_check`, loginPayload)
      .then(
        (response) => {
          setLoading(false);
          setError("");
          //get token from response
          const token = response.data.token;
          // call the login function from the hook
          login(token);
        },
        (reason) => {
          setLoading(false);
          console.error(reason);
          setError("Email o password errati");
        }
      );
  };

  return (
    <>
      <section className='bg-login'>
        <div className='container pt-5'>
          <div className='row mt-5 justify-content-center'>
            <div className='col-10 col-sm-6 col-lg-4 '>
              <form
                onSubmit={handleSubmit}
                className=' mt-5 bg-white rounded-3 p-4 w-100 shadow'
              >
                <div className='row py-3'>
                  <div className='col-12 text-center'>
                    <img
                      src={ExtendedLogo}
                      alt='fulgeas-logo'
                      className='img-fluid'
                    />
                  </div>
                </div>

                <div className='row'>
                  <div className='col-12 mt-5'>
                    {error ? (
                      <h6 className='text-danger text-center'>{error}</h6>
                    ) : (
                      ""
                    )}
                    <label htmlFor='email' className='font-weight-bold'>
                      Email
                    </label>
                  </div>

                  <div className='col-12'>
                    <input
                      type='email'
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      className='w-100 rounded ps-1 py-1 border-input-login'
                    />
                  </div>
                </div>

                <div className='row mt-3'>
                  <div className='col-12'>
                    <label htmlFor='password'>Password</label>
                  </div>
                  <div className='col-12'>
                    <input
                      type={passwordVisible ? "text" : "password"}
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                      className='w-100 rounded border-input-login py-1 ps-1'
                    ></input>
                    <i
                      style={{ marginLeft: "-30px", cursor: "pointer" }}
                      onClick={() => setPasswordVisible(!passwordVisible)}
                    >
                      {passwordVisible ? <AiFillEyeInvisible /> : <AiFillEye />}
                    </i>
                  </div>
                </div>
                <div className='col-12 my-4'>
                  <input
                    type='submit'
                    className={`btn bg-variant-blue text-white w-100 rounded-pill ${
                      loading ? "loading" : ""
                    }`}
                    value={loading ? "" : "LOGIN"}
                  />
                </div>
              </form>

              <div className='row mt-1'>
                <div className='col-6 pe-1'>
                  <a
                    type='submit'
                    className='btn w-100 text-white btn-email d-inline-flex align-items-center justify-content-center shadow'
                    href='mailto:info@fulgeas.com?subject=La tua richiesta'
                  >
                    <FiMail className='me-2' /> EMAIL
                  </a>
                </div>
                <div className='col-6 ps-0'>
                  <a
                    type='submit'
                    className='btn w-100 shadow btn-call text-white d-flex justify-content-center'
                    href='tel:030 094 8684'
                  >
                    <FiPhoneCall className='me-2' /> CHIAMACI
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Login;
